<script>
  import { conf, userId, user, lang, route, menus  } from './stores.js'
  import { setRoute, isRoute, isRouteRoot, matchRoute, locale, cdn, fuc, i18n} from './h.js'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'

  export let height=40
  export let sideBreak="xl"
  export let shortLang=false
  export let signUpBtn=false
  export let signInBtn=false
  export let signOutFct=false

  //route=false should be set to true and #  removed when express will be set on server side to access directly url
</script>


<!-- menu -->
<nav class="sticky-top navbar navbar-expand-{sideBreak}" >
  <div class="container-fluid">
    <!-- Brand -->
    <a class="me-4" route=false href="./#/">
      <img src="{cdn($conf.logo)}" {height} alt="img" title="img">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar" aria-controls="collapsibleNavbar" aria-expanded="false" aria-label="Toggle navigation">
      <span class=""><i class="fa-light fa-bars"></i></span>
    </button>

    <!-- Navbar links -->
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav nav ms-auto">
        {#each $menus as menu}
          {#if menu.valid}
            <li class="nav-item">
              <a class="nav-link" class:active={menu.route==$route || matchRoute($route, menu.route+ '/*') || (menu.actifOnRoot && isRouteRoot()) || matchRoute($route, menu.activeRoutes)} 
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show" 
                route=false 
                href="./#{menu.route}"
              >
                {#if menu.icon}<I icon={menu.icon}/>{/if}
                {#if menu.name}<I18n id={menu.name}/>{/if}
              </a>
            </li>
          {/if}
        {/each}
      </ul>

      {#if $userId}
        <ul class="navbar-nav nav ms-auto">
          <li class="pointer nav-item">
            <span on:click={()=>setRoute("/profil")} class:active={"/profil"==$route} class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
              <I user/> {$user.name || $user.firstname || $user.lastname || ($user.email && $user.email.split('@')[0]) || i18n("Guest")}
            </span>
          </li>
        </ul>
      {/if}

      <ul class="navbar-nav nav ms-auto">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="./#" role="button" aria-expanded="false">
            <I globe/> {shortLang?fuc($lang):$conf.langNames[$lang]}
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            {#each $conf.langs as langId}
              <li><span class="pointer dropdown-item" on:click={()=>locale(langId)} data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">{fuc($conf.langNames[langId])}</span></li>
            {/each}
          </ul>
        </li>
      </ul>
      

      {#if signUpBtn}
        <ul class="navbar-nav nav ms-auto">
          <li class="nav-item nav-btn" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
            <span class="btn btn-global m-1" on:click={()=>setRoute("/auth/signup")}>
              <I18n id="Signup"/>
            </span>
          </li>
        </ul>
      {/if}

      {#if signInBtn && !$userId && !isRoute($route,"/auth/signin/*")}
        <ul class="navbar-nav nav ms-auto">
          <li class="nav-item nav-btn" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
            <span on:click={()=>setRoute("/auth/signin")} class="btn btn-global m-1">
              <I18n id="Signin"/>
            </span>
          </li>
        </ul>
      {/if}
      
      {#if signOutFct && $userId}
        <ul class="navbar-nav nav ms-auto">
          <li class="nav-item nav-btn" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
            <span on:click={signOutFct} class="btn btn-global m-1">
              <I sign-out-alt/>
            </span>
          </li>
        </ul>
      {/if}

    </div>
  </div>
</nav>

<style>
  .navbar {
    background-color:var(--navbarBgColor);
    font-size: var(--navbarFontSize);
		box-shadow: 0 0.5rem 1rem rgba(0,0,0,.2)!important;
  }

  .navbar-toggler{color:var(--brandColo2r);}
  .navbar-toggler:hover{color:var(--brandColor);}


  /* navbar not expanded */
  .navbar-collapse.show .nav-link, .navbar-collapse.collapsing .nav-link{
    color: var(--brandColor2);
    afont-weight: bold;
    border:none;
    margin:10px 0 10px 0;
  }
  .navbar-collapse.show .nav-link:hover,  .navbar-collapse.collapsing .nav-link:hover {
    color:var(--brandColor);
    border:none;
  }
  .navbar-collapse.show .nav-link.active,  .navbar-collapse.collapsing .nav-link.active {
    color:var(--brandColor);
    border:none;
  }

  /* navbar expanded */
  .navbar-collapse .nav-link.active, .navbar-collapse .nav-link.active:hover  {
    border-bottom: 4px solid var(--brandColor);
  }
  .navbar-collapse .nav-link {
    afont-weight: bold;
    color:var(--brandColor2);
    border-bottom: 4px solid transparent;
  }
  .navbar-collapse .nav-link:hover {
    border-bottom: 4px solid var(--brandColor2);
  }
  .navbar-collapse .nav-link {
    padding:0;
    margin-right: 2rem;
  }



  .nav-item {
    white-space: nowrap;
  }

</style>