<script>
  import { alert } from "./stores.js"
  import { fade } from "svelte/transition"
  import I18n from './G_I18n.svelte'
  let faType='fa-light'
  
  let cl=$$props.class || ''

  export let local=false

  let alertObj={}
  let defaultTimeoutDismiss=5000

  $: if(local) alertObj=local
  $: if(!local && $alert) alertObj=$alert

  $: if(alertObj.info && !alertObj.noDismiss) setTimeout(() => {alertObj.info=false}, alertObj.dismiss || defaultTimeoutDismiss)
  $: if(alertObj.success && !alertObj.noDismiss) setTimeout(() => {alertObj.success=false}, alertObj.dismiss || defaultTimeoutDismiss)
  $: if(alertObj.warning && !alertObj.noDismiss) setTimeout(() => {alertObj.warning=false}, alertObj.dismiss || defaultTimeoutDismiss)
  $: if(alertObj.danger && !alertObj.noDismiss) setTimeout(() => {alertObj.danger=false}, alertObj.dismiss || defaultTimeoutDismiss)
  $: if(alertObj.primary && !alertObj.noDismiss) setTimeout(() => {alertObj.primary=false}, alertObj.dismiss || defaultTimeoutDismiss)
  $: if(alertObj.secondary && !alertObj.noDismiss) setTimeout(() => {alertObj.secondary=false}, alertObj.dismiss || defaultTimeoutDismiss)
</script>

{#if alertObj.info || alertObj.success || alertObj.warning || alertObj.danger || alertObj.primary || alertObj.secondary}
  <div transition:fade|local class="my-2 {cl}">  
    <div class="shadow alert" 
      class:alert-info={alertObj.info}
      class:alert-success={alertObj.success}
      class:alert-warning={alertObj.warning}
      class:alert-danger={alertObj.danger}
      class:alert-primary={alertObj.primary}
      class:alert-secondary={alertObj.secondary}
      role="alert">
      <i class="me-2 fa-2x {faType} align-middle"
        class:fa-info-square={alertObj.info || alertObj.primary || alertObj.secondary }
        class:fa-check-circle={alertObj.success}
        class:fa-exclamation-circle={alertObj.warning}
        class:fa-exclamation-triangle={alertObj.danger}></i>
      <I18n id={alertObj.info || alertObj.success || alertObj.warning || alertObj.danger || alertObj.primary || alertObj.secondary}/>
    </div>
  </div>
{/if}