<script>
  import { customerId, route, permissions } from './stores.js'
  import { setRoute, matchRoute, isRoute, fuc, sleep } from './h.js'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  export let menus=[]
  export let baseRoute="/"
  export let valid=false
  export let noAutoselect=false

  //bug when we call a direct route that is a sub route of menu, it comes back to reset 
  //ie : /account/licence/subscription return to /account/users
  //https://github.com/klicat/common4/issues/9
  //no autoselect is a workaround (ie used in m&w event & plan)

  $: if(valid && menus) {$route;selectMenu()}
  $: if(valid && menus && $customerId) {selectMenu(true)}
  $: if(!valid && menus) setRoute()

  function selectMenu(reset){
    if(noAutoselect) return
    //console.log("here",reset)
    let go, firstValid, routeInMenu
    for (let menu of menus) {
      if(menu.route==$route) routeInMenu=true
      if(menu.valid && !firstValid) firstValid=menu.route
      if(menu.valid && menu.route==$route) return
    }
    if(isRoute(baseRoute) || ((routeInMenu || reset) && $route!=firstValid) ) setRoute(firstValid)
  }
</script>


<div class="line">
  <div>
    {#each menus as menu}
      {#if menu.valid}
        <div class:active={ matchRoute($route, [menu.route + "/*", menu.route + "*"])} on:click={()=>setRoute(menu.route, null, null, true)}>
          {#if menu.icon}<I icon={menu.icon}/>{/if}
          {#if menu.name}<I18n id={fuc(menu.name)}/>{/if}
        </div>
      {/if}
    {/each}
  </div>
</div>

<style>
  .line {  
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 2px solid #dee2e6;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .line :global(>div) {  
    display: flex;
    flex-wrap: wrap;
  }

  .line :global(>div>div.active) {
    border-bottom: 4px solid var(--brandColor);
  }
  .line :global(>div>div:hover){
    border-bottom: 4px solid var(--brandColor2);
  }

  .line :global(>div>div) {
    white-space: nowrap;
    padding-bottom: 2px;
    cursor: pointer;
    margin-right: 1rem;
    border-bottom: 4px solid transparent;
  }
</style>