<script>
  import { lang } from './stores.js'
  import { dateTimeFormat, asArray, i18n} from './h.js'
  import I18n from './G_I18n.svelte'
  export let invoices=[]
</script>
<div class="fsContentHorizontal fs-5 my-4">
  <div>{asArray(invoices).length} <I18n>g--Invoices</I18n></div>
</div>
<table class="table">
  <thead>
    <tr>
      <th scope="col"><I18n>invoices--Reference</I18n></th>
      <th scope="col"><I18n>invoices--Date</I18n></th>
      <th scope="col"><I18n>invoices--Title</I18n></th>
      <!--th scope="col"><I18n>invoices--Amount</I18n></th>
      <th scope="col"><I18n>invoices--Vat</I18n></th-->
      <th scope="col"><I18n>invoices--Status</I18n></th>
    </tr>
  </thead>
  <tbody>
    {#each asArray(invoices) as invoice,i}
    <tr>
      <th scope="row">{invoice.invoiceId}</th>
      <td>{dateTimeFormat(invoice.date,'long')}</td>
      <td>{invoice.title}</td>
      <!--td>{invoice.amount} {invoice.currency}</td>
      <td>{invoice.vat} {invoice.currency}</td-->
      <td>{i18n("invoices--"+invoice.status, $lang)}</td>
    </tr>
    {/each}
  </tbody>
</table>