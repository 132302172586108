<script>
  import { flip } from 'svelte/animate'
  import { tick } from 'svelte'
  import { conf, lang } from './stores.js'
  import { i18n, getById } from './h.js'

  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Inputs from './G_Inputs.svelte'
  import Button from './G_Button.svelte'
  import Alert from './G_Alert.svelte'

  export let showBlocks=false
  export let items=[]
  export let editItemIndex=null

  let item=false

  $: item=editItemIndex!==null?items[editItemIndex]:false
  

  let blocks=[
    { id:"title", name:"title", icon:"heading" },
    { id:"p", name:"paragraph", icon:"paragraph" },
    { id:"hr", name:"horizontal-rule", icon:"horizontal-rule" },
    { id:"a", name:"link", icon:"link" },
    { id:"input", name:"input field", icon:"input-text" },
    { id:"select", name:"select", icon:"list" },
    { id:"checkbox", name:"checkbox", icon:"square-check" },
    { id:"button", name:"button", icon:"rectangle-wide", iconType:"fa-duotone" },
    { id:"video", name:"video", icon:"video" },
    { id:"image", name:"image", icon:"image" },
    //{ id:"file", name:"file", icon:"download" },
    { id:"pdf", name:"pdf", icon:"file-pdf" },
    { id:"social", name:"socials", icon:"hashtag" }
  ]


  let inputKindPattern=`^[a-z0-9]{${2},${15}}$`
  let inputKind=[
    { id:"text", name:"custom--text" },
    { id:"textarea", name:"custom--textarea" },
    { id:"tel", name:"custom--tel" },
    { id:"email", name:"custom--email" }
  ]
  let videoKind=[
    { id:"link", name:"custom--link" },
    { id:"file", name:"custom--file" }
  ]


  //Drag&Drop
  const dragstart = (event, type) => {
    editItemIndex=null
    event.dataTransfer.effectAllowed = 'copy'
    event.dataTransfer.dropEffect = 'copy'
    event.dataTransfer.setData('text/plain', JSON.stringify({type:type}))
  }

  async function add(type){
    return 
    items.push({type:type})
    items=items
    await tick()
    editItemIndex=items.length
  }
</script>

  
{#if showBlocks}
  <div class="col-12"> 
    <!--div class="shadow alert alert-info mt-2 m-b-4" role="alert">
      <I info-square me/><I18n>custom--Choose a block to add</I18n>
    </div-->
    <div class="m-1"><I18n>code--Drag a new block or select an existent to edit</I18n></div>
    {#each blocks as block}
      <div 
        draggable={true} 
        on:dragstart|stopPropagation={event => dragstart(event, block.id)}
        on:click={()=>add(block.id)} title={i18n(block.name, $lang)} class="draggable btn btn-sm btn-outline-primary m-1">
        <I type={block.iconType} icon={block.icon}/>
      </div>
    {/each}
  </div>
{/if}


{#if !showBlocks && editItemIndex!==null}
  <Input type="hr" label={getById(blocks,item.type).name} icon={$conf.faType + ' fa-'+getById(blocks,item.type).icon}/>
  {#if item.type=="title"}
    <Input set="Title" class="col-12" bind:value={item.label} icon type="text" multilang required translator label="custom--title"/>
  {/if}
  {#if item.type=="p"} 
    <Input set="Paragraph" class="col-12" bind:value={item.content} icon type="textarea" rows=6 multilang required translator label="custom--content"/>
  {/if}
  {#if item.type=="hr"} 
    <Input set=50 class="col-12" bind:value={item.width} type="range" showValue unit="%" label="width" min=10 max=100 step=1/>
  {/if}
  {#if item.type=="a"}
    <Input set="Link" class="col-12" bind:value={item.label} icon type="text" multilang required translator label="custom--label"/>
    <Input set="" class="col-12" bind:value={item.link} icon type="url" label="link"/>
    <Input class="col-12" set={false} bind:value={item.isButton} type="switch" option="custom--is button link"/>
    {#if !item.isButton}
      <Input class="col-12" set={false} bind:value={item.center} type="switch" option="custom--centered"/>
    {/if}
  {/if}
  {#if item.type=="input"}
    <Input set="Label" class="col-12" bind:value={item.label} icon type="text" multilang required translator label="custom--label"/>
    <Input set="input{items.length}" class="col-12" pattern={inputKindPattern} bind:value={item.id} icon="id" type="text" required label="custom--field id" help="custom--field id help"/>
    <Input class="col-lg-8" set="text" inline type="radio" options={inputKind} bind:value={item.kind}/>
    <Input class="col-lg-4" set={false} bind:value={item.required} type="switch" option="custom--required field"/>
  {/if}
  {#if item.type=="select"}
    <Input set="Label" class="col-12" bind:value={item.label} icon type="text" multilang required translator label="custom--label"/>
    <Input set="input{items.length}" class="col-12" pattern={inputKindPattern} bind:value={item.id} icon="id" type="text" required label="custom--field id" help="custom--field id help"/>
    <Input class="col-12" set={false} bind:value={item.required} type="switch" option="custom--required field"/>
    <Inputs bind:value={item.options} icon="option" type="text" multilang translator label="custom--options" required maxInputs=20/>
  {/if}
  {#if item.type=="checkbox"}
    <Input set="Label" class="col-12" bind:value={item.label} icon type="text" multilang required translator label="custom--label"/>
    <Input set="input{items.length}" class="col-12" pattern={inputKindPattern} bind:value={item.id} icon="id" type="text" required label="custom--field id" help="custom--field id help"/>
    <Input class="col-12" set={false} bind:value={item.required} type="switch" option="custom--required field"/>
  {/if}
  {#if item.type=="button" }
    <Input set="Go" class="col-12" bind:value={item.label} icon type="text" multilang required translator label="custom--label"/>
    <Input set="Thank you for your submit" class="col-12" bind:value={item.msgAfterSubmit} icon type="textarea" multilang required translator label="custom--message after submit"/>
  {/if}
  {#if item.type=="pdf" }
    <Input set="Download" class="col-12" bind:value={item.label} icon type="text" multilang required translator label="custom--label"/>
    <Input class="col-12" set={false} bind:value={item.isLink} type="switch" option="custom--is link"/>
    {#if item.isLink}
      <Input class="col-12" set={false} bind:value={item.center} type="switch" option="custom--centered"/>
    {/if}
    <Input class="col-12" bind:value={item.pdfCdn} type="file" accept="application/pdf"/>
  {/if}
  {#if item.type=="video"}
      <Input set="link" class="col-lg-8" inline type="radio" options={videoKind} bind:value={item.kind}/>
    {#if item.kind=="file" }
      <Input class="col-12" bind:value={item.videoCdn} type="file" accept="video/*"/>
    {:else}
      <Input set="" class="col-12" bind:value={item.link} icon type="url" label="link" required/>
    {/if}
  {/if}
  {#if item.type=="image"}
    <Input set="" class="col-12" bind:value={item.imageCdn} acompress aacompressType="png" aacompressWidth=600 type="file" accept="image/svg,image/png,image/jpeg,image/gif"/>
  {/if}
  {#if ["title","p"].includes(item.type)}
    <Input class="col-12" set={false} bind:value={item.center} type="switch" option="custom--centered"/>
  {/if}
  {#if item.type=="social"}
    <Input set="@yourtwittername" class="col-lg-6" bind:value={item.twitter} icon="twitter" type="text" label="twitter username"/>
    <Input class="col-lg-6" bind:value={item.twitterName} icon="" type="text" label="custom--label"/>

    <Input class="col-lg-6" bind:value={item.instagram} icon="instagram" type="text" label="instagram username"/>
    <Input class="col-lg-6" bind:value={item.instagramName} icon="" type="text" label="custom--label"/>

    <Input class="col-lg-6" bind:value={item.linkedin} icon="linkedin" type="url" label="linkedin link"/>
    <Input class="col-lg-6" bind:value={item.linkedinName} icon="" type="text" label="custom--label"/>

    <Input class="col-lg-6" bind:value={item.snapchat} icon="snapchat" type="text" label="snapchat username"/>
    <Input class="col-lg-6" bind:value={item.snapchatName} icon="" type="text" label="custom--label"/>

    <Input class="col-lg-6" bind:value={item.tiktok} icon="snapchat" type="text" label="tiktok username"/>
    <Input class="col-lg-6" bind:value={item.tiktokName} icon="" type="text" label="custom--label"/>

    <Input class="col-lg-6" bind:value={item.pinterest} icon="pinterest" type="text" label="pinterest account"/>
    <Input class="col-lg-6" bind:value={item.pinterestName} icon="" type="text" label="custom--label"/>

    <Input class="col-lg-6" bind:value={item.youtube} icon="youtube" type="url" label="youtube link"/>
    <Input class="col-lg-6" bind:value={item.youtubeName} icon="" type="text" label="custom--label"/>

    <Input class="col-lg-6" bind:value={item.whatsapp} icon="whatsapp" type="tel" label="whatsapp phone number" help="international format +33 (0) 1 02 03 04 05"/>
    <Input class="col-lg-6" bind:value={item.whatsappName} icon="" type="text" label="custom--label"/>

    <Input class="col-lg-6" bind:value={item.flickr} icon="flickr" type="text" label="flickr alias"/>
    <Input class="col-lg-6" bind:value={item.flickrName} icon="" type="text" label="custom--label"/>

    <Input class="col-lg-6" bind:value={item.facebook} icon="facebook" type="url" label="facebook page url"/>
    <Input class="col-lg-6" bind:value={item.facebookName} icon="" type="text" label="custom--label"/>

    <Input class="col-lg-6" bind:value={item.url} icon="url" type="url" label="url"/>
    <Input class="col-lg-6" bind:value={item.urlName} icon="" type="text" label="custom--label"/>

    <Input class="col-lg-6" bind:value={item.email} icon="email" type="email" label="email"/>
    <Input class="col-lg-6" bind:value={item.emailName} icon="" type="text" label="custom--label"/>

    <Input class="col-lg-6" bind:value={item.phone} icon="phone" type="tel" label="phone number" help="international format +33 (0) 1 02 03 04 05"/>
    <Input class="col-lg-6" bind:value={item.phoneName} icon="" type="text" label="custom--label"/>
  {/if}
  <div class="row">
    <Button class="col-auto" close={()=>{editItemIndex=null}}/>
  </div>
{/if}


<style>
  .draggable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
  }

  .dragNdrop-hovering {
    border: 5px solid blue;
    background-color: white;
  }

  .highlightMe:hover{
    border: 1px orange solid;
  }
</style>

