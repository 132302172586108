<script>
	import { conf, token, lang, userId, user, wsc } from './stores.js'
  import { api, cdn, i18n, thisYear, fuc, setRoute, omit } from './h.js'

	import I18n from './G_I18n.svelte'
	import Route from './G_Route.svelte'
	import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
	import Button from './G_Button.svelte'
	import Alert from './G_Alert.svelte'

	let localAlert = {}
	let showPwd
	let authPwd
	let authId
	let otp2fa
	let showOtp2fa=false;

	if($conf.qss.action=="try") setRoute('/auth/signUp')
	
  async function signIn(){
		localAlert={}
    if(!authId || !authPwd) return
    let data=await api('/app/users/login',{authId:authId,authPwd:authPwd,otp2fa: otp2fa},{spinner:true, noClearAuthStorage:true})
		if (data && data.token) {
			$token = data.token
			$userId=data.id
			$user=omit(data,['token'])
			$wsc.subscribe('user_' + $userId)
			setRoute('/')
		} else if(data && data.otp2fa){
			localAlert.success = data.message
			showOtp2fa=true
		}
		else {
			localAlert.danger = "Sign in fails"
		}
  }

  async function signUp(){
		localAlert={}
    let data=await api('/app/users/guest',{spinner:true})
    if(data) {
			$token=data.token
			$userId=data.id
			$user=omit(data,['token'])
			$wsc.subscribe('user_' + $userId)
			setRoute('/')
		} else {
      localAlert.danger="Sign up fails"
    }
	}

  async function forgotPwd(){
    if(!authId) return
		localAlert={}
    let data=await api('/app/users/credential/send',{authId:authId},{spinner:true})
    if(data) {
			localAlert.success=data
    }
	}
	async function sendOtp(){
    if(!authId) return
		localAlert={}
    let data=await api('/app/users/credential/sendotp',{authId:authId},{spinner:true})
    if(data) {
			localAlert.success=data
    }
	}
</script>

<Route path="/auth/*" valid={!$userId && !$token} Fade>
	<div style="z-index:10; background-image: url({cdn($conf.splash)});" class="authBgImg fs blur"></div>
	<div class="position-absolute top-50 start-50 translate-middle col-10 col-md-6 col-lg-4" style="z-index: 11;
		background-color:  white;
		background-color:  var(--authBgColor);
		padding: 26px;
		border-radius: 10px;
		-moz-border-radius: 10px;
		-webkit-border-radius: 10px;">
		<p class="text-center"><a href={$conf.companyUrl}>
				<img max-height="50px" src={cdn($conf.logo)} class="img-fluid" style="max-height: 150px;" alt="logo" />
			</a>
		</p>
		<p class="h3 text-center">{i18n($conf.slogan)}</p>

		<Alert local={localAlert} />


		<Route path="/auth/signIn" valid={$conf.signInOpen}>
			<Form>
				{#if !showOtp2fa}
					<Input bind:value={authId} type="email" placeholder="g--mail" />
					<Input bind:value={authPwd} showPassword type="password" noPattern placeholder="g--password" />
				{:else}
					<Input bind:value={otp2fa} placeholder="g--otp" />
				{/if}
			</Form>

			{#if !showOtp2fa}
				<div class="small text-center pointer">
					<div on:click={forgotPwd}>
						<I18n>Forgot your password?</I18n>
					</div>
				</div>
			{/if}

			<Button action={signIn} lg class="mt-4 mb-2" title="Sign in" />

			{#if $conf.signOtp && !showOtp2fa}
			<div class="small text-center">
				<I18n>OR</I18n>
			</div>
			<Button action={sendOtp} class="mt-4 mb-2" title="Send otp code" />{/if}

			{#if $conf.signUpOpen}<div class="small text-center pointer"><a route=false href="./#/auth/signUp">
					<I18n>Sign up</I18n>
				</a></div>{/if}
		</Route>

		<Route path="/auth/signUp" valid={$conf.signUpOpen}>
			<div class="text-center">
				<I18n>g--signUp help</I18n>
			</div>
			<Button action={signUp} lg class="mt-4 mb-2" title="Sign up" />
			{#if $conf.signInOpen}<div class="small text-center pointer"><a route=false href="./#/auth/signIn">
					<I18n>Sign in</I18n>
				</a></div>{/if}
		</Route>

		<div class="text-center small mt-4 globalText">
			<i class="fa-solid fa-copyright" aria-hidden="true"></i> {thisYear()} - <a class="link" target="new"
				href={$conf.companyUrl}>{$conf.title}</a> - V{$conf.version} - {fuc($conf.htmlRoot)}
		</div>
	</div>
	<!--div data-for-info="to avoid complain of lighthouse"><label for="email">.</label><label for="password">.</label></div-->
</Route>

<style> 
	.authBgImg{
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		-webkit-filter: blur(5px); /* Use for Safari 9+, Edge 17+ (not a mistake) and iOS Safari 9.2+ */
		filter: blur(5px);
	}
</style>
