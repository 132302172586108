<script>
  import { customerId, customer, customers } from './stores.js'
  import { asArray, getById, api } from './h.js'
  import Spinner from './G_Spinner.svelte'
  import Wrong from './G_Wrong.svelte'
</script>

{#await $customers then customers}
  {#if customers && customers.length>1}
    <div class="ms-2">
      <select class="form-select customer fs-4" bind:value={$customerId}>
        {#each customers as customer}
          <option value={customer.id} select={$customerId==customer.id}>{customer.name}</option>
        {/each}
      </select>
    </div>
  {:else}
    <div class="ms-2 fs-2 customer">{$customer.name || ""}</div>
  {/if}
{:catch}
  <Wrong/>
{/await}

<style>
  select{
    border: none;
  }
  option {
    font-size:1rem;
    color:initial;
  }
  .form-select {
    /* to put carret on left*/
    padding-left: 1.2rem;
    background-position: left 0rem center;
  }
  .customer{
    color:var(--brandColor);
    line-height:1.5rem;
  }

</style>
