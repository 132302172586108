<script>
  //npm install  apexcharts
  import ApexCharts from "apexcharts"
  import { check } from './h.js'
  import I18n from './G_I18n.svelte'

  export let data={}
  $: isDataChart = checkDataEmpty(data)

  function checkDataEmpty(){
    if(!data.series || !data.series.length) return false
    if ( (data.chart?.type=="donut" || data.chart?.type=="pie") && data.series?.every(val => val == 0)) {
      data.series=[1]
      data.labels.unshift('')
      data.colors.unshift('#f0f0f0')
      data.dataLabels = { enabled: false }
      data.tooltip = { enabled: false }
      return true
    }
    if(check(data, "series[0].data").length && check(data, "series[0].name")) return true
    if( check(data, "series").length  && !check(data, "series[0].name")) return true
  }

  const chart = (node, options) => {

    let myChart = new ApexCharts(node, options)
    myChart.render()

    return {
      update(options) {
        myChart.updateOptions(options)
      },
      destroy() {
        myChart.destroy()
      }
    }
  }
</script>
{#if isDataChart}
  <div use:chart={data} />
{:else}
  <div class="text-center"><i class="fa-light fa-exclamation-circle me-2"></i><I18n id='chart--Not enough data yet'/></div>
{/if}