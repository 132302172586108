<script>
  import { i18n, cdn, check } from './h.js'
  export let activity={}
</script>

{#if check(activity, "activity.u.isFooter")}
  <div class="footer small d-flex align-items-center justify-content-around p-2" style="background-color: {check(activity, 'activity.u.navbar.colorBg','#FFFFFF')};">
    {@html i18n(activity.u.footer)}
  </div>
{/if}


<style>
  .footer {
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,.5)!important;
    border-color: rgba(0, 0, 0, 0);
    afont-size: x-large;
  }
</style>