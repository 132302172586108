<script>
  import { tick } from 'svelte'
  import { conf, customer, permissions, modal, lang, customers, alert, route, w } from './stores.js'
  import { sleep, u, api, translate, i18n, setRoute, matchRoute, getById, cdn, inArray, asArray, isSame, genCode, anchor, clipBoard, dateTimeFormat, closest, colorRatio, pick, compressImage, fuc } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Inputs from './G_Inputs.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'
  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'
  import Stepper from './G_Stepper.svelte'
  import Alert from './G_Alert.svelte'
  
  import Preview from './L_PreviewCode.svelte'
  import Records from './L_Records.svelte'
  import Analytics from './L_Analytics.svelte'
  import CustomItems from './G_CustomItems.svelte'
  import CustomPage from './G_CustomPage.svelte'
  import Unity from '../unity/src/svelte/L_Start.svelte'
  
  import cloneDeep from 'lodash/cloneDeep'
  import isEqual from 'lodash/isEqual'
  import merge from 'lodash/merge'

  let stepEdit=""
  let stepDesign="logo"
  let stepEditFormValidity

  $: domains = ($customer.domains || []).filter((d)=>{return d.status=="valid"})

  let objs=[], obj, keyword="", sortBy={name:1}, filter={valid:true}, tagsSelected=[]
  let unityType, notUseNavbar, notUseColor
  let showUnity

  $: list(keyword, filter, tagsSelected, sortBy)
  $: if(matchRoute($route,'/codes/edit/:id?')) {
    get(matchRoute($route,'/codes/edit/:id?').routeParams.id)
  }
  $: if(matchRoute($route,'/codes')) {
    obj=false
    editItemIndex=null
  }

  let wasType
  $: if(obj && obj.type) { 
    if(!obj.u[obj.type]) {
      obj.u[obj.type]={}
      if(obj.type=="custom") obj.u.custom.items=[]
    }
    if(obj.type=="link" && obj.u.link.url) checkIframe()
    unityType=getById($conf.unitys, obj.type)
    if(obj.type=="app"){
      notUseNavbar=obj.u.app.isDirect
      notUseColor=obj.u.app.isDirect
    }
  }


  let defaultCode={
    "type":"link",
    "u": {
      "link": {},
      "navbar": {
        "title": {
          "link": {}
        }
      },
      "footer": {},
      "hooks": {}
    },
    "options": {},
    "dynamic": true,
    "ecl": "H",
    "useBackground": true,
    "useEyeBallColor": true,
    "useEyeFrameColor": true,
    "color": "#000000",
    "useColorGradient": true,
    "cellType": "morpion",
    "usePatternCell": false,
    "shake": false,
    "eyeBallType": "tear",
    "usePatternEyeBall": false,
    "eyeBallRotate": true,
    "eyeFrameType": "tear",
    "usePatternEyeFrame": false,
    "eyeRotate": true,
    "useLogo": true,
    "backgroundRounded": "0",
    "background": "#ffffff",
    "useBackgroundGradient": false,
    "logo": "/assets/placeholder-logo.png",
    "logoBlur": false,
    "logoRemoveBg": true,
    "colorGradientType": "linear",
    "colorGradientToOffset": "70",
    "colorGradientTo": "#ff0000",
    "colorGradientFromOffset": "30",
    "colorGradientFrom": "#000000",
    "colorGradientAngle": "45",
    "eyeFrameColor": "#000000",
    "eyeBallColor": "#ff0000"
  }

  async function add(){
    let licence=await api(`/app/customers/licence/get`)
    if(!licence.available?.codes) $modal={
      cancel:true,
      title:i18n("licence--No more codes"), 
      msg:i18n("licence--No more codes msg"), 
      color:'warning', actionLabel:i18n("licence--Upgrade"), 
      action:async ()=>{
        setRoute("/account/licence")
    }, cancel:()=>{}}
    else {
      await get()
      setRoute("/codes/edit")
    }
  }

  async function get(id){
    stepEdit=""
    obj=cloneDeep(defaultCode)
    
    if(id) merge(obj, await api(`/app/customers/codes/get/${id}`))
    obj=obj //hack because somtimes refresh was not done in edit
    initialShortId=obj.shortId
    stepDesign="logo"
    if(obj.id && obj.valid) {
      if(obj.dynamic) stepEdit="usage"
      else stepEdit="design"
    }
    else  stepEdit="infos"
  }

  isSame("listCodes","clean")
  async function list(...checkArgs){
    if(checkArgs.length && isSame("listCodes", checkArgs)) return
    let req={ ...filter, ...(tagsSelected.length?{tags: { $in: tagsSelected}}:{})}
    objs = await api(`/app/customers/codes/list`, {
      request: {...req},
      keyword:keyword,
      sortBy: {favorite:-1, ...sortBy}
    })
  }

  async function update(){
    await api(`/app/customers/codes/set`,{obj: obj})
    list()
    getTags()
    setRoute("/codes")
  }

  async function del(obj){
    $modal={cancel:true, title:`${i18n("g--Delete")} ${i18n(obj.name)}`, msg:i18n("g--Confirm delete"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/codes/delete/${obj.id}`)
      list()
      setRoute("/codes")
    }}
  }

  async function duplicate(obj){
    $modal={cancel:true, title:`${i18n("codes--Duplicate")} ${i18n(obj.name)}`, color:'warning', confirm:async ()=>{
      let data=await api(`/app/customers/codes/clone/${obj.id}`)
      list()
      setRoute("/codes")
    }}
  }

  let transferToCustomerId
  async function transfer(codeId, toCustomerId){
    await api(`/app/customers/codes/transfer/${codeId}/${toCustomerId}`)
    $alert.success=i18n("Transfer", $lang) + ' ' +obj.name + ' to ' + getById($customers, toCustomerId).name
    list()
    setRoute("/codes")
  }

  async function favorite(obj){
    let data=await api(`/app/customers/codes/favorite/${obj.id}`,{favorite: !obj.favorite})
    list()
  }

  let tags=[]
  getTags()
  async function getTags(){
    tags = await api('/app/customers/codes/tags/list')
  }

  function printImg(evt) {
    let shortId = closest(evt,'ref')
    let popup
    function closePrint () {
      if ( popup ) {
        popup.close();
      }
    }
    //need qrcode route: printing can be only request from the same domain
    popup = window.open(`/api/v4/qr/${shortId}/card`)
    popup.onbeforeunload = closePrint;
    popup.onafterprint = closePrint;
    popup.focus() // Required for IE
    popup.print()
  }
  
	function seeYourTarget(obj, preview) {
    window.open(`https://${obj.domain}/${obj.shortId}/${preview?"p":""}`,"eQrito", "width=400,height=650,resizable,scrollbars=yes,status=0");
	}


	function seeYourPrintingCard(evt) {
    let shortId = closest(evt,'ref')
    window.open(`${$conf.apiUrl}/qr/${shortId}/card`)
	}
	function downloadYourQR(evt){
    let shortId = closest(evt,'ref')
    let type=closest(evt,'type') || 'png'
    let size=closest(evt,'size') || '256'
    window.open(`${$conf.apiUrl}/qr/${shortId}/dl/${type}/${size}`)
	}

  async function sendQrscreen(obj){
    let mailTo=obj.u.card.email
    let nameTo=obj.u.card.firstname
    await api(`/app/customers/qrscreen/mail/${obj.id}/${mailTo}/${nameTo}`)
    $alert.success=i18n("codes--Qrscreen sent to", $lang) + ' ' + mailTo
  }


  function inRangeDate(opt){
    const now=new Date()
    if(
      (!opt.startDateValid || now > new Date(opt.startDate)) &&
      (!opt.endDateValid || now < new Date(opt.endDate))) return 'success'
    else return 'danger'
  }


  $: checkQr(obj)
  let checkQrAlert={}
  function checkQr(){
    if(!obj) return
    let ratio=0, ratioBall=0, ratioFrame=0, limitRatio=0.55
     if(!obj.useColorGradient && obj.useBackgroundGradient) ratio=Math.max(...[colorRatio(obj.color, obj.backgroundGradientFrom),colorRatio(obj.color, obj.backgroundGradientTo)])
    if(!obj.useColorGradient && !obj.useBackgroundGradient) ratio=colorRatio(obj.color, obj.background)
    if(obj.useColorGradient && !obj.useBackgroundGradient) ratio=Math.max(...[colorRatio(obj.background, obj.colorGradientFrom),colorRatio(obj.background, obj.colorGradientTo)])
    if(obj.useColorGradient && obj.useBackgroundGradient) ratio=Math.max(...[
      colorRatio(obj.backgroundGradientFrom, obj.colorGradientFrom),
      colorRatio(obj.backgroundGradientTo, obj.colorGradientTo),
      colorRatio(obj.backgroundGradientFrom, obj.colorGradientTo),
      colorRatio(obj.backgroundGradientTo, obj.colorGradientFrom)
    ])
    if(obj.useEyeBallColor && !obj.useBackgroundGradient) ratioBall=colorRatio(obj.eyeBallColor, obj.background)
    if(obj.useEyeBallColor && obj.useBackgroundGradient) ratioBall=Math.max(...[colorRatio(obj.eyeBallColor, obj.backgroundGradientFrom),colorRatio(obj.eyeBallColor, obj.backgroundGradientTo)])
    if(obj.useEyeFrameColor && !obj.useBackgroundGradient) ratioFrame=colorRatio(obj.eyeFrameColor, obj.background)
    if(obj.useEyeFrameColor && obj.useBackgroundGradient) ratioFrame=Math.max(...[colorRatio(obj.eyeFrameColor, obj.backgroundGradientFrom),colorRatio(obj.eyeFrameColor, obj.backgroundGradientTo)])
   

    checkQrAlert.ratio=ratio.toFixed(1)
    checkQrAlert.ratioFrame=ratioFrame.toFixed(1)
    checkQrAlert.ratioBall=ratioBall.toFixed(1)
    //console.log(ratio)
    checkQrAlert.color=ratio>=limitRatio?true:false
    checkQrAlert.eyeBallColor=ratioBall>=limitRatio?true:false
    checkQrAlert.eyeFrameColor=ratioFrame>=limitRatio?true:false
    checkQrAlert.background=obj.background?.toLowerCase()!="#ffffff" || obj.useBackgroundGradient?true:false
    //checkQrAlert.background=obj.background!="#ffffff" || obj.useBackgroundGradient?true:false
    checkQrAlert.ecl=obj.useLogo && obj.ecl!="H"?true:false
  }

  let optionsGradientType=[
    { id: "linear", name:"codes--Linear" },
    { id: "radial", name:"codes--Radial" }
  ]
   
  let optionsEcl=[
    {id:'L', name:'L (7%)'},
    {id:'M', name:'M (15%)'},
    {id:'Q', name:'Q (25%)'},        
    {id:'H', name:'H (30%)'},
  ]

  //properties list 
  let codePick=['id','shortId','dynamic','content','background','useBackground','backgroundRounded','backgroundGradientAngle','backgroundGradientFrom','backgroundGradientFromOffset','backgroundGradientTo','backgroundGradientToOffset','backgroundGradientType','cellType','color','colorGradientAngle','colorGradientFrom','colorGradientFromOffset','colorGradientTo','colorGradientToOffset','colorGradientType','domain','ecl','eyeBallColor','eyeBallType','eyeFrameColor','eyeFrameType','eyeRotate','eyeBallRotate','logo','logoBlur','logoBlurColor','logoRemoveBg','name','notes','shake','tags','useBackgroundGradient','useColorGradient','useEyeBallColor','useEyeFrameColor','useLogo','valid','patternCell','patternEyeFrame','patternEyeBall','usePatternCell','usePatternEyeFrame','usePatternEyeBall','authorizeGallery']

  let minlengthShortId=5
  let maxlengthShortId=30
  let patternShortId=`^[A-Z0-9-\+]{${minlengthShortId},${maxlengthShortId}}$`

  function optsAsArraySVG(opts, size=40){
    let tagBefore=`<svg viewbox="0 0 70 70" style="fill: lightgray;" height=${size} width=${size}>`
    let tagAfter=`</svg>`
    let newArr=[]
    let opt
    for(const property in opts) {
      opt={id:property}
      //if(typeof opts[property] === 'object') opt.name=`<span class="cellTypeSVG">${property}</span>`
      //else opt.name=`<span class="cellTypeSVG">${property}</span>${tagBefore} ${opts[property]} ${tagAfter}`
      if(typeof opts[property] === 'object') opt.name=`<span title="${property}" class="cellTypeSVG">${property}</span>`
      else opt.name=`<span title="${property}" class="cellTypeSVG">${tagBefore} ${opts[property]} ${tagAfter}</span>`
 
      newArr.push(opt)
    }
    return newArr
  }

  let lastGen={}, countGen=-1
  let eyeFrames,eyeBalls,cells

  setUp()
  async function setUp(){
    let qrcodeCapabilities = await api('/public/qrcode/capabilities')
    eyeFrames=optsAsArraySVG(qrcodeCapabilities.eyeFrames,40)
    eyeBalls=optsAsArraySVG(qrcodeCapabilities.eyeBalls,60)
    cells=optsAsArraySVG(qrcodeCapabilities.cells,30)
  }


  let qrCodeSrc
  $: if(stepEdit=="design") generateQR(obj)
  
  async function generateQR(values) {
   
    if(!values) return
    if(!values.shortId && values.dynamic) return
    
    let regex = new RegExp(patternShortId)
    if(values.shortId && !regex.test(values.shortId)) return
    countGen++

    //keep only properties used by to gen
    let payload=pick(values, codePick)
    //avoid to recall the exact same qr
    if(isEqual(payload,lastGen)) return
    lastGen=cloneDeep(payload)
   
    payload.format="png"
    payload.size=512
    //if(!payload.content && values.domain && values.shortId) payload.content=`https://${values.domain}/${values.shortId}`
    //else if(!payload.content && values.shortId) payload.content=`https://${$conf.qrDomain}/${values.shortId}`
    if(!values.dynamic && values.content) payload.content=values.content
    if(!values.dynamic && !values.content) payload.content=i18n('codes--No content qr')
    if(values.dynamic) payload.content=`https://${values.domain}/${values.shortId}`
    
    //if(payload.logo && payload.logo.content) payload.logo=await compressImage(payload.logo.src, 100, 'jpeg', 0.2)
    if(payload.logo && payload.logo.content) payload.logo=await compressImage(payload.logo.src, 100, 'png', 0.2)
    //console.log("generateQR", payload)
    if(values.dynamic) payload.content+="/@" //use to test if a QR is readable
    let ret= await api("/qr/generate", payload)
    ret = await ret.blob()
    qrCodeSrc= URL.createObjectURL(ret)
	}

  let lastCheckShortIdValue="", lastCheckShortIdRet, initialShortId
  async function checkShortId(value="", elt){
    if(value.length<5) return true //do not try to check, pattern will make it invalid
    if(value==initialShortId) return true
    if(lastCheckShortIdValue==value) return lastCheckShortIdRet
    let fqdn=""
    lastCheckShortIdRet= await api(`/app/customers/check/shortId/codes/${value}/${fqdn}`)
    lastCheckShortIdValue=value
    return lastCheckShortIdRet
  }


  let errorFrame=true
  async function checkIframe(iframe){
    let ret=await api(`/app/customers/test/fetch/policy`, {url: obj.u.link.url})
    errorFrame=!ret
  }

  let editItemIndex=null

  let previewSize=100
  let previewSizeMini=20

  
  let navbarTypes=[
    { id:"none", name:"unity--navbar type none" },
    { id:"logo", name:"unity--navbar type logo" },
    { id:"title", name:"unity--navbar type title" },
    { id:"logotitle", name:"unity--navbar type logotitle" },
    { id:"banner", name:"unity--navbar type banner" }
  ]
  let placementKind=[
    { id:"top", name:"unity--placement top" },
    { id:"center", name:"unity--placement center" },
    { id:"bottom", name:"unity--placement bottom" }
  ]

  let standartUnitysNavbar = ['custom','info','card','map','app','lead','event','game','locator','arboretum','social','media','coupon','survey']
  let standartUnitysColor  = ['custom','info','card','map','app','lead','event','game','locator','arboretum','social','media','coupon','survey']
</script>


<CustomerBanner/>


<Route path="/codes/*" pathHide={["/codes/edit/*", "/codes/transfer/*","/codes/analytics/*", "/codes/records/*" ]} fsContent>
  <div class="container-lg fsContent">
    
    <Title title withCustomerSelector back/>

    <FilterLine {tags} bind:tagsSelected search bind:keyword 
      create={$permissions?add:false} createBulk={false}
      bind:filter filters={[
      { filter: {valid:true}, title:'g--Active' },
      { filter: {valid:false}, title:'g--Not active' }
      ]}
  
      bind:sortBy sortBys={[
        { id:'name', title:'g--By name'},
        { id:'scanCount', title:'g--By Scan', desc:true},
        { id:'date', title:'g--By update date', desc:true}
      ]}
    />

    <List type="card" cardShadow cardUp cardFooterDate="date" refresh={()=>list()} title="g--codes" {objs} ref="shortId">

      <div slot="header" title={obj.notes} class="w-100 d-flex justify-content-between" let:obj>
        <div class="fs-6 fw-bold">{obj.name}</div>
        <div class="text-nowrap">
          {#if obj.dynamic}
            {#if obj.type}
              <I icon={getById($conf.unitys, obj.type,'id',true).icon || 'bullseye-arrow'}/> <span class="ucfirst">{i18n("unity--"+obj.type)}</span>
              
              {#if obj.options && (obj.options.endDateValid || obj.options.startDateValid)}
                <span title={i18n("codes--Scan schedule")}><I clock shake class="text-{inRangeDate(obj.options)}"/></span>
              {/if}
              {#if obj.options && obj.options.protected}
                <span title={i18n("codes--Protected code")}><I lock fade success/></span>
              {/if}
              {#if obj.options && (obj.options.mailhook || obj.options.webhook)}
                <span title={i18n("codes--Action on scan")}><I plug/></span>
              {/if}
            {:else}
              <span class="text-danger fw-bold"><I me exclamation-triangle/>{i18n("codes--No content")}</span>
            {/if}
          {:else}
            <span class="text-success fw-bold">
              <I me certificate/><I18n>codes--Static</I18n>
            </span>
          {/if}
          <span title={i18n("codes--Favorite")} on:click={()=>favorite(obj)}><I class={obj.favorite?"text-warning":""} solid={obj.favorite} star/></span>
        </div>
      </div>



      <div slot="body" let:obj>
        <div class="container-lg">

          <div class="row">
            <div class="col text-center pe-0">
              <div class="preview" on:click={()=>{setRoute(`/codes/preview/${obj.id}`)}} title={i18n("codes--Preview")}>
                <img min-width=88 class="w-100 mt-2" src={cdn(`${obj.customerId}/preview/${obj.id}.jpeg`)} alt=${obj.shortId}>
              </div>
            </div> 

            <div class="col small choices mt-3">
              {#if obj.dynamic && obj.type}
                <div on:click={()=>{setRoute(`/codes/analytics/${obj.id}`)}} class="pointer text-success fw-bold text-nowrap">
                  <I me barcode-read/>{obj.scanCount || 0} <I18n>g--Scans</I18n>
                </div>
              {/if}

              {#if obj.recordsCount}
                <div on:click={()=>{setRoute(`/codes/records/${obj.id}`)}} class="pointer text-success fw-bold text-nowrap me-4">
                  <I me database/>{obj.recordsCount || 0} <I18n>codes--Records</I18n>
                </div>
              {/if}

              <div on:click={()=>{setRoute(`/codes/edit/${obj.id}`)}} class="pointer text-success fw-bold text-nowrap"><I me palette/><I18n>Modify</I18n></div>
            </div>

            <div class="col small choices mt-3">
              
              <div class="dropdown">
                <div class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <I me cog/><I18n>g--Action</I18n>
                </div>
                <ul class="dropdown-menu">
                  <li><div class="pointer dropdown-item" on:click={()=>duplicate(obj)}><I me copy/><I18n>codes--Duplicate</I18n></div></li>                            
                  {#if $customers.length > 1}
                    <li><div class="pointer dropdown-item" on:click={()=>{setRoute(`/codes/transfer/${obj.id}`)}}><I me exchange/><I18n>codes--Transfer</I18n></div></li>  
                  {/if}
                  <li><div class="pointer dropdown-item text-danger" on:click={()=>del(obj)}><I me trash/><I18n>g--Delete</I18n></div></li>
                </ul>
              </div>

              <div class="dropdown">
                <div class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <I me download/><I18n>g--Download</I18n>
                </div>
                <ul class="dropdown-menu">
                  <li><div on:click={printImg} class="pointer dropdown-item"><I me print/><I18n>codes--Print QR Code</I18n></div></li>                            
                  <li><div on:click={downloadYourQR} type="pdf" size=256 class="pointer dropdown-item"><I me download/> PDF</div></li>
                  <li><div on:click={downloadYourQR} type="png" size=2048 class="pointer dropdown-item"><I me download/> PNG HD</div></li>
                  <li><div on:click={downloadYourQR} type="png" size=256 class="pointer dropdown-item"><I me download/> PNG (preview)</div></li>
                  <li><div on:click={downloadYourQR} type="svg" size=256 class="pointer dropdown-item"><I me download/> SVG</div></li>
                  <li><div on:click={downloadYourQR} type="jpg" size=512 class="pointer dropdown-item"><I me download/> JPG (no transparency)</div></li>
                  <li><div on:click={seeYourPrintingCard} class="pointer dropdown-item"><I me eye/><I18n>codes--See your printing Card</I18n></div></li>
                  {#if obj.type=="card"}
                    <li><div on:click={()=>sendQrscreen(obj)} class="pointer dropdown-item"><I me envelope/><I18n>codes--Send qrscreen by email</I18n></div></li>
                  {/if}

                </ul>
              </div>

            </div>

          </div>
          <div class="small">{#each asArray(obj.tags) as tag}<span class="badge-tag badge bg-secondary me-1">{tag}</span>{/each}</div>
        </div>
      </div>

      <div slot="footer" let:obj>
        {#if obj.type}
          <div>
            {#if obj.dynamic }
              <div class="dropdown">
                <div class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <I me eye beat/>https://{obj.domain || $conf.qrDomain}/{obj.shortId}
                </div>
                <ul class="dropdown-menu">
                  <li><div class="pointer dropdown-item" on:click={()=>seeYourTarget(obj, true)} title={i18n("codes--See your target")}><I18n>codes--See your target</I18n></div></li>                            
                  <li><div class="pointer dropdown-item" on:click={()=>seeYourTarget(obj)} title={i18n("codes--See your target real")}><I18n>codes--See your target real</I18n></div></li> 
                </ul>
              </div>
            {/if}
          </div>
        {/if}
      </div>
    </List>
  </div>
</Route>





<!-- EDIT -->
<Route path="/codes/edit/:id?" let:routeParams fsContent>
  {#if obj }
    <div class="container fsContent">

      <div class="row fsContent">
    
        <!-- COL LEFT or FULL -->
        <div class:col-lg-8={stepEdit!="infos"} class="col-12 fsContent">
          <Title title={obj.id?`QR - ${obj.name}`: i18n("code--New QR")}  back="/codes"/>


          <!-- STEPPER -->
          <Stepper stepValidity={stepEditFormValidity} completion={!obj.id} bind:step={stepEdit} colorStep={$conf.style.brandColor2} colorActive={$conf.style.brandColor} steps={[
            { name:"code--Infos", step:"infos", icon:obj.id?"info":""},
            { name:"code--Design", step:"design", icon:obj.id?"qrcode":""},
            { name:"code--Usage", step:"usage", icon:obj.id?"sliders":"", hide: obj.dynamic?false:true}
          ]}/>
          <hr>

          <!-- QR TOP MIN SCREEN -->
          {#if stepEdit=="design"}
            <div class="d-lg-none">
              <div class="text-center">
                <img src="{qrCodeSrc}" alt="" title="" style="width:{previewSizeMini}%;">
                <Input type="range" label="code--Size design preview" nomargin set=20 min=10 max=100 bind:value={previewSizeMini}/>
              </div>
            </div>
          {/if}
          <!-- END QR TOP MIN SCREEN -->

        
          <!-- INFOS -->
          {#if stepEdit=="infos"}
            <div class="fsContent">
              <Form fsContent norow bind:formValid={stepEditFormValidity}>
                <div class="fsScroll" style="height:100px;"><!-- height is a hack to let bottom buttons appears !-->
                  <div class="row">
                    <Input class="col-lg-10" bind:value={obj.name} placeholder="codes--name of your qr, not public" label="name" helpBefore="code--name help" minlength=1 maxlength=100 required/>
                        
                    {#if obj.dynamic}
                      <Input class="col-lg-2" lg set={true} bind:value={obj.valid} type="switch" label="active" option="yes"/>
                    {/if}

                    <Input set={[]} class="col-lg-12" bind:value={obj.tags} canAdd placeholder="g--Addtag" options={tags} type="tag" label="codes--tags" helpBefore="codes--tags help"/>
                    <Input class="col-lg-12" bind:value={obj.notes} type="textarea" placeholder="codes--your notes" label="codes--notes"/>
                    {#if obj.id}
                      <Input set={false} class="col-12" bind:value={obj.authorizeGallery} type="switch" option="codes--proud expose"/>
                    {/if}
                  </div>
                </div>
                <div class="fsContentHorizontal flex-row-reverse">
                  <Button hideTitleXs validity title="Design" next={()=>stepEdit="design"}/>
                  <Button hideTitleXs validity cond={Boolean(obj.id)} {update}/>
                </div>
              </Form>
            </div>
          {/if}
          <!-- END INFOS -->
            

          <!-- DESIGN -->
          {#if stepEdit=="design"}
            <div class="fsContent">
              <Form fsContent norow bind:formValid={stepEditFormValidity}>
                {#if obj.id}
                  <div class="small text-warning text-center"><I18n>code--design change msg</I18n></div>
                {/if}

                <!-- DESIGN CHOICES -->
                <div class="btn-group mt-1">
                  <input type="radio" class="btn-check" bind:group={stepDesign} value="logo" id="btnradio1">
                  <label class="btn btn-outline-secondary" for="btnradio1"><I18n>codes--Logo</I18n></label>
                  
                  <input type="radio" class="btn-check" bind:group={stepDesign} value="eyes" id="btnradio2">
                  <label class="btn btn-outline-secondary" for="btnradio2"><I18n>codes--Eyes</I18n></label>

                  <input type="radio" class="btn-check" bind:group={stepDesign} value="cells" id="btnradio3">
                  <label class="btn btn-outline-secondary" for="btnradio3"><I18n>codes--Cells</I18n></label>

                  <input type="radio" class="btn-check" bind:group={stepDesign} value="colors" id="btnradio4">
                  <label class="btn btn-outline-secondary" for="btnradio4"><I18n>codes--Colors</I18n></label>
                  
                  <input type="radio" class="btn-check" bind:group={stepDesign} value="options" id="btnradio5">
                  <label class="btn btn-outline-secondary" for="btnradio5"><I18n>codes--Options</I18n></label>
                </div>
                <!-- END DESIGN CHOICES -->

                <div class="fsScroll">


                  <!-- logo -->
                  <div class="row" class:d-none={stepDesign!="logo"}>
                    <Input lg set={false} class="col-sm-6" bind:value={obj.useLogo} type="switch" option="codes--Use logo"/>
                    {#if obj.useLogo}
                      <Input lg set={true} class="col-sm-6" bind:value={obj.logoRemoveBg} type="switch" option="codes--Remove Background"/>
                      <Input lg set={false} class="col-sm-6" bind:value={obj.logoBlur} type="switch" option="codes--Blur"/>
                      {#if obj.logoBlur}
                        <Input set="#ffffff" class="col-sm-6" bind:value={obj.logoBlurColor} type="color" colorText smooth label="codes--Blur color"/>
                      {/if}
                      <div>
                        <Input required set="/assets/placeholder-logo.png" class="" bind:value={obj.logo} accept="image/png,image/jpeg,image/gif,image/svg" type="file" label="codes--Logo" icon="image"/>
                      </div>
                      {#if checkQrAlert.ecl}
                        <Alert local={{ noDismiss:true, warning:"codes--Qr alert ecl logo"}}/>
                      {/if}
                    {/if}
                  </div>
                    

                  <!-- eyes -->
                  <div class="row" class:d-none={stepDesign!="eyes"}>
                    <Input type="hr" label="codes--Eye frame"/>
                    <Input class="col-lg-6" lg set={false} bind:value={obj.eyeRotate} type="switch" option="codes--Eye rotate"/>
                    <Input class="col-lg-6" lg set={false} bind:value={obj.usePatternEyeFrame} type="switch" option="codes--use svg pattern"/>
                    {#if obj.usePatternEyeFrame}
                      <Input maxSize=50 set="" class="" bind:value={obj.patternEyeFrame} accept=".svg" type="file" alabel="codes--svg pattern" aicon="image" help="codes--svg pattern rules" helpBefore="<a location=true href='https://cdn.eqrito.com/assets/frame.svg?dl=true'>frame.svg model</a>"/>            
                        {:else}
                        <Input set="tears" labelOnly inline bind:value={obj.eyeFrameType} type="radio" options={eyeFrames} alabel="codes--Eye frame"/>
                        {/if}
                        <Input type="hr" label="codes--Eye ball"/>
                        <Input class="col-lg-6" lg set={false} bind:value={obj.eyeBallRotate} type="switch" option="codes--Eye ball rotate"/>
                    <Input class="col-lg-6" lg set={false} bind:value={obj.usePatternEyeBall} type="switch" option="codes--use svg pattern"/>
                    {#if obj.usePatternEyeBall}
                      <Input maxSize=50 set="" class="" bind:value={obj.patternEyeBall} accept=".svg" type="file" alabel="codes--svg pattern" aicon="image" help="codes--svg pattern rules" helpBefore="<a location=true href='https://cdn.eqrito.com/assets/ball.svg?dl=true'>ball.svg model</a>"/>            
                    {:else}
                      <Input set="tears" labelOnly inline bind:value={obj.eyeBallType} type="radio" options={eyeBalls} alabel="codes--Eye ball"/>
                    {/if}
                  </div>
                      

                  <!-- cells -->
                  <div class="row" class:d-none={stepDesign!="cells"}>
                    <!--Input type="hr" label="codes--Cells choice"/-->
                    <Input lg class="col-lg-6 shakeIt" set={false} bind:value={obj.shake} type="switch" option="codes--Shake it"/>
                    <Input lg class="col-lg-6" set={false} bind:value={obj.usePatternCell} type="switch" option="codes--use svg pattern"/>
                    {#if obj.usePatternCell}
                      <Input maxSize=50 set="" class="" bind:value={obj.patternCell} accept=".svg" type="file" alabel="codes--svg pattern" aicon="image" help="codes--svg pattern rules" helpBefore="<a location=true href='https://cdn.eqrito.com/assets/cell.svg?dl=true'>cell.svg model</a>"/>            
                    {:else}
                      <Input set="morpion" labelOnly inline bind:value={obj.cellType} type="radio" options={cells} alabel="codes--Cell"/>
                    {/if}
                  </div>
              

                  <!-- colors -->
                  <div class="row" class:d-none={stepDesign!="colors"}>
                    <Input type="hr" label="codes--Cells" icon="fa-square-full"/>
                    {#if checkQrAlert.color}
                      <Alert local={{ noDismiss:true, danger:"codes--Qr alert color"}}/> <code>{checkQrAlert.ratio}</code>
                    {/if}
                    
                    <Input lg set={false} bind:value={obj.useColorGradient} type="switch" option="codes--Use color gradient"/>

                    {#if obj.useColorGradient}         
                      <Input set="#000000" class="col-sm-4" bind:value={obj.colorGradientFrom} type="color" smooth colorText label="codes--From color"/>
                      <Input set=40 class="col-sm-8" bind:value={obj.colorGradientFromOffset} smooth type="range" showValue unit="%" label="codes--Offset" min=1 max=99 step=1/>
                      <Input set="#ff0000" class="col-sm-4" bind:value={obj.colorGradientTo} type="color" smooth colorText label="codes--To color"/>
                      <Input set=60 class="col-sm-8" bind:value={obj.colorGradientToOffset} smooth type="range" showValue unit="%" label="codes--Offset" min=1 max=99 step=1/>
                      <Input set="linear" class="col-sm-4" inline bind:value={obj.colorGradientType} type="radio" options={optionsGradientType} label="codes--Gradient type"/>
                  
                      {#if obj.colorGradientType=='linear'}
                        <Input set=45  class="col-sm-8" bind:value={obj.colorGradientAngle} smooth type="range" showValue unit="°" label="codes--Angle" min=0 max=360 step=1/>
                      {/if}
                    {/if}
                    {#if !obj.useColorGradient}
                      <Input set="#000000" bind:value={obj.color} type="color" smooth colorText label="codes--Cell color"/>
                    {/if}

                    <Input type="hr" label="codes--Eyes" icon="fa-eye"/>
                    <Input lg set={false} bind:value={obj.useEyeFrameColor} type="switch" option="codes--Use eye frame color"/>
                    {#if obj.useEyeFrameColor}
                      {#if checkQrAlert.eyeFrameColor}
                      <Alert local={{ noDismiss:true, danger:"codes--Qr alert color"}}/> <code>{checkQrAlert.ratioFrame}</code>
                      {/if}
                      <Input set="#000000" bind:value={obj.eyeFrameColor} type="color" smooth colorText label="codes--Eye frame color"/>
                    {/if}
                    <Input lg set={false} bind:value={obj.useEyeBallColor} type="switch" option="codes--Use eye ball color"/>
                    {#if obj.useEyeBallColor}
                      {#if checkQrAlert.eyeBallColor}
                        <Alert local={{ noDismiss:true, danger:"codes--Qr alert color"}}/> <code>{checkQrAlert.ratioBall}</code>
                      {/if}
                      <Input set="#000000" bind:value={obj.eyeBallColor} type="color" smooth colorText label="codes--Eye ball color"/>
                    {/if}

                    <Input type="hr" label="codes--Background" icon="fa-send-backward"/>
                    <Input lg set={true} bind:value={obj.useBackground} type="switch" option="codes--Use background"/>
                    {#if checkQrAlert.background}
                      <Alert local={{ noDismiss:true, danger:"codes--Qr alert bg"}}/>
                    {/if}

                    {#if obj.useBackground} 
                      <Input lg set={false} bind:value={obj.useBackgroundGradient} type="switch" option="codes--Use background gradient"/>

                      {#if obj.useBackgroundGradient}         
                        <Input set="#ffffff" class="col-sm-4" bind:value={obj.backgroundGradientFrom} type="color" smooth colorText label="codes--From color"/>
                        <Input set=40 class="col-sm-8" bind:value={obj.backgroundGradientFromOffset} smooth type="range" showValue unit="%" label="codes--Offset" min=1 max=99 step=1/>
                        <Input set="#EBEBEB" class="col-sm-4" bind:value={obj.backgroundGradientTo} type="color" smooth colorText label="codes--To color"/>
                        <Input set=60 class="col-sm-8" bind:value={obj.backgroundGradientToOffset} smooth type="range" showValue unit="%" label="codes--Offset" min=1 max=99 step=1/>
                        <Input set="linear" class="col-sm-4" inline bind:value={obj.backgroundGradientType} type="radio" options={optionsGradientType} label="codes--Gradient type"/>
                    
                        {#if obj.backgroundGradientType=='linear'}
                          <Input set=0  class="col-sm-8" bind:value={obj.backgroundGradientAngle} smooth type="range" showValue unit="°" label="codes--Angle" min=0 max=360 step=1/>
                        {/if}
                      {/if}
                      {#if !obj.useBackgroundGradient}
                        <Input set="#ffffff" bind:value={obj.background} type="color" smooth colorText label="codes--Background color"/>
                      {/if}

                      <Input set=0 class="col-sm-12" bind:value={obj.backgroundRounded} smooth type="range" showValue unit="px" label="codes--Rounded" min=0 max=50 step=1/>
                    {/if}
                  
                  </div>
                  

                  <!-- options -->
                  <div class="row" class:d-none={stepDesign!="options"}>

                    <Input set="H" bind:value={obj.ecl} inline type="radio" options={optionsEcl} icon="fa-file-certificate" label="codes--quality"/>
                    {#if checkQrAlert.ecl && obj.ecl!="H"}
                      <Alert local={{ noDismiss:true,  warning:"codes--Qr alert ecl logo 2"}}/>
                    {/if}
                    <Input lg set={true} class="col-12" bind:value={obj.dynamic} type="switch" option="codes--Dynamic"/>
                    
                    {#if obj.dynamic}
                      <Input class="col-sm-5" set={[ ...domains , ...$conf.qrDomains ][0].id} bind:value={obj.domain} type="select" label="codes--domain" options={[ ...domains, ...$conf.qrDomains ]} required/> 
                      <Input class="col-sm-7" 
                        set={genCode()} 
                        bind:value={obj.shortId} 
                        label="codes--hash" 
                        uppercase pattern={patternShortId} 
                        minlength={minlengthShortId} 
                        maxlength={maxlengthShortId} 
                        customValidity={checkShortId}
                        customValidityInvalidMsg="codes--Hash already used"
                        required/>

                      <code class="mb-2 code col-sm-12 small"><I18n>codes--Qr Code URL</I18n> : https://{obj.domain}/{obj.shortId}</code>             
                    {/if}

                    {#if !obj.dynamic}
                      <Input class="col-12" bind:value={obj.content} type="textarea" placeholder="codes--link" label="content" required/>
                    {/if}
                  </div>
                </div>

                <div class="fsContentHorizontal">
                  <Button hideTitleXs validity={Boolean(obj.id)} title="Infos" previous={()=>stepEdit="infos"}/>
                  <Button hideTitleXs validity cond={Boolean(obj.id || !obj.dynamic)} {update}/>
                  {#if obj.dynamic}
                    <Button hideTitleXs validity title="Usage" next={()=>stepEdit="usage"}/>
                  {/if}
                </div>
              </Form>
            </div>
          {/if}
          <!-- END DESIGN -->


          <!-- USAGE -->
          {#if stepEdit=="usage" && obj.dynamic}
            <div class="fsContent">
              <Form fsContent norow bind:formValid={stepEditFormValidity}>
                {#if obj.id}
                  <div class="small text-success text-center"><I18n>code--content change msg</I18n></div>
                {/if}

                {#if obj.type=="custom" && editItemIndex!==null}
                  <div class="fsScroll">
                    <div class="row">
                      <CustomItems bind:items={obj.u.custom.items} bind:editItemIndex/>
                    </div>
                  </div>
                {/if}

                <div class="fsScroll" class:d-none={editItemIndex!==null || showUnity}>
                  <div class="row">
                    <Input class="type" set="link" inline type="radio" labelOnly options={$conf.unitys.filter(obj => {return obj.use == true;})} bind:value={obj.type}/>
                      
                    <div>
                      <div class="fs-5"><i class="fa-light fa-{unityType.icon}"/> {fuc(i18n(unityType.name))}</div>
                      <div class="small">{fuc(i18n(unityType.desc))}</div>
                    </div>

                    {#if standartUnitysNavbar.includes(obj.type) && !notUseNavbar}
                      <Input class="col-12" type="hr" icon="fa-bars" label="unity--Navbar"/>
            
                      <Input set="none" inline type="radio" options={navbarTypes} bind:value={obj.u.navbar.type}/>
            
                      {#if obj.u.navbar.type=="banner"}
                        <Input class="col-12" set="/assets/placeholder-banner.png" bind:value={obj.u.navbar.bannerCdn} icon="image" compress compressWidth=400 compressType="png" type="file" label="unity--banner" accept="image/svg,image/png,image/jpeg,image/gif,image/svg"/>
                      {/if}
            
                      {#if obj.u.navbar.type=="logo" || obj.u.navbar.type=="logotitle"}
                        <Input class="col-12" set="/assets/placeholder-logo.png" bind:value={obj.u.navbar.logoCdn} icon="image" compress compressWidth=400 compressType="png" type="file" label="unity--logo" accept="image/svg,image/png,image/jpeg,image/gif,image/svg"/>
                      {/if}
                      {#if obj.u.navbar.type=="title" || obj.u.navbar.type=="logotitle"}
                        <Input class="col-12" set="Title" bind:value={obj.u.navbar.title[obj.type]} icon type="text" label="unity--navbar title" multilang translator required/>
                      {/if}
            
                      {#if obj.u.navbar.type=="logo" || obj.u.navbar.type=="title" || obj.u.navbar.type=="logotitle"}
                        <Input set={2.5} class="col-lg-6" bind:value={obj.u.navbar.height} type="range" showValue unit="em" label="unity--Navbar height" min=1.5 max=6 step=0.1/>
                        <Input lg class="col-lg-6" set={false} bind:value={obj.u.navbar.center} label=" " type="switch" option="custom--centered"/>
                        <Input class="col-lg-6" bind:value={obj.u.navbar.colorBg} set="#FFFFFF" icon type="color" colorText label="unity--color bg" />
                        {#if obj.u.navbar.type=="title" || obj.u.navbar.type=="logotitle"}
                          <Input class="col-lg-6" bind:value={obj.u.navbar.colorText} set="#5C5C5C" icon type="color" colorText label="unity--color text"/>
                        {/if}
                      {/if}
                    {/if}

                    <Input class="col-12" type="hr" icon="fa-magnifying-glass" label="unity--Details"/>
                    {#if obj.type=="custom"}    
                      <Input set="top" label="unity--placement" class="col-lg-8" inline type="radio" options={placementKind} bind:value={obj.u.custom.placement}/>
                    {/if}
                    

                    {#if $w.size<3 && !showUnity}
                      <button on:click={()=>showUnity=true} class="btn btn-primary"><I18n>Show visual</I18n></button>
                    {/if}
            

                    {#if obj.type=="link"}
                      <Input bind:value={obj.u.link.url} icon type="url" label="codes--Url" required help="codes--Url warning message"/>
                      <Input class="col-12" set={false} bind:value={obj.u.link.isUtm} type="switch" option="unity--isutm" help="unity--help isutm"/>
                      {#if obj.u.link.isUtm}
                        <Input class="col-lg-4" bind:value={obj.u.link.utm_source} icon="fa-tag" placeholder="qrcode_home" type="text" label="unity--Utm_source" help="unity--Help utm_source"/>
                        <Input class="col-lg-4" bind:value={obj.u.link.utm_medium} icon="fa-tag" placeholder="qrcode_home" type="text" label="unity--Utm_medium" help="unity--Help utm_medium"/>
                        <Input class="col-lg-4" bind:value={obj.u.link.utm_campaign} icon="fa-tag" placeholder="qrcode_home" type="text" label="unity--Utm_campaign" help="unity--Help utm_campaign"/>
                        <Input class="col-lg-4" bind:value={obj.u.link.utm_term} icon="fa-tag" placeholder="qrcode_home" type="text" label="unity--Utm_term" help="unity--Help utm_term"/>
                        <Input class="col-lg-4" bind:value={obj.u.link.utm_content} icon="fa-tag" placeholder="qrcode_home" type="text" label="unity--Utm_content" help="unity--Help utm_content"/>
                      {/if}
                    {/if}
            
                    {#if obj.type=="email"}
                      <Input bind:value={obj.u.email.to} icon type="email" label="codes--email" required/>
                      <Input bind:value={obj.u.email.subject} icon type="text" label="codes--subject"/>
                    {/if}
            
                    {#if obj.type=="phone"}
                      <Input bind:value={obj.u.phone.tel} icon type="tel" label="codes--phone" required/>
                    {/if}
                    {#if obj.type=="card"}
                      <Input set="" class="col-lg-12" bind:value={obj.u.card.intro} icon type="textarea" label="unity--intro" help="unity--intro help" multilang translator/>
                      <Input class="col-12" set={true} bind:value={obj.u.card.introCenter} type="switch" option="unity--centered intro"/>
            
                      <div class="col-lg-9">
                        <Input set="Doe" required bind:value={obj.u.card.lastname} icon type="text" label="lastname"/>
                        <Input set="John" bind:value={obj.u.card.firstname} icon type="text" label="firstname"/>
                      </div>
                      <Input set="assets/placeholder-portrait.png" class="col-lg-3" bind:value={obj.u.card.photoCdn} icon="camera" compress compressWidth=200 type="file" label="photo" accept="image/png,image/jpeg,image/gif"/>
                      
                      <Input set="CEO" class="col-lg-6" bind:value={obj.u.card.title} icon type="textarea" rows=2 label="title"/>
                      <Input set="ACME Corp" class="col-lg-6" bind:value={obj.u.card.organization} icon type="textarea" rows=2 label="organization"/>
                      <Input set="http://acme.corp" class="col-lg-6" bind:value={obj.u.card.website} icon type="url" label="website"/>
                      <Input class="col-lg-6" bind:value={obj.u.card.email} icon type="email" label="email"/>
                      <Input class="col-lg-6" bind:value={obj.u.card.phoneWork} icon type="tel" label="Phone (work)"/>
                      <Input class="col-lg-6" bind:value={obj.u.card.phoneHome} icon type="tel" label="Phone (home)"/>
                      <Input class="col-lg-6" bind:value={obj.u.card.cell} icon type="tel" label="Phone cell"/>
                      <Input class="col-lg-6" bind:value={obj.u.card.faxWork} icon type="tel" label="Fax (work)"/>
                      <Input class="col-lg-6" bind:value={obj.u.card.faxHome} icon type="tel" label="Fax (home)"/>
                      <Input class="col-lg-6" bind:value={obj.u.card.street} icon type="text" label="street"/>
                      <Input class="col-lg-6" bind:value={obj.u.card.city} icon type="text" label="city"/>
                      <Input class="col-lg-6" bind:value={obj.u.card.state} icon type="text" label="state"/>
                      <Input class="col-lg-6" bind:value={obj.u.card.zipcode} icon type="text" label="zipcode"/>
                      <Input class="col-lg-6" bind:value={obj.u.card.country} icon type="text" label="country"/>

                      <Input class="col-lg-12" bind:value={obj.u.card.installName} icon="fa-mobile-screen-button" type="text" label="qrscreen installName" help="qrscreen installName help"/>
                    {/if}

                    {#if obj.type=="event"}
                      <Input set="" class="col-lg-12" bind:value={obj.u.event.intro} icon type="textarea" label="unity--intro" help="unity--intro help" multilang translator/>
                      <Input class="col-12" set={true} bind:value={obj.u.event.introCenter} type="switch" option="unity--centered intro"/>
                                   
                      <Input set="My event" required class="col-lg-12" bind:value={obj.u.event.summary} icon type="text" label="title" multilang translator/>
                      <Input set="Description" required class="col-lg-12" bind:value={obj.u.event.description} icon type="textarea" rows=2 label="title" multilang translator/>
                      <Input set="http://my.event" class="col-lg-6" bind:value={obj.u.event.url} icon type="url" label="website"/>
                      
                      <Input class="col-lg-6" bind:value={obj.u.event.organizerName} icon type="text" label="Name"/>
                      <Input class="col-lg-6" bind:value={obj.u.event.organizerEmail} icon type="email" label="Email"/>
                      <Input class="col-lg-6" bind:value={obj.u.event.organizerPhone} icon type="tel" label="Phone"/>

                      <Input class="col-lg-6" bind:value={obj.u.event.location} icon type="text" label="location"/>

                      <Input class="col-12" set={true} bind:value={obj.u.event.isDay} type="switch" option="unity--is full day" help="unity--help is full day"/>
                      {#if !obj.u.event.isDay}
                        <Input class="col-lg-6" bind:value={obj.u.event.start} icon type="text" label="start"/>
                        <Input class="col-lg-6" bind:value={obj.u.event.end} icon type="text" label="end"/>
                      {/if}
                    {/if}
            
                    {#if obj.type=="app"}
                      <Input class="col-12" set={true} bind:value={obj.u.app.isDirect} type="switch" option="unity--isdirect app screen" help="unity--help isdirect app screen"/>
                      {#if !obj.u.app.isDirect}
                        <Input set="" class="col-lg-12" bind:value={obj.u.app.intro} icon type="textarea" label="unity--intro" help="unity--intro help" multilang translator/>
                        <Input class="col-12" set={true} bind:value={obj.u.app.introCenter} type="switch" option="unity--centered intro"/>
                      {/if}
                      <Input set="" class="col-lg-12" bind:value={obj.u.app.name} icon type="text" label="unity--app name" required/>
                      <Input set="com.domain.xxxx" class="col-lg-6" bind:value={obj.u.app.androidId} icon type="text" label="unity--app android play store package Id"/>
                      <Input set="id000000000" class="col-lg-6" bind:value={obj.u.app.iosId} icon type="text" label="unity--app apple App store package Id"/>
                    {/if}


                    {#if obj.type=="social"}
                      <Input set="Hello world !" class="col-lg-12" bind:value={obj.u.social.intro} icon type="textarea" label="unity--intro" help="unity--intro help" multilang translator/>    
                      <Input class="col-12" set={true} bind:value={obj.u.social.introCenter} type="switch" option="unity--centered intro"/>
                      
                      <Input set="@me" class="col-lg-6" bind:value={obj.u.social.twitter} icon="twitter" type="text" label="twitter username"/>
                      <Input class="col-lg-6" bind:value={obj.u.social.twitterName} icon="" type="text" label="twitter title"/>

                      <Input set="@me" class="col-lg-6" bind:value={obj.u.social.instagram} icon="instagram" type="text" label="instagram username"/>
                      <Input class="col-lg-6" bind:value={obj.u.social.instagramName} icon="" type="text" label="instagram title"/>

                      <Input class="col-lg-6" bind:value={obj.u.social.linkedin} icon="linkedin" type="url" label="linkedin link"/>
                      <Input class="col-lg-6" bind:value={obj.u.social.linkedinName} icon="" type="text" label="linkedin title"/>

                      <Input class="col-lg-6" bind:value={obj.u.social.snapchat} icon="snapchat" type="text" label="snapchat username"/>
                      <Input class="col-lg-6" bind:value={obj.u.social.snapchatName} icon="" type="text" label="snapchat title"/>

                      <Input class="col-lg-6" bind:value={obj.u.social.tiktok} icon="snapchat" type="text" label="tiktok username"/>
                      <Input class="col-lg-6" bind:value={obj.u.social.tiktokName} icon="" type="text" label="tiktok title"/>

                      <Input class="col-lg-6" bind:value={obj.u.social.pinterest} icon="pinterest" type="text" label="pinterest account"/>
                      <Input class="col-lg-6" bind:value={obj.u.social.pinterestName} icon="" type="text" label="linkedin title"/>

                      <Input class="col-lg-6" bind:value={obj.u.social.youtube} icon="youtube" type="url" label="youtube link"/>
                      <Input class="col-lg-6" bind:value={obj.u.social.youtubeName} icon="" type="text" label="youtube title"/>

                      <Input class="col-lg-6" bind:value={obj.u.social.whatsapp} icon="whatsapp" type="tel" label="whatsapp phone number" help="international format +33 (0) 1 02 03 04 05"/>
                      <Input class="col-lg-6" bind:value={obj.u.social.whatsappName} icon="" type="text" label="whatsapp title"/>

                      <Input class="col-lg-6" bind:value={obj.u.social.flickr} icon="flickr" type="text" label="flickr alias"/>
                      <Input class="col-lg-6" bind:value={obj.u.social.flickrName} icon="" type="text" label="flickr title"/>

                      <Input class="col-lg-6" bind:value={obj.u.social.facebook} icon="facebook" type="url" label="facebook page url"/>
                      <Input class="col-lg-6" bind:value={obj.u.social.facebookName} icon="" type="text" label="facebook title"/>

                      <Input class="col-lg-6" bind:value={obj.u.social.url} icon="url" type="url" label="url"/>
                      <Input class="col-lg-6" bind:value={obj.u.social.urlName} icon="" type="text" label="url title"/>

                      <Input class="col-lg-6" bind:value={obj.u.social.email} icon="email" type="email" label="email"/>
                      <Input class="col-lg-6" bind:value={obj.u.social.emailName} icon="" type="text" label="email name"/>

                      <Input class="col-lg-6" bind:value={obj.u.social.phone} icon="phone" type="tel" label="phone number" help="international format +33 (0) 1 02 03 04 05"/>
                      <Input class="col-lg-6" bind:value={obj.u.social.phoneName} icon="" type="text" label="phone label"/>
                    {/if}



                    <!-- button hooks -->
                    {#if (obj.u[obj.type].items && obj.u[obj.type].items.filter(elt => {return elt.type=="button"}).length)}
                      <Input class="col-12" type="hr" icon="fa-paper-plane-top" label="codes--Action on submit" help="codes--help action on submit"/>
                      <div class="col-12">
                        <Input lg set={true} bind:value={obj.u.isRecord} type="switch" option="codes--Record on submit" help="codes--help record on submit"/>
                        <Input lg set={false} bind:value={obj.u.hooks.mailhook} type="switch" option="codes--trigger a mail on submit"/>
                        {#if obj.u.hooks.mailhook}
                          <Inputs bind:value={obj.u.hooks.mailhookTos} icon type="email" label="codes--mail to" required maxInputs=3/>
                        {/if}
                          
                        <Input lg set={false} bind:value={obj.u.hooks.webhook} type="switch" option="codes--trigger a webhook on submit"/>
                        {#if obj.u.hooks.webhook}
                          <Inputs bind:value={obj.u.hooks.webhookUrls} icon type="url" label="codes--webhook url" required maxInputs=3/>
                        {/if}
                      </div>
                    {/if}

                
                    <!-- Colors && page bg-->
                    {#if standartUnitysColor.includes(obj.type) && !notUseColor}
                      <CustomPage bind:page={obj.u}/>
                    {/if}



                    <!-- codes Options -->
                    <Input class="col-12" type="hr" icon="fa-cogs" label="codes--Options"/>
                
                    <Input class="col-12" lg set={false} bind:value={obj.options.startDateValid} type="switch" option="codes--valid from"/>
                    {#if obj.options.startDateValid}
                      <Input class="col-lg-4" bind:value={obj.options.startDate} icon type="datetime" label="codes--valid start date" required/>
                    {/if}

                    <Input class="col-12" lg set={false} bind:value={obj.options.endDateValid} type="switch" option="codes--valid until"/>
                    {#if obj.options.endDateValid}
                      <Input class="col-lg-4" bind:value={obj.options.endDate} icon type="datetime" label="codes--valid end date" required/>
                    {/if}
                    
                    <Input class="col-12" lg set={false} bind:value={obj.options.protected} type="switch" option="codes--protected code"/>
                    {#if obj.options.protected}
                      <Input class="col-12" bind:value={obj.options.pwd} icon="password" type="text" label="codes--password" required/>
                    {/if}

                    <Input lg set={false} bind:value={obj.options.mailhook} type="switch" option="codes--trigger a mail on scan"/>
                    {#if obj.options.mailhook}
                      <Inputs bind:value={obj.options.mailhookTos} icon type="email" label="codes--mail to" required maxInputs=3/>
                    {/if}
                    
                    <Input lg set={false} bind:value={obj.options.webhook} type="switch" option="codes--trigger a webhook on scan"/>
                    {#if obj.options.webhook}
                      <Inputs bind:value={obj.options.webhookUrls} icon type="url" label="codes--webhook url" required maxInputs=3/>
                    {/if}


                  </div>
                </div>

                <div class="fsContentHorizontal" class:d-none={editItemIndex!==null || showUnity}>
                  <Button hideTitleXs validity={Boolean(obj.id)} title="Design" previous={()=>stepEdit="design"}/>
                  <Button hideTitleXs validity cond={Boolean(obj.id)} {update}/>
                  <Button hideTitleXs validity cond={!obj.id} save={update}/>
                </div>
              </Form>


              <!-- UNITY mini screen -->
              {#if $w.size<3 && showUnity}
                <div class="fsScroll mb-2">
                  <button on:click={()=>showUnity=false} class="btn btn-primary"><I18n>Close</I18n></button>
                  {#if obj.type=="custom"}
                    <CustomItems showBlocks bind:items={obj.u.custom.items} bind:editItemIndex/>        
                  {/if}
                  <div class="mobile mini" class:mt-4={obj.type!="custom"}>
                    <div class="mobileContent">
                      
                      {#if obj.type=="link"}
                        {#if errorFrame}
                          <div class="my-auto mx-2 text-center">
                            <div class="fs-4"><I18n>codes--Url on scan</I18n></div>
                            <div style="overflow-x:hidden;">{u(obj.u?.link.url)}</div>
                          </div>
                        {/if}
                        <iframe class:d-none={errorFrame} src={obj.u?.link.url} title=""></iframe>
                      {:else if obj.type=="email"}
                        <div class="my-auto mx-2 text-center">
                          <div class="h4"><I18n>codes--Email on scan</I18n></div>
                          <p>{u(obj.u.email.to)}</p>
                          {#if obj.u.email.subject}<p><I18n>unity--Email subject</I18n>: {obj.u.email.subject}</p>{/if}
                        </div>
                      {:else if obj.type=="phone"}
                        <div class="my-auto mx-2 text-center">
                          <div class="h4"><I18n>codes--Phone on scan</I18n></div>
                          <p>{u(obj.u.phone.tel)}</p>
                        </div>
                      {:else}
                        <Unity isDesign bind:code={obj} bind:editItemIndex/>
                      {/if}
                    </div>
                  </div>
                </div>
              {/if}
              <!-- UNITY END mini screen -->








            </div>
          {/if}
          <!-- END USAGE -->
        </div>
        <!-- COL LEFT or FULL -->


        <!-- QR COL RIGHT large screen -->
        {#if stepEdit=="design"}
          <div class="col-4 d-none h-100 d-lg-block">
            <div class="text-center" style="opacity:{stepEdit=="infos" && !obj.id?0.2:1}">
              <Input label="code--Size design preview" class="col" type="range" nomargin set=100 min=20 max=300 bind:value={previewSize}/>
              <img class="my-2" src="{qrCodeSrc}" alt="" title="" style="width:{previewSize}%;">
            </div>
            {#if stepEdit=="design"}
              <Alert local={{noDismiss:true, warning:"codes--test qr code before"}}/>
            {/if}
          </div>
        {/if}
        <!-- QR END COL RIGHT large screen -->


        <!-- UNITY COL RIGHT large screen -->
        {#if stepEdit=="usage" && $w.size>=3}
          <div class="col-lg-4 col-12">
            {#if obj.type=="custom"}
              <CustomItems showBlocks bind:items={obj.u.custom.items} bind:editItemIndex/>        
            {/if}
            <div class="mobile" class:mt-4={obj.type!="custom"}>
              <div class="mobileContent">
                
                {#if obj.type=="link"}
                  {#if errorFrame}
                    <div class="my-auto mx-2 text-center">
                      <div class="fs-4"><I18n>codes--Url on scan</I18n></div>
                      <div style="overflow-x:hidden;">{u(obj.u?.link.url)}</div>
                    </div>
                  {/if}
                  <iframe class:d-none={errorFrame} src={obj.u?.link.url} title=""></iframe>
                {:else if obj.type=="email"}
                  <div class="my-auto mx-2 text-center">
                    <div class="h4"><I18n>codes--Email on scan</I18n></div>
                    <p>{u(obj.u.email.to)}</p>
                    {#if obj.u.email.subject}<p><I18n>unity--Email subject</I18n>: {obj.u.email.subject}</p>{/if}
                  </div>
                {:else if obj.type=="phone"}
                  <div class="my-auto mx-2 text-center">
                    <div class="h4"><I18n>codes--Phone on scan</I18n></div>
                    <p>{u(obj.u.phone.tel)}</p>
                  </div>
                {:else}
                  <Unity isDesign bind:code={obj} bind:editItemIndex/>
                {/if}
            
              
              </div>
            </div>
          </div>
        {/if}
        <!-- UNITY END COL RIGHT large screen -->


      </div>
  

    </div>
  {/if}
</Route>




<Route path="/codes/transfer/:id" let:routeParams fsContent>
  <div class="container fsContent">
    {#await get(routeParams.id) then}
      <Title title="Transfer" detail={obj.name} back="/codes"/>
      <Form>
        <Input class="col-12" type="select" options={$customers} required bind:value={transferToCustomerId}/>
        <Button validity class="col-auto" title="Transfer" ok={()=>transfer(obj.id,transferToCustomerId)}/>
        <Button class="col-auto" cancel={()=>setRoute(`/codes`)}/>
      </Form>
    {:catch}
      <Wrong/>
    {/await}
  </div>
</Route>

<Route path="/codes/preview/:id" let:routeParams fsContent>
  <Preview id={routeParams.id}/>
</Route>

<Route path="/codes/analytics/:id" let:routeParams fsContent>
  <Analytics codeId={routeParams.id}/>
</Route>

<Route path="/codes/records/:id" let:routeParams fsContent>
  <div class="container fsContent">
    {#await get(routeParams.id) then}
      <Title title="Records" detail={obj.name} back="/codes"/>
      <Records codeId={routeParams.id}/>
    {:catch}
      <Wrong/>
    {/await}
  </div>
</Route>


<style>
  .choices{
    line-height:2rem;
  }
  .badge-tag {
    cursor:default;
  }
  .preview{
    cursor:	zoom-in;
  }

  .mobile:after, .mobile:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #7b7a78;
  }

  .mobile:before {
    width: 60px;
    height: 5px;
    top: -30px;
    border-radius: 10px;
  }
  .mobile {
    position: relative;
    width: 80%; /*48vh; 380px;*/
    min-width: 330px;
    height: 75vh; /*650px;*/
    margin: auto;
    border: 16px solid #000;
    border-top-width: 50px;
    border-bottom-width: 60px;
    border-radius: 40px;
  }
  .mobile.mini{
    width: 100%;
  }

  .mobile .mobileContent {
    width: 348px;
    height: 540;
    background: #fff;
  }

  .mobile .mobileContent {
    overflow: auto;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  .mobile .mobileContent iframe{
    flex-grow: 1;
  }

  .mobile:after {
    width: 35px;
    height: 35px;
    bottom: -65px;
    border-radius: 50%;
  }

  .mobile:after, .mobile:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #333;
  }

  :global(.type input+label){
    border:1px solid var(--brandColor);
    padding: 5px;
    margin-right: 4px;
  }
  :global(.type input:checked+label, .type input:hover+label){
    background-color: var(--brandColor);
    color:white!important;
    font-weight:normal!important;
  }

</style>