<script>
  import { conf, lang, spinner } from './stores.js'
  import { fuc, i18n, cdn, isArray, asArray, inArray, dateTimeFormat } from './h.js'  
  import I18n from './G_I18n.svelte'
  import I from './G_I.svelte'

  export let type='table'
  export let title=''
  export let refresh=false
  export let objs=[]
  export let ref=false
  export let cardFooterDate=false
  export let cardPolaroid=false
  export let cardShadow=false
  export let cardUp=false
  export let counter=false //add a counter i in table
  export let noHover=false //don't make hover style in table

  export let action=false

  export let fields=[]
  export let onSelect=false

  function show(obj, field){
    let value=obj[field.id]
     if(field.format && typeof field.format === 'function') return field.format(value,obj, field)
    if(field.format=='i18n') return fuc(i18n(`${field.i18nPrefix}${value}`))
    if(inArray(field.format,['epoch','long','medium','short','dmy','short','shortDt','dt','shortTime','shortTimeSecond','time','complete'])) return dateTimeFormat(value,field.format)
    if(field.format=='badge') return value?`<i class="fa-solid fa-badge-check fa-fw"></i>`:""

    if(value===true) return `<i class="${$conf.faType} fa-check fa-fw"></i>`
    if(value===false || value===undefined) return ""
    if(field.format=='tags' && value) {
      let ret=""
      value.forEach(element => ret+=`<span class="badge-tag badge bg-secondary me-1">${element}</span>`)
      return ret
    }
    if(typeof value == 'number') return value
    else return fuc(i18n(value))
  }

  let spin=false
  function spinIt(){
    spin=true
    setTimeout(() => {
      spin=false
    }, 400)
  }

</script>


{#if title}
  <div class="fs-5 my-2">
    {asArray(objs).length} {i18n(title, $lang)}
    {#if refresh}<span on:click={()=>{spinIt();refresh();}} title={i18n("g--reload")} class="pointer hover fs-5 my-2"><I {spin} arrow-rotate-right/></span>{/if}
  </div>
{/if}

{#if asArray(objs).length}
  <div class="fsScroll fsScrollX">

    {#if type=="card"}    
      <div class="cards mt-2" >
        {#each asArray(objs) as obj,i (obj)}
          <div on:click={()=>{if(onSelect) onSelect(obj.id)}} 
            class="card" 
            class:pointer={onSelect} 
            class:polaroid={cardPolaroid}
            class:shadow={cardShadow}
            class:up={cardUp}  
            ref={obj[ref]}        
            >
            <div class="card-header fsContentHorizontal">
              <slot name="header" {obj} {i}/>
            </div>
            <div class="card-body p-0 pb-1">
              <div class="container-lg">
                <slot name="body" {obj} {i}/>
              </div>
            </div>
            <div class="card-footer xsmall d-flex justify-content-between">
              <slot name="footer" {obj} {i}/>
              {#if cardFooterDate}<div>{dateTimeFormat(obj[cardFooterDate],'shortTime')}</div>{/if}
            </div>
          </div>
        {/each}
      </div>
    {/if}

    {#if type=="table"}
      <table class="table">
        <thead>
          <tr>
            {#if counter}<th scope="col">#</th>{/if}
            {#each fields as field, f}
              <th scope="col"><span class="ucfirst"><I18n id={field.title}/></span></th>
            {/each}
            {#if action}<th><I cog/></th>{/if}
          </tr>
        </thead>
        <tbody>
          {#each asArray(objs) as obj, i (obj)}
            <tr class:showHover={!noHover} class:noPointer={!onSelect} on:click={()=>{if(onSelect) onSelect(obj.id)}} ref={obj[ref]}>
              {#if counter}<th scope="col">{i+1}</th>{/if}
              {#each fields as field, f}
                <th>{@html show(obj,field)}</th>
              {/each}
              {#if action}<th on:click={()=>action(obj.id)}><slot name="action" {obj} {i}/></th>{/if}
            </tr>
          {/each}
        </tbody>
      </table>
    {/if}
  </div>
{/if}

<style>
  tbody tr th{
    white-space: nowrap;
  }
  tbody tr.showHover:hover{
    background-color:var(--navbarBgColor);
    cursor: pointer;
  }
  tbody tr.showHover.noPointer:hover{
    cursor: default;
  }
  table thead{
    position: sticky;
    top: 0;
    background-color:white;
    border-bottom: 2px solid currentColor;
  }
  table>:not(:first-child) {/* black line under header (deleted in bootstrap 5.2 !)*/
    border-top: 2px solid currentColor;
  }


  .fsScroll {
    padding-top: 0;
  }
</style>

