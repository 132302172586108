<script>
  import { customerId, token, conf, modal } from './stores.js'
  import { api, dateTimeFormat, i18n, u } from './h.js'

  import I18n from './G_I18n.svelte'  	
  import Switch from './G_Switch.svelte'  

  function exportAnalitycs(){}
  function mailAnalitycs(){}

  export let codeId=false
  
  let rows=[]
  let headers=[]
  let reload=0

  $: getRecords(codeId, reload)
  async function getRecords(){
    rows = await api(`/app/customers/codes/records/list/${codeId}`)
    headers=rows.shift()
  }

  async function delRecords(testOnly){
    $modal={cancel:true, title:`${i18n("codes--Delete records")}`, color:'danger', confirm:async ()=>{
      await api(`/app/customers/codes/records/delete/${codeId}/${testOnly?"test":""}`)
      reload++
    }}
  }
  
  function downloadRecords(){
    window.open(`${$conf.apiUrl}/exports/codes/records/list/${codeId}/xlsx?token=${$token}`)
  }

  let toggleFullText=false
</script>

<div class="fsScroll fsScrollX">
  <button on:click={downloadRecords} class="btn btn-outline-success mb-1">Export Excel</button>
  <button on:click={()=>delRecords()} class="btn btn-outline-danger mb-1"><I18n>codes--Delete records</I18n></button>
  <button on:click={()=>delRecords(true)} class="btn btn-outline-danger mb-1"><I18n>codes--Delete records test</I18n></button>
  <div class="d-inline-block ms-4"><Switch bind:checked={toggleFullText} label="codes--Show full text"/></div>

  <table class="table table-striped">

    <thead>
      <tr>
        <th scope="col" style="width: 50px;">#</th>
        {#each headers as header}
          <th scope="col" style="width: 200px;">{i18n(header)}</th>
        {/each}
      </tr>
    </thead>
    <tbody>
      {#each rows as row,i}
        <tr>
          <th scope="row">{i+1}</th>
          {#each row as col, i}
            <td style="max-width: {toggleFullText?"":"200px"}; text-overflow: {toggleFullText?"clip":"ellipsis"};overflow: hidden; white-space:{toggleFullText?"":"nowrap"};">
              {!i?dateTimeFormat(col,'shortTime'):u(col)}

            </td>
          {/each}
        </tr>
      {/each}
      
    </tbody>
  </table>
</div>