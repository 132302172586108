<script>
  import { cdn } from './h.js'
  export let item={}
  export let ended=false
  export let src=false


  //  https://d.tube/#!/v/sagar.kothari.88/3lzgt8705er
 // <iframe width="560" height="315" src="https://emb.d.tube/#!//('files':('youtube':'I90iPFsljuQ'),'dur':'108','thumbnailUrlExternal':'','thumbnailUrl':'https:!!i.ytimg.com!vi!I90iPFsljuQ!mqdefault.jpg','nsfw':0,'oc':1)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

  let provider, kind, source
  $:{
    if(item.kind=="file" || src) {
      kind="file"
      source=cdn(src || item.videoCdn)
    }

    if(item.kind=="link"){
      kind="link"
      source=item.link
      if(source){
        if(!item.provider) {
          if(source.includes("youtu")) provider="youtube"
          if(source.includes("d.tube")) provider="d.tube"
          if(source.includes("vimeo")) provider="vimeo"
          if(source.includes("dailymotion")) provider="dailymotion"
        } else provider=item.provider
        if( provider=="youtube"){
          if(!source.includes("watch")) source="https://www.youtube.com/embed/" +  source.split("/").pop() + "?controls=0"
          if(source.includes("watch"))  source="https://www.youtube.com/embed/" +  source.split("v=").pop() + "?controls=0"
        } 
      
        if( provider=="dailymotion" && !source.includes("autoplay=")) {
          source= source + "?autoplay=1"
        }
      }
    }
  }
</script>

{#if provider=="youtube"}
  <iframe width="100%" height="315" src={source} 
    title="YouTube video player" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen>
  </iframe>

{:else if provider=="vimeo"}
  <iframe width="100%" height="315" src={source}
    title="vimeo-player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen>
  </iframe>

{:else if provider=="dailymotion"}
  <iframe width="100%" height="315" src={source} 
    title="dailymotion video player" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen>
  </iframe>

{:else if provider=="d.tube"}
  <iframe width="100%" height="315" src={source} 
    title="d.tube video player" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen>
  </iframe>

{:else if (kind=="file" || kind=="link")}
  <video poster={cdn("/assets/placeholder-video.png")} class="w-100" playsinline on:ended={ended} src={source} controls={!item.noControls} controlsList="nodownload">
    <track kind="captions">
  </video>
{/if}