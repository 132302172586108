<script>
  import { conf, lang, modal } from "./stores.js"
  import { i18n} from "./h.js"
  import { fade, fly } from "svelte/transition"
  import { quintOut } from "svelte/easing"
  import I18n from "./G_I18n.svelte"

  export let local=false

  let modalObj={}

  $: if(local) modalObj=local; else modalObj=$modal

  let icons={primary:"info-circle", success:"check-circle", warning:"exclamation-circle", danger:"exclamation-triangle"}
  
  let dangerZoneValue
  /*
    title:'Modal',
    msg:false,
    faType:'regular',
    icon:icons.primary,
    color:'primary',
    x:false,
    close:false,
    cancel:true,
    save:false,
    ok:false,
    confirm:false,
    dangerZone:false,
    class:"",
    noBackdrop:false,
    handleBackdrop:false,
    describedby:"",
    labelledby:""
  */


  async function closeModal(fct){
    if(typeof fct==='function') await fct()
    modalObj={}
    dangerZoneValue=''
  }

  function handleBackdrop(event) {
    if (modalObj.handleBackdrop) {
      event.stopPropagation()
      closeModal()
    }
  }

</script>

<style>
  .modal {
    display: block;
  }
</style>

{#if modalObj && modalObj.title}
  <div
    class:modal-open={modalObj.title}
    class="modal show"
    tabindex="-1"
    role="dialog"
    aria-labelledby={modalObj.labelledby}
    aria-describedby={modalObj.describedby}
    aria-modal="true"
    on:click|self={handleBackdrop}
    transition:fade>
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable {modalObj.class}"
      role="document"
      in:fly={{ y: -50, duration: 300 }}
      out:fly={{ y: -50, duration: 300, easing: quintOut }}>
      <div class="modal-content">
        {#if modalObj.title || modalObj.x}
          <div class="modal-header text-bg-{modalObj.color}">
            <h4 class="modal-title d-flex justify-content-start align-items-center">
              <i class="{$conf.faType} fa-{icons[modalObj.color] || icons.primary} me-2 fa-fw"></i>
              <div class="ucfirst d-inline-block">{@html modalObj.title}</div>
            </h4>
            {#if modalObj.x}
                <span on:click={closeModal(modalObj.cancel)} aria-hidden="true">&times;</span>
            {/if}
          </div>
        {/if}
        {#if modalObj.msg}
          <div class="modal-body">
            {@html modalObj.msg}
          </div>
        {/if}
        {#if modalObj.ok || modalObj.close || modalObj.cancel || modalObj.save || modalObj.confirm}
          <div class="modal-footer">
            {#if modalObj.confirm && modalObj.dangerZone}
              <div class="input-group col">
                <input bind:value={dangerZoneValue} type="text" class="text-danger form-control" placeholder={i18n("Type this word", $lang) +' : ' + modalObj.dangerZone} aria-label="dangerZone" aria-describedby="dangerZone">
                <button class="btn btn-danger" on:click={() => {if(modalObj.dangerZone==dangerZoneValue){closeModal(modalObj.confirm)}}}><I18n>Confirm</I18n></button>
              </div>
            {/if}
            {#if modalObj.cancel}
              <button type="button" class="btn btn-secondary" on:click={() => {closeModal(modalObj.cancel)}}><I18n>Cancel</I18n></button>
            {/if}
            {#if modalObj.close}
              <button type="button" class="btn btn-secondary" on:click={() => {closeModal(modalObj.close)}}><I18n>Close</I18n></button>
            {/if}
            {#if modalObj.save}
              <button type="button" class="btn btn-primary" on:click={() => {closeModal(modalObj.save)}}><I18n>Save</I18n></button>
            {/if}
            {#if modalObj.ok}
              <button type="button" class="btn btn-primary" on:click={() => {closeModal(modalObj.ok)}}><I18n>Ok</I18n></button>
            {/if}
            {#if modalObj.action}
              <button type="button" class="btn btn-primary" on:click={() => {closeModal(modalObj.action)}}>{modalObj.actionLabel}</button>
            {/if}
            {#if modalObj.confirm && !modalObj.dangerZone}
              <button type="button" class="btn btn-primary" on:click={() => {closeModal(modalObj.confirm)}}><I18n>Confirm</I18n></button>
            {/if}
          </div>
        {/if}
      </div>
    </div>
  </div>
  {#if !modalObj.noBackdrop}
    <div class="modal-backdrop show" transition:fade={{ duration: 150 }} />
  {/if}
{/if}