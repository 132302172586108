<script>
  import { customer, customerId, token } from './stores.js'
  import { api, i18n, dateCalculate } from './h.js'

  import I18n from './G_I18n.svelte'  	
  import Chart from './G_Chart.svelte'
  import FilterLine from './G_FilterLine.svelte'
  import DatesPicker from './G_DatesPicker.svelte'
  import MultiSelect from './G_MultiSelect.svelte'
  import Switch from './G_Switch.svelte'
  import Title from './G_Title.svelte'
  
  export let codeId=false
  let code=false, firstTracking=new Date("2020-01-01T00:00:00.000Z")
  getCode()

  async function getCode(){
    code=await api(`/app/customers/codes/get/${codeId}`)
    let first=await api(`/app/customers/trackings/first/${codeId}`)
    if(first) firstTracking=first.date
  }


  let today=new Date()
  let startDate=dateCalculate(today, -90, 'day', 'datePart')
  let todayDate=dateCalculate(today, 0, 'day', 'datePart')
  let endDate=todayDate

  $: preDates=[
    {name:'Last 90 days', startDate: dateCalculate(today, -90, 'day', 'datePart'), endDate: todayDate},
    {name:'Last 30 days', startDate: dateCalculate(today, -30, 'day', 'datePart'), endDate: todayDate},
    {name:'Last 7 days', startDate: dateCalculate(today, -7, 'day', 'datePart'), endDate: todayDate},
    {name:'This week', startDate: dateCalculate(today, 0, 'firstDayOfWeek', 'datePart'), endDate: todayDate},
    {name:'Yesterday', startDate: dateCalculate(today, -1, 'day', 'datePart'), endDate: dateCalculate(today, -1, 'day', 'datePart')},
    {name:'Today', startDate: todayDate, endDate: todayDate},
    {name:'Last year', startDate: dateCalculate(today, -1, 'firstDayOfYear', 'datePart'), endDate: dateCalculate(today, -1, 'lastDayOfYear', 'datePart')},
    {name:'This year', startDate: dateCalculate(today, 0, 'firstDayOfYear', 'datePart'), endDate: todayDate},
    {name:'Last month', startDate: dateCalculate(today, -1, 'firstDayOfMonth', 'datePart'), endDate: dateCalculate(today, -1, 'lastDayOfMonth', 'datePart')},
    {name:'This month', startDate: dateCalculate(today,0, 'firstDayOfMonth', 'datePart'), endDate: todayDate},
    {name:'Last week', startDate: dateCalculate(today, -1, 'firstDayOfWeek', 'datePart'), endDate: dateCalculate(today, -1, 'lastDayOfWeek', 'datePart')},
    {name:'All', startDate: dateCalculate(firstTracking, 0, 'day', 'datePart') ,endDate: todayDate}
  ]
  
  let totalScan=0
  let chartDateData=[]
  let chartQRTagData={}
  let chartDevicesData={}
  let chartLocationData={}
  let chartOSData={}
  let chartBrowserData={}

  let me
  
  $: analytics(startDate && endDate, $customerId, codes, unitHours)
  //$: getCodes(keyword, sortBy, filter, tagsSelected)


  let codes=[], keyword, sortBy={name:1}, filter={valid:true}
  /*async function getCodes(){
    if(!$token || !$customerId) return
    //tags = await getTags()
    let existentTags=tagsSelected.filter((t)=>{return tags.includes(t)})
    //console.log(existentTags)
    let req={ ...filter, ...(existentTags.length?{tags: { $in: existentTags}}:{})}
    codes = await api('/customer/search/codes', {
      request: {...req}, keyword: keyword, sortBy: sortBy
    })
  }*/

  //let codeIds

  let tags=[], tagsSelected=[]
  async function getTags(){
    return await api('/app/customers/codes/tags/list')
  }

  let showUnitChoice=false //not finalized, works, but to be affined
  let unitHours=false

  let wasStart, wasEnd, wasUnitHours
  let startMorningDate
  let endEveningDate
  async function analytics(){
    if(startDate==wasStart && endDate==wasEnd && unitHours==wasUnitHours) return
    wasStart=startDate
    wasEnd=endDate
    wasUnitHours=unitHours
    //console.log('trackings')
    //note that locale tz is passed automatically in api fct
    startMorningDate=new Date(startDate).setHours(0, 0, 0, 0)
    endEveningDate=new Date(endDate).setHours(23, 59, 59, 999)
    //console.log(endEveningDate-startMorningDate) 
    //let groupBy=(endEveningDate-startMorningDate > 70480000)?'ymd':'ymdhm' //8 jours
    //unit=(groupBy=='ymd')?'day':'hour'

    let groupBy=unitHours?'ymdh':'ymd'

  

    totalScan=await api('/app/customers/trackings/count',{
      request:{
        action:'scan',
        codeId:codeId
      },startDate: startMorningDate, endDate: endEveningDate
    })
    
    chartDateData=await api('/app/customers/trackings/timeSerie',{
      request:{
        action:'scan',
        //codes: codes,
        codeId:codeId
      },startDate: startMorningDate, endDate: endEveningDate, groupBy: groupBy
    })
    chartQRTagData=await api('/app/customers/trackings/serie',{
      request:{
        action:'scan',
        //codes: codes,
        codeId:codeId
      },startDate: startMorningDate, endDate: endEveningDate, groupBy:'codeId',
      labelsAndDatas:true,
      renameLabelsFrom:'codes'
    })
    chartDevicesData=await api('/app/customers/trackings/serie',{
      request:{
        action:'scan',
        //codes: codes,
        codeId:codeId
      },startDate: startMorningDate, endDate: endEveningDate, groupBy:'device',
      labelsAndDatas:true
    })
    chartLocationData=await api('/app/customers/trackings/serie',{
      request:{
        action:'scan',
        //codes: codes,
        codeId:codeId
      },startDate: startMorningDate, endDate: endEveningDate, groupBy:'countryCode',
      labelsAndDatas:true
    })
    chartOSData=await api('/app/customers/trackings/serie',{
      request:{
        action:'scan',
        //codes: codes,
        codeId:codeId
      },startDate: startMorningDate, endDate: endEveningDate, groupBy:'os',
      labelsAndDatas:true
    })
    chartBrowserData=await api('/app/customers/trackings/serie',{
      request:{
        action:'scan',
        //codes: codes,
        codeId:codeId
      },startDate: startMorningDate, endDate: endEveningDate, groupBy:'browser',
      labelsAndDatas:true
    })
  }

  /******* chartDate **********/
  $: chartDate = {
    chart: {
      height: "100%", 
      type: "area",
    },
    stroke: {
      width: [5,5,4],
      curve: 'smooth',
    },
    theme: {
      mode: 'light', 
      monochrome: {
          enabled: true,
          color: '#ffcc00',
          shade: 'light',
          shadeIntensity: 0.1
      },
    },
    series: [
      {
        name: i18n('analytics--Scans'),
        data: chartDateData
      },
      /*{
        name: "sales2",
        data: Array(10).fill().map(() =>  Math.round(100 * Math.random()))
      },*/
    ],
    xaxis: {
      type: 'datetime',
      min: startMorningDate,
      max: endEveningDate,
      labels: {
      datetimeFormatter: {
        year: 'yyyy',
        month: 'MMM \'yy',
        day: 'dd MMM',
        hour: '' // was 'HH:mm' but we don't want to show hour/minute
      }
    }
    }
  }


  /******* chartQRTag **********/
  /*$: chartQRTag={
    type: 'horizontalBar',
    data: {
      labels: chartQRTagData.labels,
      datasets: [
        {
          label: i18n('Scans by QR Code/Tag'),
          backgroundColor: 'rgba(255, 204, 0,7)',
          borderColor: 'rgba(255, 204, 0)',
          //pointBorderWidth:10,
          borderWidth: 1,
          //fill: true,
          data: chartQRTagData.datas
        }
      ]
    },
    options: {
      maintainAspectRatio: false, 
      responsive: true,
      aaspectRatio:1,
      legend:{
        display: true,
        position: 'top',
        labels:{
          usePointStyle: true,
          boxWidth: 5
        }
      },
      scales: {
        xAxes: [{
          display: true,
          ticks: { stepSize: 5, autoSkip:true, min:0 }
        }],
        yAxes: [{
          display: true,
          ticks: {stepSize: 10, autoSkip:false, min:0}
        }]
      }
    }
  }*/

  /******* chartDevices (true)**********/
  /*$: chartDevices={
    type: 'horizontalBar',
    data: {
      labels: chartDevicesData.labels,
      datasets: [
        {
          label: i18n('Scans by device(true)'),
          //backgroundColor: 'rgba(255, 204, 0, 0.5)',
          //borderColor: '#ffcc00',
          //pointBorderWidth:10,
          //borderWidth: 5,
          //fill: true,
          data: chartDevicesData.datas
        }
      ]
    },
    options: {
      maintainAspectRatio: false, 
      responsive: true,
      aaspectRatio:1,
      legend:{
        display: true,
        position: 'top',
        labels:{
          usePointStyle: true,
          boxWidth: 5
        }
      },
      scales: {
        xAxes: [{
          display: true,
          ticks: { stepSize: 10, autoSkip:true, min:0 }
        }],
        yAxes: [{
          display: true,
          ticks: {stepSize: 10,autoSkip:false, min:0}
        }]
      }
    }
  }*/

  /******* chartLocation **********/
  $: chartLocation={
    series: chartLocationData.datas,
    chart: {
      height:"100%",
      type: 'donut'
    },
    labels: chartLocationData.labels,
    legend: {
      position: 'top'
    },   
  }


  /******* chartOS **********/

  $: chartOS={
    series: chartOSData.datas,
    
    chart: {
      height: "100%",
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {

        dataLabels: {
          name: {
            fontSize: '22px',
          },
          value: {
            fontSize: '16px',
            formatter: function (val) {
              return val
            }
          },
          total: {
            show: true,
            label: 'Total',
            formatter: function (w) {
              return w.globals.seriesTotals.reduce((a, b) => {
                return a + b
              })
            }
          }
        }
      }
    },
    labels: chartOSData.labels,
    legend: {
      show: true,
      floating: false,
      position: 'top',

      labels: {
        useSeriesColors: true,
      },
      formatter: function(seriesName, opts) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
      },
      itemMargin: {
        avertical: 5
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
            show: false
        }
      }
    }]
  }



 /* $: chartOS={
    type: 'doughnut',
    data: {
      labels: chartOSData.labels,
      datasets: [
        {
          label: i18n('Scans by Device'),
          //backgroundColor: 'rgba(255, 204, 0, 0.5)',
          //borderColor: '#ffcc00',
          //pointBorderWidth:10,
          //borderWidth: 5,
          //fill: true,
          data: chartOSData.datas
        }
      ]
    },
    options: {
      circumference: Math.PI,
			rotation: -Math.PI,

      maintainAspectRatio: false, 
      responsive: true,
      aaspectRatio:1,
      legend:{
        display: true,
        position: 'top',
        labels:{
          usePointStyle: true,
          boxWidth: 5
        }
      },
      scales: {
        xAxes: [{
          display: false,
          ticks: { stepSize: 10, autoSkip:true, min:0 }
        }],
        yAxes: [{
          display: false,
          ticks: {stepSize: 10, autoSkip:false, min:0}
        }]
      }
    }
  }*/

  /******* chartBrowser **********/
  $: chartBrowser={
    series: chartBrowserData.datas,
    chart: {
      height:"100%",
      type: 'donut',
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10
      }
    },
    grid: {
      padding: {
        bottom: -80
      }
    },
    labels: chartBrowserData.labels,
    legend: {
      position: 'bottom'
    }
  }

  function exportAnalitycs(){}
  function mailAnalitycs(){}

</script>

{#if code.id}
  <div class="container fsContent">
    <Title title="Analytics" detail={code.name} back="/codes"/>

    <FilterLine>
      <div>
        {#each preDates as preDate}
          <div class:active={startDate==preDate.startDate && endDate==preDate.endDate} on:click={()=>{startDate=preDate.startDate; endDate=preDate.endDate}}><I18n>analytics--{preDate.name}</I18n></div>
        {/each}
      </div>
    </FilterLine>

    <DatesPicker bind:startDate bind:endDate maxDate={new Date()}/>


    <!--
    {#await codes then codes}
      <MultiSelect bind:value={codeIds} values={codes}>
        <option value="UZ">Uzbek</option>
        <option value="VI">Vietnamese</option>
        <option value="CY">Welsh</option>
        <option value="XH">Xhosa</option>
      </MultiSelect>
    {/await}
    -->

    <div class="fs-5 shadow p-2 my-2 bg-body rounded">
      <span class="fw-bold me-2">{totalScan}</span>
      <span><I18n>analytics--total Scans on period</I18n></span>
      {#if showUnitChoice}
        <span>Days <Switch bind:checked={unitHours}/> Hours</span>
      {/if}
    </div>

    <div class="fsScroll">

      <div class="d-none card  mt-3 mb-3 shadow-soft">
        <div class="card-header">
          <div class="fs-5 fsContentHorizontal">
            <div>
              <i class="fa-solid fa-code me-2"></i>Operating Systems
            </div>
            <button class="btn btn-sm btn-outline-primary small">
              <i class="fa-solid fa-file-download me-2"></i>Export
            </button>
          </div>
        </div>
        <div class="card-body p-0">
          <div style="overflow: auto; height: 300px;">
            <table class="table table-hover" style="margin-bottom: 0px;">
              <thead class="mdb-color darken-3" style="background: rgb(21, 169, 124);">
                <tr class="text-white">
                  <th>#</th>
                  <th>Operating System</th>
                  <th>Scans</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th> 
                  <td>Android</td>
                  <td>1</td>
                  <td>100%</td>
                </tr>
                <tr>
                  <th scope="row">1</th> 
                  <td>Android</td>
                  <td>1</td>
                  <td>100%</td>
                </tr><tr>
                  <th scope="row">1</th> 
                  <td>Android</td>
                  <td>1</td>
                  <td>100%</td>
                </tr><tr>
                  <th scope="row">1</th> 
                  <td>Android</td>
                  <td>1</td>
                  <td>100%</td>
                </tr><tr>
                  <th scope="row">1</th> 
                  <td>Android</td>
                  <td>1</td>
                  <td>100%</td>
                </tr><tr>
                  <th scope="row">1</th> 
                  <td>Android</td>
                  <td>1</td>
                  <td>100%</td>
                </tr><tr>
                  <th scope="row">1</th> 
                  <td>Android</td>
                  <td>1</td>
                  <td>100%</td>
                </tr><tr>
                  <th scope="row">1</th> 
                  <td>Android</td>
                  <td>1</td>
                  <td>100%</td>
                </tr><tr>
                  <th scope="row">1</th> 
                  <td>Android</td>
                  <td>1</td>
                  <td>100%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>


      <div class="card mt-3 mb-3">
        <div class="card-header">
          <I18n>analytics--Scans by date</I18n>
        </div>
        <div class="card-body" style="height:300px;">
          <Chart data={chartDate}/>
    
        </div>
      </div>


      <!--
      <div class="card mb-3">
        <div class="card-header">
          <I18n>Scans by QR Code/Tag</I18n>
        </div>
        <div class="card-body" style="height:300px;">
          <Chart1 data={chartQRTag}/>
        </div>
      </div>
      -->

      <!--
      <div class="card mb-3">
        <div class="card-header">
          <I18n>Scans by devices (true)</I18n>
        </div>
        <div class="card-body" style="height:300px;">
          <Chart1 data={chartDevices}/>
        </div>
      </div>
      -->

      <div class="card mb-3">
        <div class="card-header">
          <I18n>analytics--Scans by location</I18n>
        </div>
        <div class="card-body" style="height:300px;">
          <Chart data={chartLocation}/>
        </div>
      </div>

      <div class="card mb-3">
        <div class="card-header">
          <I18n>analytics--Scans by device</I18n>
        </div>
        <div class="card-body" style="height:300px;">
          <Chart data={chartOS}/>
        </div>
      </div>

      <div class="card mb-3">
        <div class="card-header">
          <I18n>analytics--Scans by browser</I18n>
        </div>
        <div class="card-body" style="height:300px;">
          <Chart data={chartBrowser}/>
        </div>
      </div>
    </div>
  </div>
{/if}