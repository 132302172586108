<script>
  import { conf, lang  } from './stores.js'
  import { api, translate, i18n, setRoute, sleep, inArray, isSame } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'
  import Chart from './G_Chart.svelte'

  let dash={}
  getDash()
  $: dashboard=[
    {route: '/codes', name: 'QR codes', icon:'qrcode', value: dash.codes || 0, color:"success", valid:true},
    {route: '/codes', name: 'Total scans', icon:'barcode-read', value: dash.scans || 0, color:"success", valid:true}
  ]

  let c={ 
    lightblue:'#00FFB6',
    green:'#1AD604',
    red:'#F9344F',
    purple:'#9C27B0',
    blue:'#2F9BF4',
    orange:'#FF7903'
  }

  
  async function getDash(){
    dash= await api('/app/customers/dashboard')
    if(dash) dash.ok=true
    else dash={}
  }


  /**** dashboard tiles ****/
  function colorByPercent(percent){
    if(percent>80) return 'success'
    if(percent>50) return 'warning'
    return 'danger'
  }
</script>


<CustomerBanner/>

<Route path="/dashboard" fsContent>
  <div class="container-fluid fsContent">
    
    <Title title="Dashboard" withCustomerSelector/>

    <div class="fsScroll">
      <div class="row row-cols-1 row-cols-md-4 g-4">
        
        {#each dashboard as d}
          {#if d.valid}
            <div class="col" on:click={()=>setRoute(d.route)}>
              <div class="card h-100 pointer shadow">
                <div class="card-body">
                  <div class="card-dash">

                    {#if d.type=="right"}
                      <div>
                        <div class="text-{d.color}">{d.value}</div>
                        <div class="text">{i18n(d.name, $lang)}</div>
                      </div>
                      <div class="text-{d.color}"><I icon={d.icon}/></div>
                    {:else}
                      <div class="text-{d.color}"><I icon={d.icon}/></div>
                      <div class="text-end">
                        <div class="text-{d.color}">{d.value}</div>
                        <div class="text">{i18n(d.name, $lang)}</div>
                      </div>
                    {/if}
                  </div>
                </div>
              </div>
            </div>
          {/if}
        {/each}
      </div>

<!--
      <div class="mt-1 row row-cols-1 row-cols-md-3 row-cols-lg-4 row-cols-xxl-6 g-4">

        <div class="col" on:click={()=>setRoute('/notifications')}>
          <div class="card h-100 pointer shadow">
            <div class="card-header">Notifications</div>
            <div class="card-body">
              <div>
                <Chart data={chartNotifications}/>
              </div>
            </div>
          </div>
        </div>

        <div class="col" on:click={()=>setRoute('/tags')}>
          <div class="card h-100 pointer shadow">
            <div class="card-header">Tags</div>
            <div class="card-body">
              <div>
                <Chart data={chartTags}/>
              </div>
            </div>
          </div>
        </div>

        <div class="col" on:click={()=>setRoute('/requests')}>
          <div class="card h-100 pointer shadow">
            <div class="card-header">Demandes</div>
            <div class="card-body">
              <div>
                <Chart data={chartRequests}/>
              </div>
            </div>
          </div>
        </div>

        <div class="col" on:click={()=>setRoute('/actions')}>
          <div class="card h-100 pointer shadow">
            <div class="card-header">Actions</div>
            <div class="card-body">
              <div>
                <Chart data={chartActions}/>
              </div>
            </div>
          </div>
        </div>

        <div class="col" on:click={()=>setRoute('/trainings')}>
          <div class="card h-100 pointer shadow">
            <div class="card-header">Trainings</div>
            <div class="card-body">
              <div>
                <Chart data={chartTrainings}/>
              </div>
            </div>
          </div>
        </div>

        <div class="col" on:click={()=>setRoute('/breaches')}>
          <div class="card h-100 pointer shadow">
            <div class="card-header">Violations</div>
            <div class="card-body">
              <div>
                <Chart data={chartBreaches}/>
              </div>
            </div>
          </div>
        </div>



      </div>
      
      <div class="mt-1 row row-cols-1 row-cols-md-3 g-4">

        <div class="col">
          <div class="card h-100 pointer shadow">
            <div class="card-header">Couverture traitements</div>
            <div class="card-body">
              <div>
                <Chart data={chartRadar}/>
              </div>
            </div>
          </div>
        </div>




      </div>
    -->


    </div>
  </div>
</Route>

<style>

  .card-dash {
    line-height:2.5rem;
    font-size:3rem;
    display: flex;
    justify-content: space-between;
  }
  .text{
    line-height:1rem;
    font-size:1rem;
  }
</style>


     