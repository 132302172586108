<script>
	
	import {onMount} from "svelte";
	import {loadJS, sleep, platform, asyncForEach, isArray, isObject} from "./h.js";

	export let points=[]
	export let point={name: "Paris", location: "48.852969, 2.349903", radius: 30}
	export let mode="points" //tour, direction, points, point
	export let noRouteAuto=false
	export let attribution=''
	export let initLatLng=[48.852969, 2.349903] //paris center
	export let routeType='mapbox/walking'
	export let mapBoxToken='pk.eyJ1Ijoia2xpY2F0IiwiYSI6ImNrcHBoa3Y3NzAyMnEydm8xc3VqeWEzejUifQ.gLSy3fHlK873Ke04XCy3Aw'
	//export let thunderforestToken=''

	let url,mapId, minZoom=1,	maxZoom=20, showRouteDelay=0
	mapId='mapbox/streets-v11'
	//mapId='mapbox/outdoors-v11'
	//mapId='mapbox/light-v10'
	//mapId='mapbox/dark-v10'
	//mapId='mapbox/satellite-streets-v11'
	url=`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${mapBoxToken}`
	url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
	//url='http://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png'
	//url=`https://{s}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png?apikey=${thunderforestToken}`




	//jeux de tests
	/*
	points = [
		{ name: "Clichy", location: [ 48.90278795880334, 2.3047881328909585] },
		{ name: "Brest", location:  [ 48.383, -4.500 ] },
		{ name: "Quimper", location:  [ 48.000, -4.100 ] },
		{ name: "Bayonne", location:  [ 43.500, -1.467 ] },
		{ name: "Uzès", location: [ 44.04504617530313,4.42043098863482] }

	]
	
	points=[
		{ name: "Place de la république", location: "48.914846,2.268198" },
		{ name: "hôtel de ville", location: "48.914532,2.267883" },
		{ name: "parc tricon 1", location: "48.913951,2.267989", noMarker: true },
		{ name: "parc tricon 2", location: "48.914004,2.267747", noMarker: true },
		{ name: "Robert-bain", location: "1 avenue robert-bain bois-colombes 92270" },
		{ name: "Parc des tourelles", location: "48.915019,2.264328" },
		{ name: "Ecole Paul-Bert", location: "48.913633,2.266711"}
	]

	points=[
		{ name: "", location: "48.683368,1.746219" },
		{ name: "", location: "48.686435,1.738467" },
		{ name: "", location: "48.683793,1.724616" },
		{ name: "", location: "48.673705,1.722254" },
		{ name: "", location: "48.673648,1.728567" },
		{ name: "", location: "48.677672,1.736425" },
	]

	points=[
		{ name: "Pastis", location: "44.04504617530313,4.42043098863482"}, //30700 Uzès
		{ name: "Pastis", location: "43.98046257755851,4.331061685478156"},
		{ name: "Pastis", location: "44.00936004362942,4.33655484936852"},
		{ name: "Pastis", location: "44.0221988448837,4.395606361189931"}
	]
	*/
	//Fin jeux de tests

	let Recenter=true

  let container, map, located, latLng, heading, speed, accuracy, compass=null, markerHere, myIcon

	onMount(async () => {await startMap()})

	async function startMap() {
		//DEFS
		//https://leafletjs.com/examples/quick-start/
		await loadJS("https://unpkg.com/leaflet@latest/dist/leaflet.js")
		await loadJS("https://unpkg.com/leaflet-routing-machine@latest/dist/leaflet-routing-machine.js")
		await loadJS("https://unpkg.com/leaflet-control-geocoder@latest/dist/Control.Geocoder.js")
		await loadJS("https://unpkg.com/leaflet-rotatedmarker@latest/leaflet.rotatedMarker.js")
		await loadJS("https://unpkg.com/leaflet.markercluster@latest/dist/leaflet.markercluster.js")

		//create map
		map = L.map(container).setView(initLatLng, 13);
		map.zoomControl.remove();
		map.attributionControl.setPrefix('')
		L.tileLayer(url, {
						attribution: attribution,
						minZoom: minZoom,
						maxZoom: maxZoom,
						tileSize: 512,
						id: mapId,
						zoomOffset: -1
		}).addTo(map);

		//create icons
		//myIcon is defined globally for use in startCompass
		myIcon = L.icon({
			iconUrl: 'https://image.flaticon.com/icons/png/512/566/566014.png',
			iconSize: [40, 40],
			iconAnchor: [20, 20],
			popupAnchor: [-20, -20],
		})

		let stepIcon = L.icon({
			iconUrl: 'https://odysite.fr/wp-content/uploads/2021/06/odysite-logo.png',
			iconSize: [55,64],
			iconAnchor: [27, 64],
			popupAnchor: [0, -64],
		})

		let routingLine //contains routingDrawing
		let markerClusterGroup //contains markerClusterGroup

		//Init
		switch (mode) {
			case "direction":
				startCompass()
				startLocate()
				break;
			case "tour":
				showRoute(points, noRouteAuto, true)
				break;
			case "points":
				showPoints(points,true)
				break;
			case "point":
				showApoint(point)
				break;
		}
		
		//functions
		async function showApoint(point){
			let latLng=await locationToLatLng(point.location)
			let label=point.name
			let circleRadius=point.radius || 0
			let markerInit = L.marker(latLng).addTo(map)
			markerInit.bindPopup(label).openPopup();
			if(circleRadius) L.circle(latLng, {
					color: 'red',
					fillColor: '#f03',
					fillOpacity: 0.1,
					radius: circleRadius
			}).addTo(map);
		}

		function startLocate(){
			map.locate({setView: false, timeout: 1000, maxZoom: 20, enableHighAccuracy: true, watch: true})
			map.on('locationerror', onLocationError)
			map.on('locationfound', onLocationFound)
		}

		function stopLocate(){
			map.stopLocate()
		}
	
		let lastShowRoute=0, circleHere, defaultIcon //markerHere is defined globally for use in startCompass
		function onLocationFound(e) {
			located=true
			heading=(Math.round(e.heading) || 0)
			speed=Math.round(e.speed)|| 0
			accuracy=e.accuracy
			latLng=e.latlng
			if(Recenter) map.setView(latLng,20)
			if(!markerHere) {
				markerHere=L.marker(latLng,{
					rotationAngle: heading,
					rotationOrigin: 'center center'
				}).addTo(map)
				//.bindPopup("You are within " + accuracy + " meters from this point").openPopup();
				circleHere=L.circle(latLng, { radius: accuracy/2, fillOpacity: 0.1}).addTo(map);
			} else {
				circleHere.setLatLng(latLng).setRadius(accuracy/2)
				markerHere.setLatLng(latLng)
				if(speed) { 
					markerHere.setIcon(myIcon).setRotationAngle(heading)
				}
				if(compass==null && !speed) {
						markerHere.setIcon(L.marker().getIcon()).setRotationAngle(0)
				}
			}

			if(!showRouteDelay && lastShowRoute) return
			if(lastShowRoute< new Date().getTime()-showRouteDelay*1000){
				lastShowRoute=new Date().getTime()
				if(mode=='direction') showRoute([{name:'here', location: latLng , noMarker:true}, ...points.slice(1)], noRouteAuto)
			}
		}
		function onLocationError(e) {
			located=false
			if(!showRouteDelay && lastShowRoute) return
			if(lastShowRoute< new Date().getTime()-showRouteDelay*1000){
				lastShowRoute=new Date().getTime()
				if(mode=='direction') showRoute(points, noRouteAuto, true)
			}
		}

		async function locationToLatLng(location){
			let nominatim = L.Control.Geocoder.nominatim()
			let latLng=null
			try{ //check if location is a latLng
				if(!isArray(location) && !isObject(location)) location=location.split(',')
				latLng=L.latLng(location)
			} catch(e){ }
			if(latLng==null) {
				//request nominatim
				return new Promise((resolve, reject) => nominatim.geocode(location, function (results) {
					if(results.length) L.latLng(results[0].center)					
					resolve(results.length?L.latLng(results[0].center):true)
				}))
			} else {
				return latLng
			}
		}

		async function getWaypoints(points) {
			let waypoints=[]
			await asyncForEach(points, async function (element, index, array) {
				const latLng= await locationToLatLng(element.location)
				waypoints.push({name: element.name || (index+1).toString(), latLng: latLng, noMarker: element.noMarker})
			})
			return waypoints
		}

		async function showRoute(points, noRouteAuto, bound){
			let waypoints=await getWaypoints(points)

			let routing= new L.Routing.mapbox(mapBoxToken,{
				timeout:10 * 1000,
				profile: routeType
			})
			if(!noRouteAuto) routing.route(waypoints,function (status, results) {
				console.log('Total distance is ' + Math.round(results[0].summary.totalDistance / 1000 *10)/10 + ' km and total time is ' + Math.round(results[0].summary.totalTime /60) + ' minutes')
				routingLine=L.Routing.line(results[0], {					
					styles: [{color: 'blue', weight: 5}]
				}).addTo(map)
			})
			if(noRouteAuto) {
				let Awaypoints=Array.from(waypoints, x => x.latLng);
				routingLine=L.polyline(Awaypoints, {color: 'blue', weight: 5}).addTo(map)
			}

			showPoints(waypoints, bound)
		}

		async function showPoints(points, bound){
			// à ajouter dans le popup pour se faire guider
			//<a href="https://www.google.com/maps/place/Tour+Eiffel/@48.8582581,2.2903622,17z/data=!3m1!4b1!4m5!3m4!1s0x47e66fe26b7d84eb:0x122e2d1227b415f8!8m2!3d48.8582546!4d2.2925509" target="_blank">ici</a>
			//<a href="http://maps.apple.com/?q=Mexican+Restaurant&sll=50.894967,4.341626&z=10&t=s" target="_blank">ici</a>

			markerClusterGroup = L.markerClusterGroup()
			await asyncForEach(points, async function (waypoint) {
				if(!waypoint.noMarker) {
					const marker = L.marker( waypoint.location?await locationToLatLng(waypoint.location):waypoint.latLng, { icon: stepIcon})
					marker.bindPopup(waypoint.name).openPopup()
					markerClusterGroup.addLayer(marker)
				}
			})
			map.addLayer(markerClusterGroup)
			if(bound) map.fitBounds(markerClusterGroup.getBounds())
		}

		function removePoints(){
			map.removeLayer(markerClusterGroup)
		}
	
		function removeRoute(){
			map.removeLayer(routingLine)
		}
	}

	function startCompass() {
		//https://dev.to/orkhanjafarovr/real-compass-on-mobile-browsers-with-javascript-3emi
		if(platform('iOS')) {
			try{
				DeviceOrientationEvent.requestPermission()
				.then((response) => {
					if (response === "granted") window.addEventListener("deviceorientation", handlerCompass, true)
				})
			} catch(e) {}
		} else window.addEventListener("deviceorientationabsolute", handlerCompass, true)

		function handlerCompass(e) {
			if(speed) return
			if (e.absolute && e.alpha!=null) {
				compass = Math.round(Math.abs(e.alpha - 360))
			} else if(e.webkitCompassHeading) {
				//get absolute orientation for Safari/iOS
				compass = Math.round(e.webkitCompassHeading)
			} else compass=null
			if(markerHere && compass!==null) markerHere.setIcon(myIcon).setRotationAngle(compass)
		}
	}

	function recenter(){
		console.log("recenter")
			map.setView(latLng,20)
			Recenter=true
		}

	function decenter(){
		console.log("decenter")
		if(located) Recenter=false
	}


</script>

<svelte:head>
	<link rel="stylesheet" href="https://unpkg.com/leaflet@latest/dist/leaflet.css"/>
	<link rel="stylesheet" href="https://unpkg.com/leaflet.markercluster@latest/dist/MarkerCluster.Default.css" />
</svelte:head>


	<!--button on:click={startCompass}>test</button-->
	<div class="mapContainer">
		<div class="map" on:wheel={decenter} on:pointermove={decenter} bind:this={container}></div>
	
		<div class="debug" >
			You are within {accuracy} meters from this point<br>
			located {located}, heading {heading} <br>
			speed {speed} , compass {compass}
		</div>
		<div class="buttons w-100 text-center" >
			<button class="btn btn-lg btn-primary" class:d-none={Recenter} on:click={recenter}><i class="far fa-location-arrow"></i> Recentrer</button>
		</div>
</div>



<style>
	.mapContainer{
		flex:1;
		position: relative;
		min-height:200px;
	}
	.map{
		height:100%;
		width:100%;
		z-index:1;
	}
	.debug{
		z-index:2;
		position:absolute;
		bottom:0;
	}
	.buttons{
		z-index:2;
		position:absolute;
		bottom: 10px;
		left: 10px;
	}
</style>
