
<script>
  import { conf } from './stores.js'
  import { i18n, nl2br, cdn } from './h.js'

  import I from './G_I.svelte'

  export let msg=""
  export let color=""
  export let isurl=false
  export let ispassword=false
  export let icon=""
  export let activity={}

  const i18nBr = (id) =>{
    return nl2br(i18n(id))
  }

</script>
<a href={$conf.companyUrl}><img class="img-fluid" src={cdn($conf.logo)} alt=""></a>
<div class="text-center my-4 fa-6x h1 text-{color}">
  <I class="pulse" {icon}/>
</div>
<div class="text-center mx-2 my-4 h5">
  {@html i18nBr(msg)}
</div>
{#if isurl}
  <div class="text-center mx-2 my-4 h5">
    <I class="pulse fa-4x mb-4" arrow-down/><br>
    <a href={$conf.companyUrl}>{$conf.title}</a>
  </div>
{/if}

{#if ispassword}
  <div class="text-center mx-2 my-4 h5">
    <I class="pulse fa-4x mb-4" arrow-down/><br>
    <form action="/{activity.shortId}" method="post" >

        <label for="pwd" class="mx-auto form-label">{i18n("unity--Password")}</label>
        <input type="password" class="mx-auto w-75 form-control" name="pwd" id="pwd" autocomplete="off">
        <button type="submit" class="mx-auto mt-3 w-75 btn btn-warning"><I paper-plane/></button>

    </form>
  </div>
{/if}

