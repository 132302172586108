<script>
  import { lang } from './stores.js'
  import { i18n, nl2br, cdn } from './h.js'

  export let activity={}

  let cl=$$props.class || ''

  const i18nBr = (id, lang) =>{
    return nl2br(i18n(id, lang))
  }
</script>

<div class="unityContent {cl} flex-grow-1 content" style="color:{activity.u.colorText}; 
  {activity.u.isImageBg && activity.u.imageBgCdn?`background: url(${cdn(activity.u.imageBgCdn)}) top center / cover no-repeat;`:''}
  background-color:{activity.u.colorBg};
  overflow-y: auto;">
  <div class="d-flex flex-column insideContent" 
  class:justify-content-center={activity.u[activity.type].placement=="center"}
  class:justify-content-end={activity.u[activity.type].placement=="bottom"}
  style="min-height:100%;"
  >
  <div class="transparencyBg" style="opacity:{activity.u.transparencyBg?activity.u.transparencyBg/100:0};background-color:{activity.u.colorBg};"></div>
    {#if i18nBr(activity.u[activity.type].intro, $lang)}
      <div class="my-4 px-1"
      class:text-center={activity.u[activity.type].introCenter}
      class:text-start={!activity.u[activity.type].introCenter}>{@html i18nBr(activity.u[activity.type].intro, $lang)}</div>
    {/if}
    <slot/>
  </div>
</div>
<style>
  :global(.unityDesign .content::-webkit-scrollbar) {width: 8px;height: 8px;}
  :global(.unityDesign .content::-webkit-scrollbar-track) { background: lightgray;}
  :global(.unityDesign .content::-webkit-scrollbar-thumb) { background-color: gray;}


  .unityContent {
    position:relative;
  }

  @keyframes shrink {
    0% {
      background-size: 110% 110%;
    }
    100% {
      background-size: 100% 100%;
    }
  }

  .insideContent{
    position: relative;
    z-index: 2;
  }

  .transparencyBg{
    position: absolute;
    z-index: -1;
    height:100%;
    width:100%;
    /*background-color: black;*/
  }
</style>