<script>
	import { spinner } from './stores.js'
  import I18n from './G_I18n.svelte'

	let minTimeBeforeShowSpinner=50
	let spinnerShow
	let spinnerShowTimeout
	$: if($spinner) spinnerShowTimeout=setTimeout(() => {
		if($spinner) spinnerShow=true
	}, minTimeBeforeShowSpinner)
	$: if(!$spinner) { clearTimeout(spinnerShowTimeout);spinnerShow=false;}
</script>

{#if spinnerShow}
	<div class="fullScreen spinner">
		<div>
			<i class="fa-solid fa-spinner-third fa-spin fa-3x  fa-fw"></i>
			<p><I18n>g--Please wait</I18n></p>
		</div>
	</div>
{/if}


<style>
	.spinner {
		background: rgba(0,0,0,0.1);
		z-index: 2000;
	}
	.spinner div {
		position:absolute;
		color:grey;
		top: 50%;
		width:100%;
		text-align: center;
	}
</style>
