<script>
  import { conf, permissions, modal } from './stores.js'
  import { api, translate, i18n, setRoute, sleep, inArray, isSame, getById, genCode, anchor } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'

  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'

  let objs=[], obj={}, keyword="", sortBy={tagDate:1}, filter={}, tagsSelected=[]

  $: list(keyword, filter, tagsSelected, sortBy)
    
  async function add(){
    setRoute("/tours/edit")
  }

  async function bulk(){
    setRoute("/tours/bulk")
  }

  async function edit(id){
    setRoute(`/tours/edit/${id}`)
  }

  async function update(){
    await api(`/app/customers/tours/set`,{obj: obj})
    list()
    getTags()
    setRoute("/tags")
  }

  async function get(id){
    obj={}
    if(id) obj=await api(`/app/customers/tours/get/${id}`)
    obj={ ...{dpo:{}, ...obj }}
  }

  isSame("listTours","clean")
  async function list(...checkArgs){
    if(checkArgs.length && isSame("listTours", checkArgs)) return

    let existentTags=tagsSelected?tagsSelected.filter((t)=>{return tags.includes(t)}):false
    let req={ ...filter, ...(existentTags.length?{tags: { $in: existentTags}}:{})}
    objs = await api(`/app/customers/tours/list`, {
      tag: {...req},
      keyword:keyword,
      sortBy: sortBy
    })
  }

  async function del(){
    $modal={cancel:true, title:`${i18n("g--Delete")} ${i18n(obj.name)}`, msg:i18n("g--Confirm delete"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/tours/delete/${obj.id}`)
      list()
      setRoute("/tours")
    }}
  }

  let tags=[]
  getTags()
  async function getTags(){
    tags = await api('/app/customers/tours/tags/list')
  }

</script>


<CustomerBanner/>


<Route path="/tours/*" pathHide="/tours/edit/:id" fsContent>
  <div class="container-lg fsContent">
    
    <Title title withCustomerSelector back/>

    <FilterLine {tags} bind:tagsSelected search bind:keyword 
      create={$permissions?add:false} createBulk={false}

      bind:filter filters={[
      ]}

      bind:sortBy sortBys={[
        { id:'name', title:'g--By name'},
        { id:'valid', title:'g--By valid', desc:true}
      ]}
    />

    <List onSelect={edit} refresh={()=>list()} title="g--tours" {objs} fields={[
      {id:'name', title:'Name'},
      {id:"valid", title:"Valid"}
    ]}/>
  </div>
</Route>


<Route path="/tours/edit/:id" let:routeParams fsContent>
  {#await get(routeParams.id) then}

    <div class="container fsContent">
      <Title title="tour" detail={obj.name} back="/tours"/>
      
      <div class="fsScroll">
        <Form>
          <Input type="hr" icon="fa-info-square" label="Description"/>
          <Input bind:value={obj.name} required type="text" multilang translator icon label="Name"/>
          
          <div class="row">
            {#if obj.id}
              <Button validity {del} class="col-auto"/>
              <Button validity {update} class="col-auto"/>
            {:else}
              <Button validity {update} actionModel="save" class="col-auto"/>
            {/if}
          </div>
        </Form>
      </div>
    </div>
  {:catch}
    <Wrong/>
  {/await}
</Route>