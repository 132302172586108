<script>
  import { lang } from './stores.js'
  import { permissions } from './stores.js'
  import { i18n, asArray } from './h.js'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import _isEqual from 'lodash/isEqual'

  export let step=false
  export let filter=false
  export let sortBy=false
  export let steps=[]
  export let filters=[]
  export let sortBys=[]

  export let tags=false
  export let tagsSelected=[]
  export let search=false
  export let keyword=''
  export let create=false
  export let createLabel='Create'
  export let createBulk=false
  export let createBulkLabel=''
  export let dl=false
  export let dlLabel='Download'
</script>

<div class="line">
  <slot/>

  {#if steps.length}
    <div>
      {#each steps as v}
        {#if v.cond!==false}
          <div class:active={v.step==step} on:click={()=>{step=v.step}}><I18n id={v.title}/></div>
        {/if}
      {/each}
    </div>
  {/if}

  {#if filters.length}
    <div>
      {#each filters as v}
        {#if v.cond!==false}
          <div 
            class:active={_isEqual(v.filter,filter)} 
            on:click={()=>{filter=v.filter}}>
            <I18n id={v.title}/>
          </div>
        {/if}
      {/each}
    </div>
  {/if}

  {#if sortBys.length}
    <div>
      {#each sortBys as v}
        {#if v.cond!==false}
          <div class:active={sortBy[v.id]} on:click={()=>{sortBy={[v.id]:v.desc?-1:1}}}><I18n id={v.title}/></div>
        {/if}
      {/each}
    </div>
  {/if}

</div>

{#if tags || search || create }
  <div class="row">
    {#if tags}
      <div class="col-12 col-lg-12 mb-1">
        {#each asArray(tags) as tag (tag)}
          <input id="tag_{tag.id || tag}" class="tag" type="checkbox" bind:group={tagsSelected} value={tag.id || tag}>
          <label class="mb-1 me-1" for="tag_{tag.id || tag}">{tag.name || tag}</label>
        {/each}
      </div>
    {/if}
    {#if search || create}
      <div class="ms-auto col-12 col-lg-12 mb-1">
        <div class="fsContentHorizontal">
          {#if search}
            <div class="me-1 col">
              <div class="input-group">
                <span class="input-group-text"><I search/></span>
                <input type="text" bind:value={keyword} class="form-control" placeholder={i18n("Search", $lang)} aria-label="Search" aria-describedby="button-search">
              </div>
            </div>
          {/if}
          {#if create}
            <div class="col-auto">
              <button on:click={create} class="btn btn-success"><I plus/><span class="d-none d-sm-inline ms-2">{i18n(createLabel)}</span></button>
            </div>
          {/if}
          {#if createBulk}
            <div class="col-auto ms-1">
              <button on:click={createBulk} class="btn btn-secondary" title={i18n(createBulkLabel, $lang)}><I hat-wizard/></button>
            </div>
          {/if}
          {#if dl}
            <div class="col-auto ms-1">
              <button on:click={dl} class="btn btn-success"><I download/><span class="d-none d-sm-inline ms-2">{i18n(dlLabel)}</span></button>
            </div>
          {/if}
        </div>
      </div>
    {/if}
  </div>
{/if}

<style>
  
  .line {  
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 2px solid #dee2e6;
    margin-bottom: 0.5rem;
  }

  .line :global(>div) {  
    display: flex;
    flex-wrap: wrap;
  }

  .line :global(>div>div.active) {
    border-bottom: 4px solid var(--brandColor);
  }
  .line :global(>div>div:hover){
    border-bottom: 4px solid var(--brandColor2);
  }

  .line :global(>div>div) {
    white-space: nowrap;
    padding-bottom: 2px;
    cursor: pointer;
    margin-right: 1rem;
  }


  input[type="checkbox"].tag {
    display: none;
  }
  input[type="checkbox"].tag:hover + label {
    border: 1px solid var(--brandColor);
    background-color: var(--brandColor);
    opacity:0.5;
  }
  input[type="checkbox"].tag + label {
    cursor:pointer;
    apadding: 0.34rem 0.5rem;
    padding: 0rem 0.5rem;
    font-size:0.8rem;
    border: 1px dashed var(--brandColor);
  }
  input[type="checkbox"].tag:checked + label {
    border: 1px solid var(--brandColor);
    background-color: var(--brandColor);
    color:white;
  }
</style>