<script>
  import { conf, online, wscOnline} from './stores.js'
  import { atobUnicode } from './h.js'
  import Start from './L_Start.svelte'
  export let context={}

  if(typeof window !=='undefined' && window.context) {
    context=JSON.parse(atobUnicode(window.context))
  }
  $conf=context.conf || {}
  let navigatorOnline=false
	$: $online=($wscOnline==-1 && navigatorOnline) || ($wscOnline && navigatorOnline)
  //$: console.log(`WSC : ${$wscOnline} - navigatorOnline : ${navigatorOnline} - Online ? : ${$online}`);
</script>
<Start/>

<svelte:window bind:online={navigatorOnline}/>
<svelte:head>
	<meta name="theme-color" content="{$conf.metaThemeColor || 'grey'}">
</svelte:head>