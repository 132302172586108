<script>
  import { flip } from 'svelte/animate'
  import { tick } from 'svelte'
  import { lang } from './stores.js'
  import { i18n, nl2br, api, cdn, btoaUnicode, fileExt, asArray, mapObjsIdToArray } from './h.js'

  import I18n from './G_I18n.svelte'
  import I from './G_I.svelte'
  import Video from './G_Video.svelte'
  import Input from './G_Input.svelte'
  import Form from './G_Form.svelte'
  import PdfImg from './G_PdfImg.svelte'
  
  export let editItemIndex=null

  export let items=[]
  export let page={}
  export let fields={}
  export let submit=false
  export let isDesign=false
  export let isPreview=false

  $: if(false && isDesign && fields) { //we purge ghost fields[xxidxx] if they don't exit anymore (when we change field id in design mode)
    const ids = mapObjsIdToArray(items)
    for (const id in fields) {
      if(!ids.includes(id)) delete fields[id]
    }
  }
  let me=[]
  let pdfButton=[]


  let submitDone=false

  $: if(submitDone && (isDesign || isPreview)) setTimeout(() => {
    submitDone=false
  }, 10000)

  const i18nBr = (id, lang) =>{
    return nl2br(i18n(id, lang))
  }

  let social=[
    { name:"twitter", url:"https://twitter.com/", icon:"fab fa-x-twitter", prefix:"@"},
    { name:"instagram", url:"https://www.instagram.com/", prefix:""},
    { name:"linkedin", url:"", icon:"fab fa-linkedin", prefix:""},
    { name:"snapchat", url:"https://www.snapchat.com/add/", prefix:"@"},
    { name:"tiktok", url:"https://tiktok.com/@", icon:"fab fa-tiktok", prefix:"@"},
    { name:"pinterest", url:"https://www.pinterest.fr/", prefix:""},
    { name:"youtube",  url:"", prefix:""},
    { name:"whatsapp", url:"https://wa.me/", prefix:""},
    { name:"flickr", url:"https://www.flickr.com/photos/", icon:"fab fa-flickr", prefix:""},
    { name:"facebook", url:"", prefix:""},
    { name:"url", url:"", icon:"fas fa-square-w", prefix:""},
    { name:"email", url:"mailto:", icon:"fas fa-square-envelope", prefix:""},
    { name:"phone", url:"tel:", icon:"fas fa-square-phone", prefix:""}
  ]
  function cleanSocialAccount(account="", encode=false){
    account = account.replace(/^@/, "");
    account = account.replace(/^#/, "");
    if(encode) account=encodeURI(account)
    return account
  }

  function del(index){
    items.splice(index,1);
    editItemIndex=null
    items=items
  }
  function edit(index){
    editItemIndex=index
  }
  function up(index){
    items.splice(index-1, 0, items.splice(index, 1)[0])
    editItemIndex=null
    items = items
  }
  function down(index){
    items.splice(index+1, 0, items.splice(index, 1)[0])
    editItemIndex=null
    items = items
  }

  //Drag&Drop
  const dragstart = (event, indexFrom) => {
    if(!isDesign) return
    editItemIndex=null
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.dropEffect = 'move'
    event.dataTransfer.setData('text/plain', JSON.stringify({indexFrom:indexFrom}))
  }

  let hovering = null
  const drop = async (event, indexTo) => {
    if(!isDesign) return
    event.dataTransfer.dropEffect = 'move'
    const data = await JSON.parse(event.dataTransfer.getData("text/plain"))
    let newTracklist = items
    if(data.indexFrom!==undefined){
      if (data.indexFrom < indexTo) {
        newTracklist.splice(indexTo + 1, 0, newTracklist[data.indexFrom])
        newTracklist.splice(data.indexFrom, 1)
      } else {
        newTracklist.splice(indexTo, 0, newTracklist[data.indexFrom])
        newTracklist.splice(data.indexFrom + 1, 1)
      }
      items = newTracklist
    }
    if(data.type){
      let after=!items.length?0:1
      newTracklist.splice(indexTo+after, 0, {type:data.type})
      items = newTracklist
      await tick()
      editItemIndex=indexTo+after
    }
    hovering = null
  }

</script>

<Form norow>
  {#if isDesign && !asArray(items).length}
    <div class="firstItemAddPlace text-center"
      on:drop={event => drop(event, 0)}
      ondragover="return false">
      <I plus 4x/><br>
      <I18n>code--Drag you first block here</I18n>
    </div>
  {/if}
  {#each asArray(items) as item,index (item)}
    <div on:click={()=>edit(index)} bind:this={me[index]} 
      animate:flip={{duration:200}}
      class="item"
      class:isDesign
      draggable={isDesign}
      class:draggable={isDesign}
      on:dragstart={event => dragstart(event, index)}
      on:drop={event => drop(event, index)}
      ondragover="return false"
      on:dragenter={() => hovering = index}
      on:dragend={() => hovering = null}
      class:dragNdrop-hovering={isDesign && hovering === index}
      class:edit={isDesign && editItemIndex === index}>
      {#if isDesign}
        <div class="control">
          <I title="move" hand-sparkles/>
          <span class="pointer" on:click|stopPropagation={()=>del(index)}><I  class="pointer" title="delete" trash/></span>
          <span class="pointer" on:click|stopPropagation={()=>edit(index)}><I class="pointer" title="modify" edit/></span>
          {#if index>0}<span class="pointer" on:click|stopPropagation={()=>up(index)}><I class="pointer" title="move up" caret-up/></span>{/if}
          {#if index<items.length-1}<span class="pointer" on:click|stopPropagation={()=>down(index)}><I class="pointer" title="move down" caret-down/></span>{/if}
        </div>
      {/if}
      {#if item.type=="title"}
        <div class="container mt-4 mb-2">
          <h1 class:text-center={item.center} class={item.class}>{@html i18nBr(item.label, $lang)}</h1>
        </div>
      {/if}
      {#if item.type=="p"} 
        <div class="container mt-4 mb-2">
          <p class:text-center={item.center} class={item.class}>{@html i18nBr(item.content, $lang)}</p>
        </div>
      {/if}
      {#if item.type=="hr"} 
        <div class="container mt-4 mb-2">
          <hr class="mx-auto" style="width:{item.width|| 50}%; opacity:0.5; border-top: 2px solid {page.colorText};">
        </div>
      {/if}
      {#if item.type=="a"}
        <div class="container my-2" class:text-center={item.center}>
          {#if item.isButton}
            <a role="button" class="w-100 btn btn-primary btn-lg {item.class} pointer" 
              style="border-color: {page.colorButtonBg}; 
              color:{page.colorButtonText}; 
              background-color:{page.colorButtonBg};" 
              target="_blank" href="{item.link}">
              <i class="me-1 fa-light" class:fa-file-pdf={fileExt(item.link)=="pdf"}></i>
              <span>{@html i18n(item.label, $lang)}</span>
            </a>
          {:else}
            <i class="me-1 fa-light" class:fa-file-pdf={fileExt(item.link)=="pdf"} class:fa-link={fileExt(item.link)!="pdf"}
              style="color:{page.colorLink};">
            </i>
            <a class="{item.class} pointer" target="_blank" href={item.link} 
              style="text-decoration: underline; border-color: {page.colorLink};  color:{page.colorLink};">
              {@html i18nBr(item.label, $lang)}
            </a>
          {/if}
        </div>
      {/if}
      {#if item.type=="input"}
        <div class="container my-2">
          {#if !item.kind || item.kind=="text"}<Input bind:value={fields[item.id]} type="text" label={item.label} required={item.required}/>{/if}
          {#if item.kind=="textarea"}<Input bind:value={fields[item.id]} type="textarea" label={item.label} required={item.required}/>{/if}
          {#if item.kind=="email"}<Input bind:value={fields[item.id]} type="email" label={item.label} required={item.required}/>{/if}
          {#if item.kind=="tel"}<Input bind:value={fields[item.id]} type="tel" label={item.label} required={item.required}/>{/if}
        </div>
      {/if}
      {#if item.type=="select"}
        <div class="container my-2">
          <Input bind:value={fields[item.id]} type="select" label={item.label} options={item.options} required={item.required}/> 
        </div>
      {/if}
      {#if item.type=="checkbox"}
        <div class="container my-2">
            <Input set={false} bind:value={fields[item.id]} type="checkbox" option={item.label} required={item.required}/>
        </div>
      {/if}
      {#if item.type=="button"}
        <div class="container my-3">
          {#if !submitDone}
            <button type="button" validity=true on:click={async ()=>{submitDone=await submit(item)}} 
              style="border-color: {page.colorButtonBg}; 
              color:{page.colorButtonText}; background-color:{page.colorButtonBg};" 
              class="w-100 btn btn-primary btn-lg {item.class} pointer">
              {@html i18n(item.label, $lang)}
            </button>
          {:else}
            <div class="text-center alert alert-success" role="alert">
              {@html i18nBr(item.msgAfterSubmit, $lang)}
            </div>
          {/if}
        </div>
      {/if}
      
      {#if item.type=="video"}
        <Video {item}/>
      {/if}

      {#if item.type=="image"}
        <img src={cdn(item.imageCdn || "assets/placeholder-image.png")} alt={item.alt} class="{item.class || ""} w-100 img-fluid"/>
      {/if}

      {#if item.type=="pdf"}
        <div class="container my-3">
          {#if item.isLink}
            <div on:click={()=>pdfButton[index]=true} class:text-center={item.center} class="{item.class} pointer"
              style="text-decoration: underline; border-color: {page.colorLink};  color:{page.colorLink};">
              {@html i18nBr(item.label, $lang)}
            </div>
          {:else}
            <button type="button" on:click={()=>pdfButton[index]=true} class="w-100 btn btn-primary btn-lg {item.class}" 
              style="border-color: {page.colorButtonBg}; 
              color:{page.colorButtonText}; 
              background-color:{page.colorButtonBg};">
              {@html i18n(item.label, $lang)}
            </button>
          {/if}
        </div>
        {#if pdfButton[index]}
          <PdfImg src={cdn(item.pdfCdn)} close={()=>pdfButton[index]=false}/>
         {/if}
      {/if}

      {#if item.type=="social"}
        <div class="container my-3">
          <div class="row">
            {#each social as social}
              {#if item[social.name]}
                <a style="color:{page.colorLink};" class="mb-3 mx-auto col-3 pointer text-center" target="_blank" href="{social.url}{cleanSocialAccount(item[social.name],true)}">
                  <div><i class="fa-2x {social.icon?social.icon:"fa-brands fa-"+social.name+"-square"}"></i></div>
                  <div>{item[social.name + "Name"] || ""}</div>
                </a>
              {/if}
            {/each}
          </div>
        </div>
      {/if}

      {#if item.type=="vcard"}
        <div class="container my-3">
          <a role="button" class="w-100 btn btn-primary btn-lg" 
            style="border-color: {page.colorLink}; 
            color:{page.colorText}; 
            background-color:{page.colorLink};" target="_blank" href="{`/api/v3/public/vcard?payload=${btoaUnicode(JSON.stringify(item.vcard))}`}">
            {@html item.label}
          </a>
        </div>
      {/if}
    </div>
  {/each}
</Form>


<style>
  .item{
    position:relative;
    border: 1px transparent solid;
  }
  .item.isDesign:hover{
    border: 1px red solid;
  }
  .item.isDesign:hover .control{display:block;}
  .item.isDesign .control{
    display:none;
    position: absolute;
    border-radius: 10px;
    top: 0;
    right: 0;
    font-size: 1rem;
    color: red;
    background: #ffffff80;
    z-index: 9999;
  }


  .isDesign.draggable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
  }

  .isDesign.dragNdrop-hovering {
    border: 5px solid red;
  }
  .isDesign.edit {
    border: 5px solid green!important;
  }
  .firstItemAddPlace{
    color:green;
    height:7rem;
    background-color: white;
  }
</style>