<script>
  import { platform } from './h.js'

  export let activity={}
</script>

<div>

  {#if activity.appName}
    <div>Retrouvez aussi l'application {activity.appName} sur</div>

    <div class="badgeStore">
      {#if !platform('iOS')}
       <a href="https://play.google.com/store/apps/details?id={activity.appstoreAndroid}" target="_blank"><img alt="" src="https://cdn.eqrito.com/google-play-badge.svg"></a>
      {/if}
      {#if !platform('android')}
        <a href="https://apps.apple.com/app/{activity.appstoreIOS}" target="_blank"><img alt="" src="https://cdn.eqrito.com/apple-app-store-badge.svg"></a>
      {/if}
    </div>
  {/if}
  
  
  
  {#if activity.website}
    <div>
      Et notre site internet<br> <a href={activity.website} class="h5" target="_blank">{activity.website}</a>
    </div>
  {/if}
</div>

<style>
  .badgeStore img{
    height: 30px;
    margin: 5px;
  }
</style>