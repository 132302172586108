<script>
	import { onMount } from 'svelte';
	import { conf } from './stores.js'
  import { loadJS, cdn } from './h.js'
	
	import I from './G_I.svelte'

	export let src=false
	export let border=false
	export let close=false //show close button
	export let download=false //show download button
	export let parts=[]
	export let notFull=false //not full screen

	/*export let parts=[
		{type:"pdf", src:'https://cdn.eqrito.com/a84e0466-3f84-4b90-8671-d6295d3d79cc/codes/d8e85333-572e-418a-9fbf-93f1e03af13a/76df7861-bfd9-4ba0-8593-c659779f68c0.pdf'},
		{type:"image", src:"https://myeventstory.com/workshopinthecity/lille/img/logo.png"},
		{type:"pdf", src:'https://cdn.eqrito.com/a84e0466-3f84-4b90-8671-d6295d3d79cc/codes/d8e85333-572e-418a-9fbf-93f1e03af13a/76df7861-bfd9-4ba0-8593-c659779f68c0.pdf'},
		{type:"image", src:"https://mobicheckin-assets.s3.amazonaws.com/uploads/form_items/61deeb5fb6823e305c36df0e/Calque_1.png"},
		{type:"pdf", src:'https://cdn.eqrito.com/a84e0466-3f84-4b90-8671-d6295d3d79cc/codes/d8e85333-572e-418a-9fbf-93f1e03af13a/76df7861-bfd9-4ba0-8593-c659779f68c0.pdf'}
	]*/

	if(src) parts=[{type:"pdf", src:src}]
	//console.log(parts)

	let currentScene=0
	let totalView=0
	let pdf=[]
	let pdfCanvas=[]
	let pdfCurrentPage=1
	let pdfLoaded
	let pdfPageloaded
	let pdfPageloaded2
	let pageLoaderHeight

	onMount(async () => {
		initialLoad()
	})

	async function initialLoad(){
		if(!window['initPDFViewer']) {   
			await loadJS("https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.min.js")
		}	
		// The workerSrc property shall be specified.
		pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.js';
		parts.forEach(async (part,scene)=>{
			if(part.type=="image") totalView++
			if(part.type=="pdf"){
				pdfLoaded=false
				try {
					pdf[scene] = await pdfjsLib.getDocument(cdn(part.src)).promise
					totalView += pdf[scene].numPages
					if(scene==0) showCurrentPage()

				}	catch(error) {
					alert(error.message)
				}				
			}
		})
		pdfLoaded=true

	}

	function next(){
		if(!pdfPageloaded2) return
		if(pdf[currentScene] && pdfCurrentPage < pdf[currentScene].numPages) {
			pdfCurrentPage++
			showCurrentPage()
		} else {
			pdfCurrentPage=1
			currentScene++
			if(currentScene>parts.length-1) currentScene=0
			if(pdf[currentScene]) showCurrentPage()
		}
		console.log(currentScene,pdfCurrentPage,totalView-1)
	}

	function prev(){
		if(!pdfPageloaded2) return
		if(pdf[currentScene] && pdfCurrentPage > 1) {
			pdfCurrentPage--
			showCurrentPage()
		} else {
			currentScene--
			if(currentScene<0) currentScene=parts.length-1
			if(pdf[currentScene]) {
				pdfCurrentPage=pdf[currentScene].numPages
				showCurrentPage()
			}
		}
		console.log(currentScene,pdfCurrentPage,totalView-1)
	}

	// load and render specific page of the PDF
	async function showCurrentPage() {
		pdfPageloaded=false
		pdfPageloaded2=false
		let page
		try {
			page = await pdf[currentScene].getPage(pdfCurrentPage)
		}
		catch(error) {
			alert(error.message)
		}
		// as the canvas is of a fixed width we need to adjust the scale of the viewport where page is rendered
		//let scale_required = pdfCanvas[currentScene].width / pdf_original_width;
		let scale_required=1
		// get viewport to render the page at required scale
		let viewport = page.getViewport({scale: scale_required})
	
		// set canvas height same as viewport height
		pdfCanvas[currentScene].height = viewport.height;
		pdfCanvas[currentScene].width = viewport.width; //added to have good presentation

		// setting page loader height for smooth experience
		pageLoaderHeight = pdfCanvas[currentScene].height + 'px';

		let renderContext = {
			canvasContext: pdfCanvas[currentScene].getContext('2d'),
			viewport: viewport
		}
			
		// render the page contents in the canvas
		try {
			pdfPageloaded=true
			await page.render(renderContext).promise
			console.log('done')
			pdfPageloaded2=true
		}
		catch(error) {
			alert(error.message);
		}
	}
</script>


<div class="scene" class:notFull>
	{#each parts as part,scene}
		<div class:hide={scene!=currentScene} class="scene internal">
			{#if part.type=="image"}
				<img src={part.src} class="w-100" alt=" ">
			{/if}
				
			{#if part.type=="pdf"}
				<div class:hide={pdfLoaded}>Loading document ...</div>
				<canvas class:hide={!pdfPageloaded} bind:this={pdfCanvas[scene]} class:border class="canvas"></canvas>
				<div class:hide={pdfPageloaded} style="height:{pageLoaderHeight}; line-height:{pageLoaderHeight};">Loading page ...</div>
			{/if}
		</div>
	{/each}

	{#if totalView>1}
		<div class="navBts">
			<div on:click={prev} class="prev nvbtn"><div class="p-1 arrow pointer blink">&lt;</div></div>
			<div on:click={next} class="next nvbtn"><div class="p-1 arrow pointer blink">&gt;</div></div>
		</div>
	{/if}
	{#if close}
		<div on:click={close} class="x topbtn pointer"><I solid times/></div>
	{/if}
	{#if download}
	<div class="dl topbtn pointer"><a href={cdn(src)} target="_blank"><I solid download/></a></div>
{/if}

</div>


<style>
	.x {
		right:10px;
	}
	.dl {
		left:10px;
	}
	.scene {
		position:absolute;
		z-index:3;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background-color:white;
	}

	.scene.notFull {
		position:relative;
	}

	.canvas{
		width: 100%!important;
	}

	.border{
		border:1px solid grey;
	}

	.navBts {
		display: flex;
    flex-direction: row;
    justify-content: space-between;
		z-index:5;
		position:absolute;
		top:40%;
		left:0;
		right:0;
	}
			
	.arrow{
		font-size:80px;
		font-weight:1000;
		color: grey;
		-webkit-text-fill-color: darkgrey;
		-webkit-text-stroke-width: 2px;
		-webkit-text-stroke-color: grey;
		opacity:0.9;
	}

	.topbtn {
		position:absolute;
		top:0;
		z-index:10;
		font-size:40px;
		color: grey!important;
	}
	.topbtn * {
		color: grey!important;
	}
</style>