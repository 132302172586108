<script>
  import { setRoute } from './h.js'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  export let menus=[]
  export let colorBg=""
  export let colorText=""
</script>


<div class="row text-center">
  {#each menus as menu}
    {#if menu.valid}
      <div class="col-12 col-lg-6 pointer" on:click={()=>setRoute(menu.route)}>
        <div class="p-5 m-2 divButton shadow-lg" style="color:{colorText};background-color:{colorBg};">
          <I icon={menu.icon} me/><I18n fuc id={menu.name}/>
        </div>
      </div>
    {/if}
  {/each}
</div>

<style>
  .divButton{
    color:white;
    background-color: grey;
    font-size: 2rem;
  }
</style>