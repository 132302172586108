<script>
  import { i18n, cdn, check } from './h.js'
  export let activity={}

  $: navbar=check(activity,"u.navbar")

</script>


{#if navbar.type=="banner"}
  <img src={cdn(navbar.bannerCdn)} class="sticky-top w-100" title="" alt="">
{/if}
{#if navbar.type=="logo" || navbar.type=="title" || navbar.type=="logotitle"}
  <div 
    class="sticky-top fs-4 navBar d-flex flex-wrap align-items-center p-2"  class:justify-content-center={navbar.center}
    style="color: {navbar.colorText || "#000000"}; background-color: {navbar.colorBg || "#E0E0E0"};">
    {#if (navbar.type=="logo" || navbar.type=="logotitle") && navbar.logoCdn}
      <img class="logo me-2" src={cdn(navbar.logoCdn)} title="" alt="" style="height:{navbar.height - 0.9}rem;">
    {/if}
    {#if navbar.type=="title" || navbar.type=="logotitle"}
      <div class="" style="font-size:{navbar.height - 1}rem;">{i18n(navbar.title[activity.type])}</div>
    {/if}
  </div>
{/if}


<style>
  .navBar {
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,.5)!important;
    border-color: rgba(0, 0, 0, 0);
  }
  .navBar img {
    height: 1.2em;
  }
</style>