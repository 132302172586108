<script>
  import { route, conf, modal, userId, customers, customer, customerId, permissions, alert } from './stores.js'
  import { setRoute, fuc, daysLeft, u, clipBoard, logout, asArray, i18n, api, dateTimeFormat, sleep } from './h.js'

  import Alert from './G_Alert.svelte'
  import I18n from './G_I18n.svelte'
  import I from './G_I.svelte'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import LineMenu from './G_LineMenu.svelte'
  import List from './G_List.svelte'
  import Iban from './G_Iban.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'
  import Route from './G_Route.svelte'
  import Invoices from './G_Invoices.svelte'
  import Licence from './L_Licence.svelte'
  import PricingGrid from './G_PricingGrid.svelte'
  import Wrong from './G_Wrong.svelte'
 
  $: menus=[
    {route: '/account/users', name: 'team', valid:$permissions.admin || $permissions.users},
    {route: '/account/informations', name: 'informations', valid:$permissions.admin || $permissions.informations},
    {route: '/account/domains', name: 'domains', valid: $conf.account?.withDomains && $permissions.admin},
    {route: '/account/logs', name: 'logs', valid: $conf.account?.withLogs && $permissions.admin},
    {route: '/account/licence', name: 'licence', valid: $conf.account?.withLicence && $permissions.admin},
    {route: '/account/billing', name: 'billing', valid: $conf.account?.withBilling && $permissions.admin},
    {route: '/account/api', name: 'api', valid: $conf.account?.withApi && $permissions.admin},
    {route: '/account/support', name: 'support', valid:true}
  ]
 
  let obj={}

  export let supportCanChangeConf=false

  
  function startSubscription(planId){
    setRoute(`/account/licence/checkout/${planId}`)
  }

  async function getCustomer(){
    obj = await api(`/app/customers/get`)
  }

  async function setCustomer(){
    await api(`/app/customers/set/`,{obj: obj})
    await sleep(200)
    $customers = await api('/app/users/customers/list')
    $customer=obj
    $alert.success="OK"
  }

  async function delCustomer(){
    $modal={cancel:true, title:`${i18n("g--Delete")}  ${obj.name}`, msg:i18n("account--delete account confirm"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      //let data=await api(`/app/customers/delete/${$customerId}`,{spinner:true})
      logout()
    }}
  }
    
  async function genApiKey(){
    $modal={cancel:true, title:`${i18n("g--Regenerate")} apiKey`, msg:i18n("account--invalidate api confirm"), color:'warning', confirm:async ()=>{
      //await api(`/customer/set/customers`,{obj: obj})
      //obj.apiKey="new"
    }}   
  }

  async function listUsers(){
    return await api(`/app/customers/users/list`)
  }

  async function addUser(){
    let licence=await api(`/app/customers/licence/get`)
    if(!licence.available?.users) $modal={
      cancel:true,
      title:i18n("licence--No more users"), 
      msg:i18n("licence--No more users msg"), 
      color:'warning', actionLabel:i18n("licence--Upgrade"), 
      action:async ()=>{
        setRoute("/account/licence")
    }, cancel:()=>{}}
    else {
      obj={perms:{valid:true}}
      setRoute("/account/users/add")
    }
  }


  async function setUser(){
    await api(`/app/customers/users/set`,{obj: obj})
    setRoute("/account/users")
  }

  async function delUser(user){
    $modal={cancel:true, title:`${i18n("Delete")}  ${user.name || user.email}`, msg:i18n("account--delete member"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/users/delete/${user.id}`)
      setRoute("/account/users")
    }}
  }

  async function sendCredential(user){
    if(!user) return
    let data=await api('/app/users/credential/send',{authId:user.email})
    if(data) $alert.success=`${i18n("account--Credential sent to")} ${user.email}`
  }

  async function setUserPermissions(user){
    await sleep(100) //needed to permit to perms checkbox to propagate !
    await api(`/app/customers/users/permissions/set`,{obj: { id: user.id, perms: user.perms }})
  }

  async function checkDomain(domain){
    return await api(`/app/customers/domains/check/${domain}`)
  }

  async function addDomain(){
    obj={}
    setRoute("/account/domain/add")
  }

  async function setDomain(){
    await api(`/app/customers/domains/set`, {obj:obj})
    sleep(100)
    $customer.domains=await api(`/app/customers/domains/list`)
    setRoute("/account/domains")
  }

  async function deleteDomain(domain){
    await api(`/app/customers/domains/delete/${domain}`)
    sleep(100)
    $customer.domains=await api(`/app/customers/domains/list`)
    setRoute("/account/domains")
  }


  let keywordLogs="", sortByLogs={cDate:-1}
  let objsLogs=[]


  let fieldsLogs=[
    {id:"cDate", title:"date", format:'shortTimeSecond'},
    {id:"action", title:"action"},
    {id:"result", title:"result"},
    {id:"userEmail", title:"g--userEmail"},
    {id:"ressourceType", title:"ressourceType"},
    {id:"ressourceName", title:"ressourceName"},
    {id:"ressourceId", title:"ressourceId"},
  ]

  async function listLogs(){
    objsLogs =  await api(`/app/customers/logs/list`,{
      sortBy: sortByLogs
    }, {spinner: true})
  }

</script>


<CustomerBanner/>

<div class="container-lg fsContent">   
  {#key $permissions}
  
    <Title title withCustomerSelector back/>
    <LineMenu {menus} baseRoute="/account" valid={$permissions.admin || $permissions.users || $permissions.informations}/>

    <Route path="/account/informations" fsContent valid={$permissions.admin || $permissions.informations}>
      {#await getCustomer() then}
        <div class="fsScroll">
          <Form>
            <Input readonly bind:value={obj.shortId} class="col-sm-2" label="reference" icon required/>
            <Input bind:value={obj.name} class="col-sm-6" label="company" icon required/>
            <Input bind:value={obj.taxId} class="col-sm-4" label="VAT number" icon="taxid"/>
            <Input bind:value={obj.email} class="col-sm-6" type="email" label="email" icon/>
            <Input bind:value={obj.phone} class="col-sm-6" type="tel" label="phone" icon/>
            <Input bind:value={obj.address} class="col-sm-8" label="address" icon/>
            <Input bind:value={obj.zip} class="col-sm-4" label="zip" icon/>
            <Input bind:value={obj.city} class="col-sm-4" label="city" icon/>
            <Input bind:value={obj.state} class="col-sm-4" label="state" icon/>
            <Input bind:value={obj.country} class="col-sm-4" label="country" icon/>

            {#if $conf.account?.withSponsorship}
              <Input class="col-12" readonly bind:value={obj.sponsorshipId} label="sponsorship id" icon="fa-hands-holding-diamond" required/>
            {/if}

            {#if supportCanChangeConf}
              <Alert local={{noDismiss:true, warning:"account--Define a mail from"}}/>
              <Input set={"support"} bind:value={obj.supportName} class="col-sm-6" label="g--name" icon="name" required/>
              <Input set={false} type="switch" class="col-sm-6" label=" " option="g--use my SMTP mail server" bind:value={obj.isSmtpServer}/>
              
              {#if obj.isSmtpServer}
                <Input set={"support@mydomain.zzz"} bind:value={obj.supportEmail} type="email" class="col-sm-6" label="g--email" icon="email" required/>
                <Input set={"smtp.mydomain.zzz"} bind:value={obj.smtpServer} type="fqdn" class="col-sm-4" label="g--server" icon="fa-server" required/>
                <Input set={25} bind:value={obj.smtpPort} type="number" class="col-sm-2" label="g--port" icon="fa-plug" required/>
                <Input set={"user"} bind:value={obj.smtpAccount} type="text" class="col-sm-6" label="g--account" icon="fa-user"/>
                <Input set={"password"} bind:value={obj.smtpPassword} type="password" noPattern class="col-sm-6" label="g--password" icon="fa-key"/>
              {/if}
            {/if}
            {#if $conf.account?.withDPO}
              <Alert local={{noDismiss:true, warning:"account--Define a DPO"}}/>
              <Input bind:value={obj.dpoEmail} type="email" class="col-sm-6" label="Dpo email" icon="email"/>
              <Input bind:value={obj.dpoName} class="col-sm-6" label="Dpo name" icon="name"/>
              <Input bind:value={obj.dpoLink} type="url" class="col-sm-12" label="Dpo link" icon="link"/>
            {/if}
            <Button validity action={setCustomer} actionModel="update" class="col-auto"/>
            <!--Button action={delCustomer} actionModel="delete" class="col-auto"/-->
          </Form>
        </div>
      {:catch}
        <Wrong/>
      {/await}
    </Route>


    <Route path="/account/users" fsContent valid={$permissions.admin || $permissions.users}>
      {#await listUsers() then objs}
        <div class="fsContentHorizontal fs-5 my-4 ">
          <div>{asArray(objs).length} <I18n>account--Users</I18n></div>
          <Button add={()=>{addUser()}} title="account--add member"/>
        </div>
              
        <div class="fsScroll">
          <div class="cards">
            {#each asArray(objs) as user,i}
              <div class="card shadow">
                <div class="card-header">
                  <div class="fsContentHorizontal">
                    <div class="fs-5 fw-bold">{u(user.name || user.email.split('@')[0])}</div>
                    {#if $userId==user.id}({i18n("Me")}){/if}
                    <Input set={false} type="switch" disabled={$userId==user.id} option="g--Active" bind:value={user.perms.valid} change={()=>setUserPermissions(user)}/>
                  </div>
                  <div class="small">{u(user.email)}</div>
                </div>
                <div class="card-body">
                  {#if $permissions.admin}
                    <Input set={false} type="switch" disabled={$userId==user.id} option="g--Admin" bind:value={user.perms.admin} change={()=>setUserPermissions(user)}/>
                  {/if}
                  {#each $conf.perms as p}
                    {#if $permissions[p.id] || $permissions.admin}
                      <Input set={false} type="switch" disabled={$userId==user.id || user.perms.admin} option={p.name} bind:value={user.perms[p.id]} change={()=>setUserPermissions(user)}/>
                    {/if}
                  {/each}
                  <!--Input set={{}} type="checkbox" disabled={($userId==user.id || !user.perms.valid || user.perms.admin) && !$permissions.support} options={$conf.perms} bind:value={user.perms} change={()=>setUserPermissions(user)}/-->
                </div>
                {#if $userId!=user.id}
                  <div class="card-footer fsContentHorizontal">
                    {#if asArray(objs).length>1} 
                      <Button del={()=>delUser(user)}/>
                    {/if}
                    <div class="pointer" on:click={()=>sendCredential(user)}><i class="{$conf.faType} fa-key pointer"></i> <I18n>Send credentials</I18n></div>
                  </div>
                {/if}
              </div>
            {/each}
          </div>
        </div>
      {/await}
    </Route>


    <Route path="/account/users/add" fsContent valid={$permissions.users || $permissions.admin}>
      <Title title="account--add member" cancel="/account/users"/>
      <Form>
        <Input bind:value={obj.email} icon type="email" label="email" required/>
        {#if $permissions.admin}
          <Input set={false} type="switch" option="g--Admin" bind:value={obj.perms.admin}/>
        {/if}
        {#each $conf.perms as p}
          {#if $permissions[p.id] || $permissions.admin}
            <Input set={false} type="switch" disabled={obj.perms.admin} option={p.name} bind:value={obj.perms[p.id]}/>
          {/if}
        {/each}
        <!--Input set={{valid:true}} type="checkbox" options={$conf.perms} bind:value={obj.perms}/-->
        <Button validity action={setUser} actionModel="add" class="col-auto"/>
      </Form>
    </Route>


    <Route path="/account/domains" fsContent valid={$permissions.admin}>
      <div class="fsContentHorizontal fs-5">
        <div>{asArray($customer.domains).length} <I18n>account--Domains</I18n></div>
        <Button add={()=>{addDomain()}} title="account--add domains"/>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col"><I18n>account--Domain</I18n></th>
            <th scope="col"><I18n>account--Status</I18n></th>
          </tr>
        </thead>
        <tbody>
          {#each asArray($customer.domains) as domain,i (domain)}
            <tr>
              <th scope="row">{i+1}</th>
              <td>{domain.id}</td>
              <td>
                {#await checkDomain(domain.id) then check}
                  {#if check.valid}
                    <span class="badge bg-success"><I18n>account--Domain valid</I18n></span>
                  {:else}
                    <span class="badge bg-danger"><I18n>account--Domain not yet transfered</I18n></span>
                  {/if}
                  <button on:click={()=>deleteDomain(domain.id)} class="btn btn-xs btn-danger"><I trash/></button>
                {/await}
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    </Route>


    <Route path="/account/domain/add" fsContent valid={$permissions.admin}>
      <Title title="account--add domain" cancel="/account/users"/>
      <p><I18n>account--To add your own domain</I18n></p>
      <div class="p-3 bg-light">
        <div class="fsContentHorizontal">
          <div><code>{"your.domain.com" + ' CNAME ' + $conf.cname + '.'}</code> <button disable={!obj.id} class="btn btn-xs btn-outline-secondary" on:click={()=>clipBoard((obj.id ||"your.domain.com") + ' CNAME ' + $conf.cname, true)}><I18n>Copy</I18n></button></div>
        </div>
      </div>
      <Form>
        <Input placeholder="your.domain.com" bind:value={obj.domain} icon type="fqdn" label="account--domain" required/>
        <Button validity action={setDomain} actionModel="add" class="col-auto"/>
      </Form>
    </Route>
          
          
    <Route path="/account/api" fsContent valid={$permissions.admin}>
      <I18n>account--Use api</I18n>
      <div class="p-3 bg-light">
        <div class="fsContentHorizontal">
          <div><code>{obj.apiKey}</code> <button class="btn btn-xs btn-outline-secondary" on:click={()=>clipBoard(obj.apiKey, true)}><I18n>Copy</I18n></button></div>
          <Button action={genApiKey} title="Regenerate" color="warning" icon="undo" />
        </div>
      </div>
    </Route>
                

    <Route path="/account/billing" fsContent valid={$permissions.admin}>
      <div class="fsScroll">
        <!--Form>
          <Iban/>
        </Form-->
        {#await api(`/app/customers/invoices/list`) then invoices}
          <Invoices {invoices}/>
        {/await}
      </div>
    </Route>

    <Route path="/account/logs" fsContent>
      <h5><I18n>account--See logs</I18n></h5>
      {#await listLogs() then}
        <List title="g--last logs" objs={objsLogs} fields={fieldsLogs}/>
      {/await}
    </Route>
      

    <Route path="/account/support" fsContent>
      <h5><I18n>account--Your support</I18n></h5>
      {#if $conf.support}
        <p>{u($conf.support.email)}</p>
        <p>{u($conf.support.phone)}</p>
        <p>{u($conf.support.text)}</p>
        <p>Version V{$conf.version} - Base {fuc($conf.htmlRoot)} - Code {fuc($conf.serviceCode)} </p>
      {/if}
    </Route>




    <Route path="/account/licence" fsContent valid={$permissions.admin}>
      <div class="card">
        <div class="card-header fw-bold">
          <I18n>licence--Your</I18n>
        </div>
        {#await api(`/app/customers/licence/get`) then obj}
          <div class="card-body">
            {#if obj.expireDate}
              <div class="card-title">
                <I18n>licence--Expiration date</I18n>: {dateTimeFormat(obj.expireDate, 'complete')}
              </div>
              {#if obj.expired}
                <div class="h4 text-danger fw-bold"><I18n>licence--Expired</I18n></div>
              {:else}
                <p class="card-text"><I18n>licence--Days left</I18n>: {daysLeft(obj.expireDate)}</p>
              {/if}
            {/if}
            {#if obj.expired}
              <button on:click={()=>setRoute('/account/licence/subscription')} class="btn btn-lg btn-warning"><I18n>licence--Renew</I18n></button>
            {/if}
            {#if obj.upgrade}
              <button on:click={()=>setRoute('/account/licence/subscription')} class="btn btn-lg btn-success"><I18n>licence--Upgrade</I18n></button>
            {/if}
            {#if $conf.account?.withLicenceDetail}
              <Licence {obj} select="accountCustomer"/>
            {/if}
          </div>
        {/await}
      </div>
    </Route>




    <Route path="/account/licence/subscription" fsContent valid={$permissions.admin}>
      <div class="fsScroll">
        <div class="container-lg">
          {#await api('/app/customers/pricing') then pricing}
            <PricingGrid CTA={startSubscription} {pricing} subscription compare={$conf.account?.withComparePlan}/>
          {/await}
        </div>
      </div>
    </Route>


    <Route path="/account/licence/checkout/:planId" let:routeParams fsContent valid={$permissions.admin}>
      <Alert local={{noDismiss:true, danger:"Le système de paiement est en cours de mise à jour. Contactez-nous pour faire un changement de licence. Vous pourrez régler par virement ou prélevement."}}/>
      
      <a class="btn btn-success" href="mailto:support@eqrito.com?subject=Demande d'information tarifaire&body=Bonjour,%0D%0Aje souhaite éventuellement souscrire à votre service ou changer de pack.%0D%0APouvez-vous me contacter par mail et me donner plus d'information sur vos différent tarifs.%0D%0A%0D%0AMerci.%0D%0A%0D%0A====================================%0D%0A{$customerId}%0D%0A====================================%0D%0A">Envoyez un email de demande</a>
      
      <div class="fsScroll">
        <div class="container-lg">
          <!--<Form>
            {routeParams.planId}
            <Input bind:value={obj.name} class="col-sm-6" label="company" icon required/>
            <Input bind:value={obj.taxId} class="col-sm-4" label="VAT number" icon="taxid" required/>
            <Input bind:value={obj.email} class="col-sm-6" type="email" label="email" icon required/>
            <Input bind:value={obj.phone} class="col-sm-6" type="tel" label="phone" icon/>
            <Input bind:value={obj.address} class="col-sm-8" label="address" icon/>
            <Input bind:value={obj.zip} class="col-sm-4" label="zip" icon/>
            <Input bind:value={obj.city} class="col-sm-4" label="city" icon/>
            <Input bind:value={obj.state} class="col-sm-4" label="state" icon/>
            <Input bind:value={obj.country} class="col-sm-4" label="country" icon required/>
            <Button validity action={setCustomer} actionModel="update" class="col-auto"/>
          </Form>-->
        </div>
      </div>
    </Route>
  {/key}
</div>