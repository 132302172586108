<script>
	import { online, lang } from './stores.js'
  import I18n from './G_I18n.svelte'

	let timeoutOffline
	let showOffline=false
	$: if(!$online) {
		clearTimeout(timeoutOffline)
		timeoutOffline=setTimeout(() => {
			showOffline=true
		}, 200);
	}
	$: if($online) {
		showOffline=false
		clearTimeout(timeoutOffline)
	}

</script>

{#if showOffline}
	<div class="fullScreen offline">
    <div class="blink h1">
      <span class="fa-stack">
        <i class="fa-light fa-cloud fa-stack-2x"></i>
        <i class="fa-solid fa-exclamation-triangle fa-stack-1x" style="color:Tomato"></i>
      </span>
      <span><I18n id='No connection'/></span>
    </div>
  </div>
{/if}

<style>
	.offline {
		background: black;
		opacity:0.9;
		z-index: 3000;
	}
	.offline div {
		position:absolute;
		color:white;
		top: 50%;
		width:100%;
		text-align: center;
	}
</style>
