<script>
  import { customerId, customer, customers, permissions, route, conf, uuid, token, user, userId, lang, wsc, menus } from './stores.js'
  import { setRoute, setRouteRoot, isRoute, daysLeft, closest, i18n, cdn, init, setCssVars, api, logout, asArray, thisYear, getById, applyStylesPerms } from './h.js'
  import Auth from './G_Auth.svelte'

  import Offline from './G_Offline.svelte'
  import SpinnerGlobal from './G_SpinnerGlobal.svelte'
  import NavBarMenu from './G_NavBarMenu.svelte'
  import DivButtonMenu from './G_DivButtonMenu.svelte'
  import Modal from './G_Modal.svelte'
  import Alert from './G_Alert.svelte'
  import Route from './G_Route.svelte'
  import I18n from './G_I18n.svelte'
  import I from './G_I.svelte'

  import Profil from './G_Profil.svelte'
  import Translations from './G_Translations.svelte'
  import Account from './G_Account.svelte'
  import Agency from './G_Agency.svelte'

  export let localConf={}
  //$: console.log($route)
  //$: console.log("conf",$conf)

  init(localConf)
  $: setCssVars($conf.style)



  $: if(!$userId && !$token) setRoute('/auth/signIn')
  $: if(!$userId && $token) auth()

  async function auth(){
    if(!$userId && $token){
      let data=await api(`/app/users/get`)
      if(data) {
        $userId=data.id
        $user=data
        $wsc.subscribe('user_' + $userId)
        return
      }
    }
    setRoute('/auth/signIn')
  }

  $: if($userId && !$customers) usersCustomersList()
  $: $customer=getById($customers, $customerId) 
  $: if($userId || $customerId) usersPermissionsGet()

  async function usersCustomersList(){
    $customers = await api('/app/users/customers/list')
    if($customers && !getById($customers, $customerId) && $customers[0]) $customerId=$customers[0].id
    if($customers) {setRouteRoot('/dashboard');setRoute()}
    if(!$customers) {setRouteRoot('/homenocustomer');setRoute()}
  }
  async function usersPermissionsGet(){
    if($userId && $customerId) $permissions = await api(`/app/customers/users/permissions/get/${$userId}`)
  }
</script>

<!-- global -->
<Offline/>
<SpinnerGlobal/>
<Modal/>

<!-- signin -->
<Route path="/auth/*">
  <Auth/>
</Route>


<!-- full screen app -->
<Route valid={$userId}>
  <div class="fs">
    <!-- menu -->
      <NavBarMenu sideBreak='lg' signOutFct={logout}/>

    <!-- global alert -->
    <Alert class="container-lg"/>

    <!-- content -->
    <div class="fsContent"> 

      <slot/>
      

      <Route path="/profil/*" fsContent>
        <Profil/>
      </Route>

      <Route path="/translations/*" fsContent>
        <Translations/>
      </Route>

      <Route path="/account/*" fsContent>
        <Account/>
      </Route>

      <Route path="/agency/*" fsContent>
        <Agency/>
      </Route>      


    </div>

    <!-- footer -->
    <div class="footer small fsContentHorizontal justify-content-around p-2">
      <div><i class="{$conf.faType} fa-copyright"></i> {thisYear()} {$conf.title} - V{$conf.version}</div>
    </div>

  </div>
</Route>


<style>
  :global(body){
    background-color:var(--colorBg)!important;
    color: var(--colorText)!important;
  }
  :global(body) {
    font-family: 'Questrial'!important;
  }

  :global(.I){
    color:var(--brandColor);
  }

  :global(.btn-global) {
    background-color:var(--buttonBgColor)!important;
    border-color:var(--buttonBgColor)!important;
    color:var(--buttonTextColor)!important;
  }
  :global(.btn-global:hover) {
    opacity:0.8;
  }
  :global(.btn-outline-global) {
    border-color:var(--buttonBgColor)!important;
    color:var(--buttonBgColor)!important;
  }
  :global(.btn-outline-global:hover) {
    opacity:0.8;
    background-color:var(--buttonBgColor)!important;
    color:var(--buttonTextColor)!important;
  }
  
  :global(.link-global) {
    color:var(--colorLink)!important;
  }

  :global(.fsScroll::-webkit-scrollbar-thumb) {
    /*background-color: var(--brandColor)!important;*/
  }

  :global(.dropdown-menu) {
    padding: 0;
  }

  .footer {
    margin-top:0.5rem;
    z-index:2;
    background-color: var(--navbarBgColor);
    box-shadow: 0 0.5rem 1rem var(--navbarBgColor);
    border-color: rgba(0, 0, 0, 0);
  }

  :global(.cards) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-auto-rows: auto;
    grid-gap: 2rem;
  }

  :global(.anchor span) {
    color:var(--brandColor2);
    cursor:pointer;
  }
  :global(.anchor span:hover){
    font-weight:bold;
  }

  :global(.alert-info) {
    --bs-alert-color: #055160;
    --bs-alert-bg: #f0fcff;
    --bs-alert-border-color: #f0fcff;
  }
  :global(.alert-warning) {
    --bs-alert-color: #055160;
    --bs-alert-bg: #fff4cb;
    --bs-alert-border-color: #fff4cb;
  }
  :global(.alert-success) {
    --bs-alert-color: #055160;
    --bs-alert-bg: #dcffd5;
    --bs-alert-border-color: #dcffd5;
  }


</style>

<svelte:head>
  <link href='https://fonts.googleapis.com/css?family=Questrial' rel='stylesheet'>
</svelte:head>
