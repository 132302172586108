<script>
  import { trigger, uuid } from "./h.js"
  import Input from './G_Input.svelte'
  import I from './G_I.svelte'

  $: trigger('valueChanged', value) //this replace change() value is not used in form.svelte

  export let useUuid=false
  export let value=false
  export let icon=false
  export let label=''
  export let legend=''
  export let help=''
  export let minInputs=1
  export let maxInputs=0

  $: minInputs=parseInt(minInputs)
  $: maxInputs=parseInt(maxInputs)
  $: if(minInputs && !value && !useUuid) value=['']
  $: if(minInputs && !value && useUuid) value=[{id:uuid(), value:""}]

  function del(i){
    value.splice(i,1);
    value=value;
  }

  function add(){
    if(!value) value=[]
    if(useUuid) value.push({id:uuid(), value:""})
    else value.push('')
    value=value
  }
</script>
{#if value.length || minInputs}
  {#if !useUuid}
    {#each value as v,i}
      <Input bind:value={v} 
        label={!i?label:''}
        legend={!i?legend:''}
        help={!i?help:''}
        icon={!i?icon:''}
        btnDelete={value.length>minInputs?()=>del(i):false}
        btnAdd={i==value.length-1 && (!maxInputs || value.length<maxInputs )?()=>add():false}
        required={minInputs && i<minInputs}
        {...$$restProps}
      />
    {/each}
  {:else}
    {#each value as v,i}
        <Input type="hidden" bind:value={v.id}/>
        <Input bind:value={v.value} 
          label={!i?label:''}
          legend={!i?legend:''}
          help={!i?help:''}
          icon={!i?icon:''}
          btnDelete={value.length>minInputs?()=>del(i):false}
          btnAdd={i==value.length-1 && (!maxInputs || value.length<maxInputs )?()=>add():false}
          required={minInputs && i<minInputs}
          {...$$restProps}
        />
      {/each}
  {/if}
{:else}
  <div class="col-12 pt-2 mb-4">
    <button class="btn btn-sm btn-outline-success" on:click={()=>add()}><I plus/></button>
  </div>
{/if}