<script>
  import { user, userId, alert } from './stores.js'
  import { api, setRoute } from './h.js'
  import Alert from './G_Alert.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import Title from './G_Title.svelte'
 
  let obj={}

  async function getUser(){
    obj=await api(`/app/users/profil/get/${$userId}`)
  }

  async function setUser(){
    let ret=await api(`/app/users/profil/set`,{obj: obj})
    if(ret) {
      $alert.success="Updated"
      $user={ ...$user, ...obj}
      setRoute()
    }
    else $alert.danger="Error"
  }

  async function proposePassword(){
    return await api(`/app/gen/pwd`)
  }
</script>


<div class="container-lg fsContent">
  <Title title="Profil" back/>
  <div class="fsScroll">
    {#await getUser() then}
      <Form>
        <Input bind:value={obj.email} type="email" icon label="email" required/>
        <Input bind:value={obj.name} label="name" required icon/>
        <Input bind:value={obj.password} type="password" {proposePassword} pattern showStrength showPassword label="password" icon/>
        <Input set={false} lg type="switch" option="2FA" bind:value={obj.is2fa}/>
        <Button validity action={setUser} actionModel="save" class="col-auto"/>
      </Form>
    {:catch}
      <Wrong/>
    {/await}
  </div>
</div>