<script>
  import { permissions, customers, customerId, route, conf, user, menus } from './stores.js'

  import Window from './G_Window.svelte'
  import Route from './G_Route.svelte'

  import Dashboard from './L_Dashboard.svelte'
  import Codes from './L_Codes.svelte'
  import Tours from './L_Tours.svelte'
  import Steps from './L_Steps.svelte'
  
  let localConf={
    faType:"fa-light",
    faSharp:false,
    url:"https://eqrito.com",
    qrDomain:"eqri.to",
    qrDomains:[{id:"qr-cod.eu"},{id:"q-to.eu"},{id:"eqri.to"}],
    langs:['fr','en'],
    i18n:{},
    perms:[
      {id:"users", name:"member--manage team"}
    ],
    permsAgency:[
      {id:"customers", name:"agency--manage agency customers"},
      {id:"users", name:"agency--manage agency users"}
    ],
    account:{
      withDomains:false,
      withBilling:false,
      withApi:false,
      withDPO:false,
      withComparePlan:false,
      withLicence:true,
      withLicenceDetail:true,
      withSponsorship:true
    },
    agency:{
      withLicenceAgencyCustomer:true,
      withLicenceAgencyCustomerDetail:true,
      withSponsorship:true
    },
    cname:"customer.eqrito.com",
    unitys:[//should be take from config.json
      {id: 'link', name: 'unity--link', icon:'link', desc: 'unity--link description', use:true},
      {id: 'custom', name: 'unity--custom', icon:'shapes', desc: "unity--custom description", use:true},
      {id: 'social', name: 'unity--social', icon:'hashtag', desc: 'unity--social description', use:true},
      {id: 'card', name: 'unity--card', icon:'address-card', desc: "unity--card description", use:true},
      {id: 'info', name: 'unity--info', icon:'magnifying-glass', desc: "unity--info description", use:false},
      {id: 'media', name: 'unity--media', icon:'photo-video', desc: 'unity--media description', use:false},
      {id: 'lead', name: 'unity--lead', icon:'user', desc: 'unity--lead description', use:false},
      {id: 'event', name: 'unity--event', icon:'calendar-check', desc: 'unity--event description', use:false},
      {id: 'show', name: 'unity--show', icon:'presentation', desc: "unity--show description", use:false},
      {id: 'coupon', name: 'unity--coupon', icon:'ticket-alt', desc: 'unity--coupon description', use:false},
      {id: 'survey', name: 'unity--survey', icon:'comment', desc: 'unity--survey description', use:false},
      {id: 'game', name: 'unity--game', icon:'dice', desc: 'unity--game description', use:false},
      {id: 'locator', name: 'unity--locator', icon:'map-marker-alt', desc: 'unity--locator description', use:false},
      {id: 'app', name: 'unity--app', icon:'mobile-alt', desc: "unity--app description", use:true},
      {id: 'email', name: 'unity--email', icon:'at', desc: 'unity--email description', use:true},
      {id: 'phone', name: 'unity--phone', icon:'phone', desc: 'unity--phone description', use:true},
      {id: 'pdf', name: 'unity--pdf', icon:'file-pdf', desc: 'unity--pdf description', use:false},
      {id: 'map', name: 'unity--map', icon:'map-marker-alt', desc: 'unity--map description', use:false},
      {id: 'arboretum', name: 'unity--arboretum', icon:'flower-daffodil', desc: 'unity--arboretum description'},
      {id: 'location', name: 'unity--location', icon:'person-walking', desc: "unity--location description", use:false}
    ]

  }

  $: $menus=[
    {route: '/dashboard', name: '', icon:'home', valid:$customers},
    {route: '/homenocustomer', name: '', icon:'home', valid:!$customers},
    {route: '/codes', name: 'Qr codes', icon:'qrcode', valid:$customers},
    {route: '/steps', name: 'Steps', icon:'location-dot', valid:$permissions.admin && $permissions.tours},
    {route: '/tours', name: 'Tours', icon:'map-marked-alt', valid:$permissions.admin && $permissions.tours},
    {route: '/account', name: 'Account', icon:'wrench', valid:$permissions.admin || $permissions.users || $permissions.informations},
    {route: '/agency', name: 'Agency', icon:'globe', valid:$user.perms?.valid},
    {route: '/translations', name: 'Translations', icon:'university', valid:$user.perms?.translation}
  ]
</script>

<Window {localConf}>
  {#key $customerId}
    <Route path="/homenocustomer" fsContent>
      Hello home no customer
    </Route>


    <Route path="/dashboard" fsContent>
      <Dashboard/>
    </Route>

    <Route path="/codes/*" fsContent>
      <Codes/>
    </Route>

    <Route path="/tours/*" fsContent>
      <Tours/>
    </Route>

    <Route path="/steps/*" fsContent>
      <Steps/>
    </Route>
  {/key}
</Window>