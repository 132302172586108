<script>
  import { conf  } from './stores.js'
  import { cdn, setRoute } from './h.js'
  import I18n from './G_I18n.svelte'
  import Input from './G_Input.svelte'
  import { fly } from 'svelte/transition'

  export let id

  let src
  let testMode=false, previewSize, previewStyle="range" //="storyboard",
  let previewStyleOptions=[{id:"storyboard", name:"codes--storyboard"},{id:"range", name:"codes--range"}]
  let mode=testMode?"previewTest":"preview" //don't know the difference ?
  
  if(id.startsWith('blob')) src=id
  else src=`${$conf.apiUrl}/qr/${id}/${mode}`
  

  function close(){setRoute(`/codes`)}
</script>


<div transition:fly={{x:-400}} class="fs bg-white px-1" style="z-index:9999;"  on:click={close}>
  <div on:click|stopPropagation class="bg-light shadow-sm row d-flex align-items-center" style="z-index:1000;">
    <div class="col-auto"><img style="height:2rem;" src={cdn($conf.logo)} alt="logo"></div>
    <div class="col row d-flex align-items-center">
      <div class="col-md-auto fs-1"><I18n>codes--Preview</I18n></div>
      <Input inline class="col-md-auto" type="radio" nomargin options={previewStyleOptions} bind:value={previewStyle}/>
      {#if previewStyle=="range"}
        <Input class="col" type="range" nomargin set=10 min=2 max=100 bind:value={previewSize}/>
      {/if}
      {#if testMode && previewStyle=="storyboard"}
        <div class="pulse text-danger col-md-auto"><I18n>codes--preview msg</I18n></div>
      {/if}
    </div>

    <div on:click={close} class="ms-auto col-auto"><i class="{$conf.faType} fa-times me-1 fa-3x"></i></div>
  </div>
  {#if previewStyle=="storyboard"}
    <div class="text-center d-sm-none fsScroll">
      <img alt="" class="w-100" src="{src}">
      <div d-flex>
        <img alt="" class="w-50" src="{src}">
        <img alt="" class="w-25" src="{src}">
      </div>
    </div>
    <div class="d-none d-sm-block position-absolute">
      <div class="d-flex">
        <div>
          <img alt="" class="" style="width: 50vw;height: 100vmin;" src="{src}">
        </div>
        <div>
          <img alt="" class="" style="width: 25vw;height: 60vmin;" src="{src}">
          <img alt="" class="" style="width: 12vw;height: 15vmin;" src="{src}">
          <img alt="" class="" style="width: 6vw;height: 6vmin;" src="{src}">
      
        </div>
        <div>
          <img alt="" class="" style="width: 21vw;height: 54vmin;" src="{src}">
          <img alt="" class="" style="width: 12vw;height: 22vmin;" src="{src}">
        </div>
      </div>
    </div>
  {:else}
    <div class="d-flex flex-grow-1 flex-column justify-content-center text-center">
      <div class="h5"><i class="fa-light fa-arrows-h"></i> {previewSize} cm<i class="text-danger fa-light fa-asterisk"></i></div>
      <small class="fw-bold"><i class="fa-light fa-ruler"></i> <I18n>codes--scanbestbetween</I18n>: {previewSize*8} cm ~ {previewSize*12} cm
        {#if testMode}
          <div class="pulse text-danger"><I18n>codes--preview msg</I18n></div>
        {/if}
      </small>
      <div><img alt="" class="border" style="height: {previewSize}cm; width: {previewSize}cm;" src="{src}"></div>
      <small><i class="text-danger fa-light fa-asterisk"></i> <I18n>codes--realsizediffers</I18n></small>
    </div>
  {/if}
</div>