<script>
  import { conf } from './stores.js'
  import { api } from './h.js'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'

  export let iban={}

  let countries=[
    'Austria', 'Belgium', 'Bulgaria', 'Croatia', 'Cyprus', 'Czech Republic', 'Denmark', 'Estonia',
    'Finland', 'France', 'Germany', 'Greece', 'Guadeloupe', 'Hungary', 'Iceland',
    'Ireland', 'Italy', 'Latvia', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Malta', 'Martinique', 
    'Monaco', 'Netherlands', 'Norway', 'Poland', 'Portugal', 'Reunion', 'Romania', 'San Marino', 'Slovak Republic', 
    'Slovenia', 'Spain', 'Sweden', 'Switzerland', 'United Kingdom'
  ]
</script>

  <Input type="hr" label="Consumer details" icon="fa-info-square"/>
  <Input bind:value={iban.name} class="col-sm-5" label="name" icon required/>
  <Input bind:value={iban.address} class="col-sm-7" label="address" icon required/>

  <Input bind:value={iban.city} class="col-sm-4" label="city" icon required/>
  <Input bind:value={iban.zip} class="col-sm-2" label="zip" icon required/>
  <Input bind:value={iban.state} class="col-sm-3" label="state" icon required/>
  <Input bind:value={iban.country} class="col-sm-3" type="select" options={countries} label="country" icon required/>
  
  <Input type="hr" label="Account details" icon="fa-money-check-alt"/>
  <Input bind:value={iban.iban} class="col-sm-6" label="IBAN" icon minLength=5 maxLength=34 required/>
  <Input bind:value={iban.bic} class="col-sm-6" label="BIC" icon required/>
  
  <Input type="hr" label="Bank details" icon="fa-money-check-alt"/>
  <Input bind:value={iban.bankName} class="col-sm-5" label="Bank Name" icon="fa-university" required/>
  <Input bind:value={iban.bankAddress} class="col-sm-7" label="address" icon required/>
  
  <Input bind:value={iban.bankCity} class="col-sm-4" label="city" icon required/>
  <Input bind:value={iban.bankZip} class="col-sm-2" label="zip" icon required/>
  <Input bind:value={iban.bankState} class="col-sm-3" label="state" icon required/>
  <Input bind:value={iban.bankCountry} class="col-sm-3" type="select" options={countries} label="country" icon required/>


  <Input bind:value={iban.grantAuth} icon type="checkbox" label="authorization" option="I authorize this site to proceed payment request to this bank account." required/>
 
