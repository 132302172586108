<script>
  import { route } from './stores.js'
  import { setRoute, matchRoute, setCssVars } from './h.js'
 
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'

  import findIndex from 'lodash/findIndex'

  export let colorStep="grey"
  export let colorActive="purple"
  export let colorCompleted="lightgreen"
  export let steps=[]
  export let step
  export let completion=false //auto marks completed all step before current
  export let stepValidity=false //block click on other step if current is not valid (state provided by stepValidity)


  let cl=$$props.class || ''

  $: index = findIndex(steps, function (st) {
    return st.step == step
  })

  //$: console.log(completion, step, index, stepValidity)


  setCssVars({colorStep:colorStep, colorActive:colorActive, colorCompleted:colorCompleted})
</script>


<div class="stepper {cl}">
  {#each steps as st,i}
    {#if !st.hide}
      <div 
        class="step"
        class:canClick={completion?(i<=index) || (stepValidity && i==index+1):stepValidity}
        class:completed={completion?(i<index):false}
        class:active={st.route?matchRoute($route, st.route):st.step==step} 
        on:click={()=>{
          let canClick=completion?(i<=index) || (stepValidity && i==index+1):stepValidity
          if(canClick) st.route?setRoute(st.route,null,null,true):step=st.step
        }}>
        <div class="counter">
          {#if st.icon}<I solid icon={st.icon}/>{:else}{i+1}{/if}
        </div>
        <div class="name">
          <I18n id={st.name}/>
        </div>
      </div>
    {/if}
  {/each}
</div>

<style>
  .stepper {
    cursor: not-allowed;
    display: flex;
    justify-content: space-between;
  }
  .step {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  .step::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid var(--colorStep);
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
  }

  .step::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid var(--colorStep);
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
  }

  .step .counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
  }

  .step.active .counter {
    transform: scale(1.4);
    background-color:var(--colorActive);
  }
  .step.active .name {
    color:var(--colorActive);
    font-weight:bold;
  }
  
  .step .counter{
    transition: transform .2s;
  }
  .step:not(.active):hover .counter {
    transform: scale(1.2);
    background-color:var(--colorActive);
  }
  .step:hover .name {
    color:var(--colorActive);
    font-weight:bold;
  }

  .step.completed .counter {
    background-color:  var(--colorCompleted);
  }

  .step.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid var(--colorCompleted);
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
  }

  .step:first-child::before {
    content: none;
  }
  .step:last-child::after {
    content: none;
  }
  .step.canClick {
    cursor: pointer;
  }
</style>