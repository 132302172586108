<script>
  import Input from './G_Input.svelte'
  export let page={}
</script>

<Input class="col-12" type="hr" icon="fa-palette" label="custom--Colors"/>

<Input class="col-lg-4" bind:value={page.colorText} set="#000000" icon type="color" label="custom--color text" colorText/>
<Input class="col-lg-4" bind:value={page.colorLink} set="#007bff" icon type="color" label="custom--color link" colorText/>
<Input class="col-lg-4" bind:value={page.colorButtonBg} set="#007bff" icon type="color" label="custom--color button bg" colorText/>

<Input class="col-lg-4" bind:value={page.colorButtonText} set="#FFFFFF" icon type="color" label="custom--color button text"colorText/>
<Input class="col-lg-4" bind:value={page.colorBg} set="#FFFFFF" icon type="color" label="custom--color bg"colorText/>
<Input lg class="col-lg-4" set={false} bind:value={page.isImageBg} label=" " type="switch" option="custom--is img bg"colorText/>
{#if page.isImageBg}
  <Input class="col-lg-4" bind:value={page.imageBgCdn} icon="image" type="file" label="custom--image bg" accept="image/svg,image/png,image/jpeg,image/gif"/>
  <Input set=0 class="col-lg-4" bind:value={page.transparencyBg} type="range" showValue unit="%" label="custom--transparency bg" min=0 max=100 step=1/>
{/if}
